@charset "UTF-8";

// TODO - Move this to global file
.public-theme {
  font-family: 'Inter', sans-serif;
  color: #000000;

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  ul[class],
  ol[class] {
    padding: 0;
  }

  body,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  ul[class],
  ol[class],
  li,
  figure,
  figcaption,
  blockquote,
  dl,
  dd {
    margin: 0;
  }

  .article-content {
    p:not(.caption_1):not(.caption_1_med):not(.caption_2):not(.caption_2_med):not(.caption_3):not(.caption_3_med):not(.caption_4):not(.caption_4_med) {
      font-size: 20px;
      line-height: 28px;
      letter-spacing: -0.6px;
      color: rgba(0, 0, 0, 0.8);
      margin-bottom: 30px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    ul,
    ol {
      padding-left: 30px;
      font-size: 20px;
      line-height: 28px;
      letter-spacing: -0.6px;
      color: rgba(0, 0, 0, 0.8);
    }

    ul li,
    ol li {
      margin-bottom: 15px;
    }

    ul li:last-child,
    ol li:last-child {
      margin-bottom: 0;
    }

    ul {
      list-style-type: disc;
    }

    blockquote {
      color: rgba(0, 0, 0, 0.5);
      background-color: rgba(0, 0, 0, 0.02);
      padding: 20px 30px;
      font-size: 22px;
      line-height: 28px;
      letter-spacing: -0.8px;
      margin: 30px 0;
      border-radius: 5px;

      footer {
        margin-top: 10px;
      }
    }

    .img__caption {
      font-size: 16px;
      line-height: 20px;
      letter-spacing: -0.5px;
    }

    h1 h2,
    h3,
    h4,
    h5,
    h6 {
      margin-top: 50px;
      margin-bottom: 30px;
    }

    b {
      font-weight: 600;
    }
  }

  @media screen and (min-width: 768px) {
    .article-content p:not(.caption_1):not(.caption_1_med):not(.caption_2):not(.caption_2_med):not(.caption_3):not(.caption_3_med):not(.caption_4):not(.caption_4_med) {
      font-size: 21px;
      line-height: 30px;
      letter-spacing: -0.8px;
    }
  }

  @media screen and (min-width: 768px) {
    .article-content {

      ul,
      ol {
        font-size: 21px;
        line-height: 30px;
        letter-spacing: -0.8px;
      }
    }
  }

  @media screen and (min-width: 768px) {
    .article-content blockquote {
      font-size: 24px;
      line-height: 35px;
      letter-spacing: -1px;
    }
  }

  @media screen and (min-width: 768px) {
    .article-content .img__caption {
      font-size: 17px;
      line-height: 25px;
      letter-spacing: -0.7px;
    }
  }

  .article-content__footer-top {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
    margin: 60px 0 20px;

    &::after {
      position: absolute;
      bottom: 0;
      left: 0;
      display: block;
      content: '';
      width: 100%;
      height: 1.5px;
      background: rgba(0, 0, 0, 0.05);
      border-radius: 2px;
    }
  }

  .article-content__footer-bottom {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 33.5px;

    &::after {
      position: absolute;
      bottom: 0;
      left: 0;
      display: block;
      content: '';
      width: 100%;
      height: 1.5px;
      background: rgba(0, 0, 0, 0.05);
      border-radius: 2px;
    }
  }

  .article-content__tags {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;

    >* {
      margin: 5px 10px 5px 0;
    }
  }

  .article-content__date {
    color: rgba(0, 0, 0, 0.5);
    margin-bottom: 5px;
    width: 100%;
  }

  .article-content__author-block {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 20px;

    >* {
      margin: 5px 15px 5px 0;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .article-content__author-label {
    width: 100%;
    color: rgba(0, 0, 0, 0.5);
  }

  .article-content__share-block {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    >* {
      margin: 5px 10px 5px 0;

      &:first-child {
        margin-right: 20px;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  @media (min-width: 480px) {
    .article-content__author-block>* {
      margin: 0 15px 0 0;
    }

    .article-content__share-block>* {
      margin: 0 10px 0 0;
    }

    .article-content__author-label {
      width: auto;
    }
  }

  @media (min-width: 680px) {
    .article-content__tags {
      margin-bottom: 0;

      >* {
        margin: 0 10px 0 0;
      }
    }

    .article-content__date {
      width: auto;
    }
  }

  @media (min-width: 768px) {
    .article-content__footer-top {
      margin: 80px 0px 20px;
      padding-bottom: 25px;
    }
  }

  @media (min-width: 860px) {
    .article-content__author-block {
      width: auto;
      margin-bottom: 0;
    }
  }

  @media (min-width: 992px) {
    .article-content__footer-top {
      margin: 100px 0px 25px;
      padding-bottom: 31px;
    }
  }

  .headline {
    margin: 0 0 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;

    >*:first-child {
      margin-bottom: 15px;
    }
  }

  @media screen and (min-width: 768px) {
    .headline {
      margin: 0 0 30px 0;
    }
  }

  @media screen and (min-width: 992px) {
    .headline {
      margin: 0 0 40px 0;
    }
  }

  @media (min-width: 480px) {
    .headline {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      >*:first-child {
        margin-bottom: 0;
      }
    }
  }

  .input-combined {
    display: inline-block;
    position: relative;
    margin-right: -4px;
    max-width: 100%;
  }

  .input,
  .input_icon-left,
  .input_rounded_icon-left,
  .input_icon-right,
  .input_rounded_icon-right,
  .input_icon-both,
  .input_rounded_icon-both,
  .input_button,
  .input_rounded,
  .input_big,
  .input_big_icon-left,
  .input_big_rounded_icon-left,
  .input_big_icon-right,
  .input_big_rounded_icon-right,
  .input_big_icon-both,
  .input_big_rounded_icon-both,
  .input_big_rounded {
    max-width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 15px 18px;
    color: black;
    height: 50px;
    font-size: 17px;
    line-height: 18px;
    letter-spacing: -0.3px;
  }

  @media screen and (min-width: 768px) {

    .input,
    .input_icon-left,
    .input_rounded_icon-left,
    .input_icon-right,
    .input_rounded_icon-right,
    .input_icon-both,
    .input_rounded_icon-both,
    .input_button,
    .input_rounded,
    .input_big,
    .input_big_icon-left,
    .input_big_rounded_icon-left,
    .input_big_icon-right,
    .input_big_rounded_icon-right,
    .input_big_icon-both,
    .input_big_rounded_icon-both,
    .input_big_rounded {
      font-size: 18px;
      line-height: 20px;
      letter-spacing: -0.3px;
    }
  }

  .input~svg path,
  .input_icon-left~svg path,
  .input_rounded_icon-left~svg path,
  .input_icon-right~svg path,
  .input_rounded_icon-right~svg path,
  .input_icon-both~svg path,
  .input_rounded_icon-both~svg path,
  .input_button~svg path,
  .input_rounded~svg path,
  .input_big~svg path,
  .input_big_icon-left~svg path,
  .input_big_rounded_icon-left~svg path,
  .input_big_icon-right~svg path,
  .input_big_rounded_icon-right~svg path,
  .input_big_icon-both~svg path,
  .input_big_rounded_icon-both~svg path,
  .input_big_rounded~svg path {
    fill: rgba(0, 0, 0, 0.3);
  }

  .input::-moz-placeholder,
  .input_icon-left::-moz-placeholder,
  .input_rounded_icon-left::-moz-placeholder,
  .input_icon-right::-moz-placeholder,
  .input_rounded_icon-right::-moz-placeholder,
  .input_icon-both::-moz-placeholder,
  .input_rounded_icon-both::-moz-placeholder,
  .input_button::-moz-placeholder,
  .input_rounded::-moz-placeholder,
  .input_big::-moz-placeholder,
  .input_big_icon-left::-moz-placeholder,
  .input_big_rounded_icon-left::-moz-placeholder,
  .input_big_icon-right::-moz-placeholder,
  .input_big_rounded_icon-right::-moz-placeholder,
  .input_big_icon-both::-moz-placeholder,
  .input_big_rounded_icon-both::-moz-placeholder,
  .input_big_rounded::-moz-placeholder,
  .input:-ms-input-placeholder,
  .input_icon-left:-ms-input-placeholder,
  .input_rounded_icon-left:-ms-input-placeholder,
  .input_icon-right:-ms-input-placeholder,
  .input_rounded_icon-right:-ms-input-placeholder,
  .input_icon-both:-ms-input-placeholder,
  .input_rounded_icon-both:-ms-input-placeholder,
  .input_button:-ms-input-placeholder,
  .input_rounded:-ms-input-placeholder,
  .input_big:-ms-input-placeholder,
  .input_big_icon-left:-ms-input-placeholder,
  .input_big_rounded_icon-left:-ms-input-placeholder,
  .input_big_icon-right:-ms-input-placeholder,
  .input_big_rounded_icon-right:-ms-input-placeholder,
  .input_big_icon-both:-ms-input-placeholder,
  .input_big_rounded_icon-both:-ms-input-placeholder,
  .input_big_rounded:-ms-input-placeholder,
  .input::placeholder,
  .input_icon-left::placeholder,
  .input_rounded_icon-left::placeholder,
  .input_icon-right::placeholder,
  .input_rounded_icon-right::placeholder,
  .input_icon-both::placeholder,
  .input_rounded_icon-both::placeholder,
  .input_button::placeholder,
  .input_rounded::placeholder,
  .input_big::placeholder,
  .input_big_icon-left::placeholder,
  .input_big_rounded_icon-left::placeholder,
  .input_big_icon-right::placeholder,
  .input_big_rounded_icon-right::placeholder,
  .input_big_icon-both::placeholder,
  .input_big_rounded_icon-both::placeholder,
  .input_big_rounded::placeholder {
    color: rgba(0, 0, 0, 0.3);
  }

  .input_icon-left,
  .input_rounded_icon-left {
    padding-left: 44px;
  }

  .input_icon-left+svg,
  .input_rounded_icon-left+svg {
    position: absolute;
    width: 16.68px;
    height: auto;
    top: 50%;
    left: 18.33px;
    transform: translate(0, -50%);
  }

  .input_icon-right,
  .input_rounded_icon-right {
    padding-right: 44px;
  }

  .input_icon-right+svg,
  .input_rounded_icon-right+svg {
    position: absolute;
    width: 16.68px;
    height: auto;
    top: 50%;
    right: 18.33px;
    transform: translate(0, -50%);
  }

  .input_icon-both,
  .input_rounded_icon-both {
    padding-left: 44px;
    padding-right: 44px;
  }

  .input_icon-both+svg,
  .input_rounded_icon-both+svg {
    position: absolute;
    width: 16.68px;
    height: auto;
    top: 50%;
    left: 18.33px;
    transform: translate(0, -50%);
  }

  .input_icon-both+svg+svg,
  .input_rounded_icon-both+svg+svg {
    position: absolute;
    width: 16.68px;
    height: auto;
    top: 50%;
    right: 18.33px;
    transform: translate(0, -50%);
  }

  .input_button {
    background-color: #5d8cff;
    color: #fff;
    border: none;
    transition: background-color 0.15s ease-in;
    font-weight: 500;

    &:hover {
      background-color: rgba(93, 140, 255, 0.8);
      cursor: pointer;
    }

    &:active {
      background-color: #547ee6;
    }
  }

  .input_rounded,
  .input_rounded_icon-left,
  .input_rounded_icon-right,
  .input_rounded_icon-both {
    border-radius: 25px;
  }

  .input_big,
  .input_big_icon-left,
  .input_big_rounded_icon-left,
  .input_big_icon-right,
  .input_big_rounded_icon-right {
    height: 60px;
    padding: 18px 22px;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: -0.5px;
  }

  @media screen and (min-width: 768px) {

    .input_big,
    .input_big_icon-left,
    .input_big_rounded_icon-left,
    .input_big_icon-right,
    .input_big_rounded_icon-right {
      font-size: 20px;
      line-height: 24px;
      letter-spacing: -0.6px;
    }
  }

  .input_big_icon-left,
  .input_big_rounded_icon-left {
    padding-left: 54px;
  }

  .input_big_icon-left+svg,
  .input_big_rounded_icon-left+svg {
    position: absolute;
    width: 20px;
    height: auto;
    top: 50%;
    left: 22px;
    transform: translate(0, -50%);
  }

  .input_big_icon-right,
  .input_big_rounded_icon-right {
    padding-left: 54px;
  }

  .input_big_icon-right+svg,
  .input_big_rounded_icon-right+svg {
    position: absolute;
    width: 20px;
    height: auto;
    top: 50%;
    right: 22px;
    transform: translate(0, -50%);
  }

  .input_big_icon-both,
  .input_big_rounded_icon-both {
    padding-left: 54px;
    padding-right: 54px;
  }

  .input_big_icon-both+svg,
  .input_big_rounded_icon-both+svg {
    position: absolute;
    width: 20px;
    height: auto;
    top: 50%;
    left: 22px;
    transform: translate(0, -50%);
  }

  .input_big_icon-both+svg+svg,
  .input_big_rounded_icon-both+svg+svg {
    position: absolute;
    width: 20px;
    height: auto;
    top: 50%;
    right: 22px;
    transform: translate(0, -50%);
  }

  .input_big_rounded,
  .input_big_rounded_icon-left,
  .input_big_rounded_icon-right,
  .input_big_rounded_icon-both {
    border-radius: 30px;
  }

  .show-more-block {
    display: flex;
    justify-content: center;
  }

  .modal {
    display: none;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    padding: 30px;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: black;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1001;

    a {
      color: rgba(0, 0, 0, 0.8);
      text-decoration: none;

      &:visited {
        color: rgba(0, 0, 0, 0.8);
      }

      &:hover {
        cursor: pointer;
        color: #547ee6;
      }
    }

    .password-toggler {
      cursor: pointer;
    }
  }

  .modal__content {
    padding: 25px 30px 30px;
    position: relative;
    background-color: #fff;
    border-radius: 5px;
    overflow: hidden;
    max-width: 430px;
    width: 100%;
    top: calc(50% - 30px);
    left: 50%;
    transform: translate(-50%, -50%);
    margin-top: 30px;
  }

  @media screen and (min-width: 768px) {
    .modal__content {
      padding: 32px 35px 35px;
    }
  }

  @media screen and (min-width: 992px) {
    .modal__content {
      padding: 33px 40px 40px;
    }
  }

  .modal__heading {
    margin: 0 0 15px 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;

    p {
      color: rgba(0, 0, 0, 0.5);
    }

    a {
      margin-left: 5px;
    }
  }

  @media screen and (min-width: 768px) {
    .modal__heading {
      margin: 0 0 20px 0;
    }
  }

  @media screen and (min-width: 992px) {
    .modal__heading {
      margin: 0 0 23px 0;
    }
  }

  .modal__name {
    margin-right: auto;
    width: 100%;
    margin-bottom: 10px;
  }

  .modal {
    .button {
      width: 100%;
    }

    .input,
    .input_icon-left,
    .input_rounded_icon-left,
    .input_icon-right,
    .input_rounded_icon-right,
    .input_icon-both,
    .input_rounded_icon-both,
    .input_button,
    .input_rounded,
    .input_big,
    .input_big_icon-left,
    .input_big_rounded_icon-left,
    .input_big_icon-right,
    .input_big_rounded_icon-right,
    .input_big_icon-both,
    .input_big_rounded_icon-both,
    .input_big_rounded,
    .input-combined,
    .input_icon-left,
    .input_rounded_icon-left,
    .input_icon-right,
    .input_rounded_icon-right,
    .input_icon-both,
    .input_rounded_icon-both {
      width: 100%;
      border-radius: 5px;
    }
  }

  .modal__body form>* {
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .modal__footer {
    margin-top: 17px;
    color: rgba(0, 0, 0, 0.5);
  }

  .modal__footer-delimiter {
    margin: 18px 0 20px 0;
    display: flex;
    align-items: center;

    p {
      margin: 0 8px;
    }
  }

  @media screen and (min-width: 768px) {
    .modal__footer-delimiter {
      margin: 20px 0 22px 0;
    }
  }

  @media screen and (min-width: 992px) {
    .modal__footer-delimiter {
      margin: 22px 0 24px 0;
    }
  }

  .modal__footer-delimiter-line {
    flex-grow: 1;
    height: 1.5px;
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 2px;
  }

  .modal__small-text {
    font-size: 13px;
    line-height: 15px;
    letter-spacing: -0.2px;
    text-align: center;
  }

  @media screen and (min-width: 768px) {
    .modal__small-text {
      font-size: 13px;
      line-height: 18px;
      letter-spacing: -0.2px;
    }
  }

  .modal__footer-icons {
    display: flex;
    justify-content: center;
  }

  .modal__footer-icons-title {
    margin-bottom: 20px;
  }

  .modal__footer-icon {
    position: relative;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: rgba(93, 140, 255, 0.1);
    transition: background-color 0.15s ease-in;
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 16px;
      height: auto;

      path {
        fill: #5d8cff;
        transition: fill 0.15s ease-in;
      }
    }

    &:hover {
      background-color: rgba(93, 140, 255, 0.05);
      opacity: 0.5;
    }
  }

  .modal__greetings-title {
    margin-bottom: 11px;
  }

  .modal__greetings-text {
    margin: 0 0 20px 0;
    color: rgba(0, 0, 0, 0.5);
  }

  @media screen and (min-width: 768px) {
    .modal__greetings-text {
      margin: 0 0 25px 0;
    }
  }

  @media screen and (min-width: 992px) {
    .modal__greetings-text {
      margin: 0 0 30px 0;
    }
  }

  .modal__greetings-photo {
    position: relative;
    margin: 0 auto;
    width: 110px;
    height: 110px;
    border: 1px dashed rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    margin: 0 auto 20px auto;

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  @media screen and (min-width: 768px) {
    .modal__greetings-photo {
      margin: 0 auto 25px auto;
    }
  }

  @media screen and (min-width: 992px) {
    .modal__greetings-photo {
      margin: 0 auto 30px auto;
    }
  }

  @-webkit-keyframes animatetop {
    0% {
      top: -300px;
      opacity: 0;
    }

    80% {
      top: calc(50% - 10px);
      opacity: 1;
    }

    100% {
      top: calc(50% - 30px);
      opacity: 1;
    }
  }

  @keyframes animatetop {
    0% {
      top: -300px;
      opacity: 0;
    }

    80% {
      top: calc(50% - 10px);
      opacity: 1;
    }

    100% {
      top: calc(50% - 30px);
      opacity: 1;
    }
  }

  @media (min-width: 460px) {
    .modal__heading {
      justify-content: flex-end;
    }

    .modal__name {
      width: auto;
      margin-bottom: 0;
    }
  }

  @media screen and (orientation: landscape) and (max-width: 991px) {
    .modal__content {
      top: 0;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }

  @media screen and (max-width: 374px) {
    .modal__content {
      top: 0;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }

  .search-results {
    position: fixed;
    top: 75px;
    right: 0;
    opacity: 0;
    z-index: -1;
    width: 100%;
    padding: 20px 30px 20px;
    background-color: #fff;
    box-shadow: 0px 30px 100px rgba(0, 0, 0, 0.08);
    border-radius: 5px;

    &.open {
      opacity: 1;
      z-index: 101;
    }
  }

  @media screen and (min-width: 768px) {
    .search-results {
      padding: 25px 35px 20px;
    }
  }

  @media screen and (min-width: 992px) {
    .search-results {
      padding: 30px 40px 23px;
    }
  }

  .search-results__header {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 15px 0;
    padding: 0 0 20px 0;

    &:after {
      position: absolute;
      bottom: 0;
      left: 0;
      display: block;
      content: '';
      width: 100%;
      height: 1.5px;
      background: rgba(0, 0, 0, 0.05);
      border-radius: 2px;
    }
  }

  @media screen and (min-width: 768px) {
    .search-results__header {
      margin: 0 0 18px 0;
    }
  }

  @media screen and (min-width: 992px) {
    .search-results__header {
      margin: 0 0 20px 0;
    }
  }

  @media screen and (min-width: 768px) {
    .search-results__header {
      padding: 0 0 22px 0;
    }
  }

  @media screen and (min-width: 992px) {
    .search-results__header {
      padding: 0 0 23.5px 0;
    }
  }

  .search-results__author {
    flex-wrap: wrap;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0 0 10px 0;
    padding: 0 0 20px 0;

    &:after {
      position: absolute;
      bottom: 0;
      left: 0;
      display: block;
      content: '';
      width: 100%;
      height: 1.5px;
      background: rgba(0, 0, 0, 0.05);
      border-radius: 2px;
    }

    >* {
      margin: 15px 15px 15px 0;

      &:last-child {
        margin: 0 auto 0 auto;
      }
    }
  }

  @media screen and (min-width: 768px) {
    .search-results__author {
      margin: 0 0 12.5px 0;
    }
  }

  @media screen and (min-width: 992px) {
    .search-results__author {
      margin: 0 0 15px 0;
    }
  }

  @media screen and (min-width: 768px) {
    .search-results__author {
      padding: 0 0 22px 0;
    }
  }

  @media screen and (min-width: 992px) {
    .search-results__author {
      padding: 0 0 23.5px 0;
    }
  }

  .search-results__article {
    display: flex;
    position: relative;
    margin: 0 0 10px 0;
    padding: 0 0 20px 0;

    &:after {
      position: absolute;
      bottom: 0;
      left: 0;
      display: block;
      content: '';
      width: 100%;
      height: 1.5px;
      background: rgba(0, 0, 0, 0.05);
      border-radius: 2px;
    }
  }

  @media screen and (min-width: 768px) {
    .search-results__article {
      margin: 0 0 12.5px 0;
    }
  }

  @media screen and (min-width: 992px) {
    .search-results__article {
      margin: 0 0 15px 0;
    }
  }

  @media screen and (min-width: 768px) {
    .search-results__article {
      padding: 0 0 22px 0;
    }
  }

  @media screen and (min-width: 992px) {
    .search-results__article {
      padding: 0 0 23.5px 0;
    }
  }

  .search-results__article-content {
    flex: 1;
  }

  .search-results__article-img {
    display: none;
    width: 80px;
    min-width: 80px;
    height: 60px;
    min-height: 60px;
    border-radius: 5px;
  }

  .search-results__article-header {
    color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: space-between;
    margin-left: 0;
  }

  .search-results__article-body {
    display: flex;
    justify-content: space-between;
    margin-left: 0;
    margin-top: 12px;

    a {
      color: inherit;
      text-decoration: none;
      transition: color 0.15s ease-in;

      &:visited {
        color: black;
      }

      &:hover {
        color: #e11d74;
      }
    }

    svg {
      margin-left: 25px;
      min-width: 14px;
      min-height: 21px;

      &:hover {
        cursor: pointer;

        path {
          fill: #e11d74;
        }
      }
    }
  }

  .search-results__article-title {
    font-size: 18px;
    font-weight: 500;
    line-height: 25px;
    letter-spacing: -0.3px;

    &:hover {
      cursor: pointer;
      color: #e11d74;
    }
  }

  .search-results__link {
    color: rgba(0, 0, 0, 0.3);
    display: block;
    text-decoration: none;
    text-align: center;
    font-size: 17px;
    line-height: 18px;
    letter-spacing: -0.3px;
    transition: color 0.15s ease-in;

    &:visited {
      color: rgba(0, 0, 0, 0.3);
    }

    &:hover {
      color: #e11d74;
    }
  }

  @media screen and (min-width: 768px) {
    .search-results__link {
      font-size: 18px;
      line-height: 20px;
      letter-spacing: -0.3px;
    }
  }

  @media (min-width: 375px) {
    .search-results__author>* {
      margin: 0 15px 0 0;

      &:last-child {
        margin: 0 0 0 auto;
      }
    }
  }

  @media (min-width: 600px) {
    .search-results__article-img {
      display: block;
    }

    .search-results__article-header,
    .search-results__article-body {
      margin-left: 20px;
    }
  }

  @media (min-width: 768px) {
    .search-results {
      top: 75px;
    }

    .search-results__header:last-of-type {
      margin-top: 40px;
    }
  }

  @media (min-width: 992px) {
    .search-results {
      width: 580px;
      top: 75px;
      right: 110px;
    }
  }

  a {
    color: inherit;
    color: rgba(93, 140, 255, 0.8);

    &:visited,
    &:active {
      color: rgba(93, 140, 255, 0.8);
    }

    &:hover {
      color: #547ee6;
    }
  }

  h1,
  h5 {
    font-weight: 600;
  }

  h2,
  h3,
  h4,
  .lead_medium {
    font-weight: 500;
  }

  .body_large,
  .body_normal,
  .body_small,
  .lead {
    font-weight: 400;
  }

  .display {
    font-family: Inter, sans-serif;
    font-size: 120px;
    font-weight: 500;
    line-height: 120px;
    letter-spacing: -6px;
  }

  h1 {
    font-size: 38px;
    line-height: 42px;
    letter-spacing: -2.5px;
  }

  @media screen and (min-width: 768px) {
    h1 {
      font-size: 72px;
      line-height: 80px;
      letter-spacing: -4.5px;
    }
  }

  h2 {
    font-size: 32px;
    line-height: 40px;
    letter-spacing: -1.8px;
  }

  @media screen and (min-width: 768px) {
    h2 {
      font-size: 60px;
      line-height: 65px;
      letter-spacing: -2.5px;
    }
  }

  h3 {
    font-size: 26px;
    line-height: 30px;
    letter-spacing: -0.9px;
  }

  @media screen and (min-width: 768px) {
    h3 {
      font-size: 48px;
      line-height: 54px;
      letter-spacing: -1.6px;
    }
  }

  h4 {
    font-size: 24px;
    line-height: 28px;
    letter-spacing: -0.8px;
  }

  @media screen and (min-width: 768px) {
    h4 {
      font-size: 36px;
      line-height: 44px;
      letter-spacing: -1.2px;
    }
  }

  h5 {
    font-size: 22px;
    line-height: 26px;
    letter-spacing: -0.55px;
  }

  @media screen and (min-width: 768px) {
    h5 {
      font-size: 28px;
      line-height: 34px;
      letter-spacing: -0.8px;
    }
  }

  .lead,
  .lead_medium {
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.5px;
  }

  p {
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.4px;
  }

  .body_large {
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -0.6px;
  }

  @media screen and (min-width: 768px) {
    .body_large {
      font-size: 21px;
      line-height: 30px;
      letter-spacing: -0.8px;
    }
  }

  .body_normal {
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.5px;
  }

  @media screen and (min-width: 768px) {
    .body_normal {
      font-size: 17px;
      line-height: 25px;
      letter-spacing: -0.7px;
    }
  }

  .body_small {
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.5px;
  }

  @media screen and (min-width: 768px) {
    .body_small {
      font-size: 15px;
      line-height: 23px;
      letter-spacing: -0.6px;
    }
  }

  .caption_1 {
    font-size: 18px;
    line-height: 20px;
    letter-spacing: -0.5px;
    font-weight: 400;
  }

  .caption_1_med {
    font-size: 18px;
    line-height: 20px;
    letter-spacing: -0.5px;
    font-weight: 400;
    font-weight: 500;
  }

  @media screen and (min-width: 768px) {

    .caption_1,
    .caption_1_med {
      font-size: 20px;
      line-height: 24px;
      letter-spacing: -0.6px;
    }
  }

  .caption_2 {
    font-size: 17px;
    line-height: 18px;
    letter-spacing: -0.3px;
    font-weight: 400;
  }

  .caption_2_med {
    font-size: 17px;
    line-height: 18px;
    letter-spacing: -0.3px;
    font-weight: 400;
    font-weight: 500;
  }

  @media screen and (min-width: 768px) {

    .caption_2,
    .caption_2_med {
      font-size: 18px;
      line-height: 20px;
      letter-spacing: -0.3px;
    }
  }

  .caption_3 {
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -0.5px;
    font-weight: 400;
  }

  .caption_3_med {
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -0.5px;
    font-weight: 400;
    font-weight: 500;
  }

  @media screen and (min-width: 768px) {

    .caption_3,
    .caption_3_med {
      font-size: 16px;
      line-height: 18px;
      letter-spacing: -0.5px;
    }
  }

  .caption_4 {
    font-size: 13px;
    line-height: 15px;
    letter-spacing: -0.3px;
    font-weight: 400;
  }

  .caption_4_med {
    font-size: 13px;
    line-height: 15px;
    letter-spacing: -0.3px;
    font-weight: 400;
    font-weight: 500;
  }

  @media screen and (min-width: 768px) {

    .caption_4,
    .caption_4_med {
      font-size: 13px;
      line-height: 15px;
      letter-spacing: -0.3px;
    }
  }

  .wrap {
    display: flex;
    flex-direction: column;
    flex-grow: 2;
    min-height: 100vh;

    .outer {
      overflow: hidden;
    }

    .content {
      width: 100%;
      padding: 0 30px;
      max-width: 1290px;
      margin: 0 auto;
    }

    .content__thin {
      width: 100%;
      padding: 0 30px;
      max-width: 1290px;
      margin: 0 auto;
      padding: 0 30px;
    }

    .content .content__thin,
    .content__thin .content__thin {
      padding: 0;
    }
  }

  .flex-container {
    display: flex;
    flex-wrap: wrap;
  }

  .img_cropped {
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 57.5%;

    img {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      -o-object-fit: cover;
      object-fit: cover;
      -o-object-position: center;
      object-position: center;
    }
  }

  .img_round-borders {
    border-radius: 5px;

    img {
      border-radius: 5px;
    }
  }

  .img_bottom-margin {
    margin-bottom: 50px;
  }

  .img__caption {
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.5px;
    color: rgba(0, 0, 0, 0.3);
    text-align: center;
    margin: 15px 0 30px;
  }

  @media screen and (min-width: 768px) {
    .img__caption {
      font-size: 17px;
      line-height: 25px;
      letter-spacing: -0.7px;
    }
  }

  .text-left {
    text-align: left;
  }

  .text-right {
    text-align: right;
  }

  .text-center {
    text-align: center;
  }

  .notify_error {
    background-color: #ff4e4e !important;
    color: #fff !important;
  }

  .notify_warning {
    background-color: #feb72f !important;
    color: #fff !important;
  }

  .notify_success {
    background-color: #90da1a !important;
    color: #fff !important;
  }

  .hidden {
    display: none !important;
  }

  .p-l-0 {
    padding-left: 0px !important;
  }

  .p-l-1 {
    padding-left: 1px !important;
  }

  .p-l-2 {
    padding-left: 2px !important;
  }

  .p-l-3 {
    padding-left: 3px !important;
  }

  .p-l-4 {
    padding-left: 4px !important;
  }

  .p-l-5 {
    padding-left: 5px !important;
  }

  .p-l-6 {
    padding-left: 6px !important;
  }

  .p-l-7 {
    padding-left: 7px !important;
  }

  .p-l-8 {
    padding-left: 8px !important;
  }

  .p-l-9 {
    padding-left: 9px !important;
  }

  .p-l-10 {
    padding-left: 10px !important;
  }

  .p-l-11 {
    padding-left: 11px !important;
  }

  .p-l-12 {
    padding-left: 12px !important;
  }

  .p-l-13 {
    padding-left: 13px !important;
  }

  .p-l-14 {
    padding-left: 14px !important;
  }

  .p-l-15 {
    padding-left: 15px !important;
  }

  .p-l-16 {
    padding-left: 16px !important;
  }

  .p-l-17 {
    padding-left: 17px !important;
  }

  .p-l-18 {
    padding-left: 18px !important;
  }

  .p-l-19 {
    padding-left: 19px !important;
  }

  .p-l-20 {
    padding-left: 20px !important;
  }

  .p-l-21 {
    padding-left: 21px !important;
  }

  .p-l-22 {
    padding-left: 22px !important;
  }

  .p-l-23 {
    padding-left: 23px !important;
  }

  .p-l-24 {
    padding-left: 24px !important;
  }

  .p-l-25 {
    padding-left: 25px !important;
  }

  .p-l-26 {
    padding-left: 26px !important;
  }

  .p-l-27 {
    padding-left: 27px !important;
  }

  .p-l-28 {
    padding-left: 28px !important;
  }

  .p-l-29 {
    padding-left: 29px !important;
  }

  .p-l-30 {
    padding-left: 30px !important;
  }

  .p-l-31 {
    padding-left: 31px !important;
  }

  .p-l-32 {
    padding-left: 32px !important;
  }

  .p-l-33 {
    padding-left: 33px !important;
  }

  .p-l-34 {
    padding-left: 34px !important;
  }

  .p-l-35 {
    padding-left: 35px !important;
  }

  .p-l-36 {
    padding-left: 36px !important;
  }

  .p-l-37 {
    padding-left: 37px !important;
  }

  .p-l-38 {
    padding-left: 38px !important;
  }

  .p-l-39 {
    padding-left: 39px !important;
  }

  .p-l-40 {
    padding-left: 40px !important;
  }

  .p-l-41 {
    padding-left: 41px !important;
  }

  .p-l-42 {
    padding-left: 42px !important;
  }

  .p-l-43 {
    padding-left: 43px !important;
  }

  .p-l-44 {
    padding-left: 44px !important;
  }

  .p-l-45 {
    padding-left: 45px !important;
  }

  .p-l-46 {
    padding-left: 46px !important;
  }

  .p-l-47 {
    padding-left: 47px !important;
  }

  .p-l-48 {
    padding-left: 48px !important;
  }

  .p-l-49 {
    padding-left: 49px !important;
  }

  .p-l-50 {
    padding-left: 50px !important;
  }

  @media screen and (min-width: 768px) {
    .p-l-sm-0 {
      padding-left: 0px !important;
    }

    .p-l-sm-1 {
      padding-left: 1px !important;
    }

    .p-l-sm-2 {
      padding-left: 2px !important;
    }

    .p-l-sm-3 {
      padding-left: 3px !important;
    }

    .p-l-sm-4 {
      padding-left: 4px !important;
    }

    .p-l-sm-5 {
      padding-left: 5px !important;
    }

    .p-l-sm-6 {
      padding-left: 6px !important;
    }

    .p-l-sm-7 {
      padding-left: 7px !important;
    }

    .p-l-sm-8 {
      padding-left: 8px !important;
    }

    .p-l-sm-9 {
      padding-left: 9px !important;
    }

    .p-l-sm-10 {
      padding-left: 10px !important;
    }

    .p-l-sm-11 {
      padding-left: 11px !important;
    }

    .p-l-sm-12 {
      padding-left: 12px !important;
    }

    .p-l-sm-13 {
      padding-left: 13px !important;
    }

    .p-l-sm-14 {
      padding-left: 14px !important;
    }

    .p-l-sm-15 {
      padding-left: 15px !important;
    }

    .p-l-sm-16 {
      padding-left: 16px !important;
    }

    .p-l-sm-17 {
      padding-left: 17px !important;
    }

    .p-l-sm-18 {
      padding-left: 18px !important;
    }

    .p-l-sm-19 {
      padding-left: 19px !important;
    }

    .p-l-sm-20 {
      padding-left: 20px !important;
    }

    .p-l-sm-21 {
      padding-left: 21px !important;
    }

    .p-l-sm-22 {
      padding-left: 22px !important;
    }

    .p-l-sm-23 {
      padding-left: 23px !important;
    }

    .p-l-sm-24 {
      padding-left: 24px !important;
    }

    .p-l-sm-25 {
      padding-left: 25px !important;
    }

    .p-l-sm-26 {
      padding-left: 26px !important;
    }

    .p-l-sm-27 {
      padding-left: 27px !important;
    }

    .p-l-sm-28 {
      padding-left: 28px !important;
    }

    .p-l-sm-29 {
      padding-left: 29px !important;
    }

    .p-l-sm-30 {
      padding-left: 30px !important;
    }

    .p-l-sm-31 {
      padding-left: 31px !important;
    }

    .p-l-sm-32 {
      padding-left: 32px !important;
    }

    .p-l-sm-33 {
      padding-left: 33px !important;
    }

    .p-l-sm-34 {
      padding-left: 34px !important;
    }

    .p-l-sm-35 {
      padding-left: 35px !important;
    }

    .p-l-sm-36 {
      padding-left: 36px !important;
    }

    .p-l-sm-37 {
      padding-left: 37px !important;
    }

    .p-l-sm-38 {
      padding-left: 38px !important;
    }

    .p-l-sm-39 {
      padding-left: 39px !important;
    }

    .p-l-sm-40 {
      padding-left: 40px !important;
    }

    .p-l-sm-41 {
      padding-left: 41px !important;
    }

    .p-l-sm-42 {
      padding-left: 42px !important;
    }

    .p-l-sm-43 {
      padding-left: 43px !important;
    }

    .p-l-sm-44 {
      padding-left: 44px !important;
    }

    .p-l-sm-45 {
      padding-left: 45px !important;
    }

    .p-l-sm-46 {
      padding-left: 46px !important;
    }

    .p-l-sm-47 {
      padding-left: 47px !important;
    }

    .p-l-sm-48 {
      padding-left: 48px !important;
    }

    .p-l-sm-49 {
      padding-left: 49px !important;
    }

    .p-l-sm-50 {
      padding-left: 50px !important;
    }
  }

  @media screen and (min-width: 992px) {
    .p-l-md-0 {
      padding-left: 0px !important;
    }

    .p-l-md-1 {
      padding-left: 1px !important;
    }

    .p-l-md-2 {
      padding-left: 2px !important;
    }

    .p-l-md-3 {
      padding-left: 3px !important;
    }

    .p-l-md-4 {
      padding-left: 4px !important;
    }

    .p-l-md-5 {
      padding-left: 5px !important;
    }

    .p-l-md-6 {
      padding-left: 6px !important;
    }

    .p-l-md-7 {
      padding-left: 7px !important;
    }

    .p-l-md-8 {
      padding-left: 8px !important;
    }

    .p-l-md-9 {
      padding-left: 9px !important;
    }

    .p-l-md-10 {
      padding-left: 10px !important;
    }

    .p-l-md-11 {
      padding-left: 11px !important;
    }

    .p-l-md-12 {
      padding-left: 12px !important;
    }

    .p-l-md-13 {
      padding-left: 13px !important;
    }

    .p-l-md-14 {
      padding-left: 14px !important;
    }

    .p-l-md-15 {
      padding-left: 15px !important;
    }

    .p-l-md-16 {
      padding-left: 16px !important;
    }

    .p-l-md-17 {
      padding-left: 17px !important;
    }

    .p-l-md-18 {
      padding-left: 18px !important;
    }

    .p-l-md-19 {
      padding-left: 19px !important;
    }

    .p-l-md-20 {
      padding-left: 20px !important;
    }

    .p-l-md-21 {
      padding-left: 21px !important;
    }

    .p-l-md-22 {
      padding-left: 22px !important;
    }

    .p-l-md-23 {
      padding-left: 23px !important;
    }

    .p-l-md-24 {
      padding-left: 24px !important;
    }

    .p-l-md-25 {
      padding-left: 25px !important;
    }

    .p-l-md-26 {
      padding-left: 26px !important;
    }

    .p-l-md-27 {
      padding-left: 27px !important;
    }

    .p-l-md-28 {
      padding-left: 28px !important;
    }

    .p-l-md-29 {
      padding-left: 29px !important;
    }

    .p-l-md-30 {
      padding-left: 30px !important;
    }

    .p-l-md-31 {
      padding-left: 31px !important;
    }

    .p-l-md-32 {
      padding-left: 32px !important;
    }

    .p-l-md-33 {
      padding-left: 33px !important;
    }

    .p-l-md-34 {
      padding-left: 34px !important;
    }

    .p-l-md-35 {
      padding-left: 35px !important;
    }

    .p-l-md-36 {
      padding-left: 36px !important;
    }

    .p-l-md-37 {
      padding-left: 37px !important;
    }

    .p-l-md-38 {
      padding-left: 38px !important;
    }

    .p-l-md-39 {
      padding-left: 39px !important;
    }

    .p-l-md-40 {
      padding-left: 40px !important;
    }

    .p-l-md-41 {
      padding-left: 41px !important;
    }

    .p-l-md-42 {
      padding-left: 42px !important;
    }

    .p-l-md-43 {
      padding-left: 43px !important;
    }

    .p-l-md-44 {
      padding-left: 44px !important;
    }

    .p-l-md-45 {
      padding-left: 45px !important;
    }

    .p-l-md-46 {
      padding-left: 46px !important;
    }

    .p-l-md-47 {
      padding-left: 47px !important;
    }

    .p-l-md-48 {
      padding-left: 48px !important;
    }

    .p-l-md-49 {
      padding-left: 49px !important;
    }

    .p-l-md-50 {
      padding-left: 50px !important;
    }
  }

  @media screen and (min-width: 1200px) {
    .p-l-lg-0 {
      padding-left: 0px !important;
    }

    .p-l-lg-1 {
      padding-left: 1px !important;
    }

    .p-l-lg-2 {
      padding-left: 2px !important;
    }

    .p-l-lg-3 {
      padding-left: 3px !important;
    }

    .p-l-lg-4 {
      padding-left: 4px !important;
    }

    .p-l-lg-5 {
      padding-left: 5px !important;
    }

    .p-l-lg-6 {
      padding-left: 6px !important;
    }

    .p-l-lg-7 {
      padding-left: 7px !important;
    }

    .p-l-lg-8 {
      padding-left: 8px !important;
    }

    .p-l-lg-9 {
      padding-left: 9px !important;
    }

    .p-l-lg-10 {
      padding-left: 10px !important;
    }

    .p-l-lg-11 {
      padding-left: 11px !important;
    }

    .p-l-lg-12 {
      padding-left: 12px !important;
    }

    .p-l-lg-13 {
      padding-left: 13px !important;
    }

    .p-l-lg-14 {
      padding-left: 14px !important;
    }

    .p-l-lg-15 {
      padding-left: 15px !important;
    }

    .p-l-lg-16 {
      padding-left: 16px !important;
    }

    .p-l-lg-17 {
      padding-left: 17px !important;
    }

    .p-l-lg-18 {
      padding-left: 18px !important;
    }

    .p-l-lg-19 {
      padding-left: 19px !important;
    }

    .p-l-lg-20 {
      padding-left: 20px !important;
    }

    .p-l-lg-21 {
      padding-left: 21px !important;
    }

    .p-l-lg-22 {
      padding-left: 22px !important;
    }

    .p-l-lg-23 {
      padding-left: 23px !important;
    }

    .p-l-lg-24 {
      padding-left: 24px !important;
    }

    .p-l-lg-25 {
      padding-left: 25px !important;
    }

    .p-l-lg-26 {
      padding-left: 26px !important;
    }

    .p-l-lg-27 {
      padding-left: 27px !important;
    }

    .p-l-lg-28 {
      padding-left: 28px !important;
    }

    .p-l-lg-29 {
      padding-left: 29px !important;
    }

    .p-l-lg-30 {
      padding-left: 30px !important;
    }

    .p-l-lg-31 {
      padding-left: 31px !important;
    }

    .p-l-lg-32 {
      padding-left: 32px !important;
    }

    .p-l-lg-33 {
      padding-left: 33px !important;
    }

    .p-l-lg-34 {
      padding-left: 34px !important;
    }

    .p-l-lg-35 {
      padding-left: 35px !important;
    }

    .p-l-lg-36 {
      padding-left: 36px !important;
    }

    .p-l-lg-37 {
      padding-left: 37px !important;
    }

    .p-l-lg-38 {
      padding-left: 38px !important;
    }

    .p-l-lg-39 {
      padding-left: 39px !important;
    }

    .p-l-lg-40 {
      padding-left: 40px !important;
    }

    .p-l-lg-41 {
      padding-left: 41px !important;
    }

    .p-l-lg-42 {
      padding-left: 42px !important;
    }

    .p-l-lg-43 {
      padding-left: 43px !important;
    }

    .p-l-lg-44 {
      padding-left: 44px !important;
    }

    .p-l-lg-45 {
      padding-left: 45px !important;
    }

    .p-l-lg-46 {
      padding-left: 46px !important;
    }

    .p-l-lg-47 {
      padding-left: 47px !important;
    }

    .p-l-lg-48 {
      padding-left: 48px !important;
    }

    .p-l-lg-49 {
      padding-left: 49px !important;
    }

    .p-l-lg-50 {
      padding-left: 50px !important;
    }
  }

  .p-r-0 {
    padding-right: 0px !important;
  }

  .p-r-1 {
    padding-right: 1px !important;
  }

  .p-r-2 {
    padding-right: 2px !important;
  }

  .p-r-3 {
    padding-right: 3px !important;
  }

  .p-r-4 {
    padding-right: 4px !important;
  }

  .p-r-5 {
    padding-right: 5px !important;
  }

  .p-r-6 {
    padding-right: 6px !important;
  }

  .p-r-7 {
    padding-right: 7px !important;
  }

  .p-r-8 {
    padding-right: 8px !important;
  }

  .p-r-9 {
    padding-right: 9px !important;
  }

  .p-r-10 {
    padding-right: 10px !important;
  }

  .p-r-11 {
    padding-right: 11px !important;
  }

  .p-r-12 {
    padding-right: 12px !important;
  }

  .p-r-13 {
    padding-right: 13px !important;
  }

  .p-r-14 {
    padding-right: 14px !important;
  }

  .p-r-15 {
    padding-right: 15px !important;
  }

  .p-r-16 {
    padding-right: 16px !important;
  }

  .p-r-17 {
    padding-right: 17px !important;
  }

  .p-r-18 {
    padding-right: 18px !important;
  }

  .p-r-19 {
    padding-right: 19px !important;
  }

  .p-r-20 {
    padding-right: 20px !important;
  }

  .p-r-21 {
    padding-right: 21px !important;
  }

  .p-r-22 {
    padding-right: 22px !important;
  }

  .p-r-23 {
    padding-right: 23px !important;
  }

  .p-r-24 {
    padding-right: 24px !important;
  }

  .p-r-25 {
    padding-right: 25px !important;
  }

  .p-r-26 {
    padding-right: 26px !important;
  }

  .p-r-27 {
    padding-right: 27px !important;
  }

  .p-r-28 {
    padding-right: 28px !important;
  }

  .p-r-29 {
    padding-right: 29px !important;
  }

  .p-r-30 {
    padding-right: 30px !important;
  }

  .p-r-31 {
    padding-right: 31px !important;
  }

  .p-r-32 {
    padding-right: 32px !important;
  }

  .p-r-33 {
    padding-right: 33px !important;
  }

  .p-r-34 {
    padding-right: 34px !important;
  }

  .p-r-35 {
    padding-right: 35px !important;
  }

  .p-r-36 {
    padding-right: 36px !important;
  }

  .p-r-37 {
    padding-right: 37px !important;
  }

  .p-r-38 {
    padding-right: 38px !important;
  }

  .p-r-39 {
    padding-right: 39px !important;
  }

  .p-r-40 {
    padding-right: 40px !important;
  }

  .p-r-41 {
    padding-right: 41px !important;
  }

  .p-r-42 {
    padding-right: 42px !important;
  }

  .p-r-43 {
    padding-right: 43px !important;
  }

  .p-r-44 {
    padding-right: 44px !important;
  }

  .p-r-45 {
    padding-right: 45px !important;
  }

  .p-r-46 {
    padding-right: 46px !important;
  }

  .p-r-47 {
    padding-right: 47px !important;
  }

  .p-r-48 {
    padding-right: 48px !important;
  }

  .p-r-49 {
    padding-right: 49px !important;
  }

  .p-r-50 {
    padding-right: 50px !important;
  }

  @media screen and (min-width: 768px) {
    .p-r-sm-0 {
      padding-right: 0px !important;
    }

    .p-r-sm-1 {
      padding-right: 1px !important;
    }

    .p-r-sm-2 {
      padding-right: 2px !important;
    }

    .p-r-sm-3 {
      padding-right: 3px !important;
    }

    .p-r-sm-4 {
      padding-right: 4px !important;
    }

    .p-r-sm-5 {
      padding-right: 5px !important;
    }

    .p-r-sm-6 {
      padding-right: 6px !important;
    }

    .p-r-sm-7 {
      padding-right: 7px !important;
    }

    .p-r-sm-8 {
      padding-right: 8px !important;
    }

    .p-r-sm-9 {
      padding-right: 9px !important;
    }

    .p-r-sm-10 {
      padding-right: 10px !important;
    }

    .p-r-sm-11 {
      padding-right: 11px !important;
    }

    .p-r-sm-12 {
      padding-right: 12px !important;
    }

    .p-r-sm-13 {
      padding-right: 13px !important;
    }

    .p-r-sm-14 {
      padding-right: 14px !important;
    }

    .p-r-sm-15 {
      padding-right: 15px !important;
    }

    .p-r-sm-16 {
      padding-right: 16px !important;
    }

    .p-r-sm-17 {
      padding-right: 17px !important;
    }

    .p-r-sm-18 {
      padding-right: 18px !important;
    }

    .p-r-sm-19 {
      padding-right: 19px !important;
    }

    .p-r-sm-20 {
      padding-right: 20px !important;
    }

    .p-r-sm-21 {
      padding-right: 21px !important;
    }

    .p-r-sm-22 {
      padding-right: 22px !important;
    }

    .p-r-sm-23 {
      padding-right: 23px !important;
    }

    .p-r-sm-24 {
      padding-right: 24px !important;
    }

    .p-r-sm-25 {
      padding-right: 25px !important;
    }

    .p-r-sm-26 {
      padding-right: 26px !important;
    }

    .p-r-sm-27 {
      padding-right: 27px !important;
    }

    .p-r-sm-28 {
      padding-right: 28px !important;
    }

    .p-r-sm-29 {
      padding-right: 29px !important;
    }

    .p-r-sm-30 {
      padding-right: 30px !important;
    }

    .p-r-sm-31 {
      padding-right: 31px !important;
    }

    .p-r-sm-32 {
      padding-right: 32px !important;
    }

    .p-r-sm-33 {
      padding-right: 33px !important;
    }

    .p-r-sm-34 {
      padding-right: 34px !important;
    }

    .p-r-sm-35 {
      padding-right: 35px !important;
    }

    .p-r-sm-36 {
      padding-right: 36px !important;
    }

    .p-r-sm-37 {
      padding-right: 37px !important;
    }

    .p-r-sm-38 {
      padding-right: 38px !important;
    }

    .p-r-sm-39 {
      padding-right: 39px !important;
    }

    .p-r-sm-40 {
      padding-right: 40px !important;
    }

    .p-r-sm-41 {
      padding-right: 41px !important;
    }

    .p-r-sm-42 {
      padding-right: 42px !important;
    }

    .p-r-sm-43 {
      padding-right: 43px !important;
    }

    .p-r-sm-44 {
      padding-right: 44px !important;
    }

    .p-r-sm-45 {
      padding-right: 45px !important;
    }

    .p-r-sm-46 {
      padding-right: 46px !important;
    }

    .p-r-sm-47 {
      padding-right: 47px !important;
    }

    .p-r-sm-48 {
      padding-right: 48px !important;
    }

    .p-r-sm-49 {
      padding-right: 49px !important;
    }

    .p-r-sm-50 {
      padding-right: 50px !important;
    }
  }

  @media screen and (min-width: 992px) {
    .p-r-md-0 {
      padding-right: 0px !important;
    }

    .p-r-md-1 {
      padding-right: 1px !important;
    }

    .p-r-md-2 {
      padding-right: 2px !important;
    }

    .p-r-md-3 {
      padding-right: 3px !important;
    }

    .p-r-md-4 {
      padding-right: 4px !important;
    }

    .p-r-md-5 {
      padding-right: 5px !important;
    }

    .p-r-md-6 {
      padding-right: 6px !important;
    }

    .p-r-md-7 {
      padding-right: 7px !important;
    }

    .p-r-md-8 {
      padding-right: 8px !important;
    }

    .p-r-md-9 {
      padding-right: 9px !important;
    }

    .p-r-md-10 {
      padding-right: 10px !important;
    }

    .p-r-md-11 {
      padding-right: 11px !important;
    }

    .p-r-md-12 {
      padding-right: 12px !important;
    }

    .p-r-md-13 {
      padding-right: 13px !important;
    }

    .p-r-md-14 {
      padding-right: 14px !important;
    }

    .p-r-md-15 {
      padding-right: 15px !important;
    }

    .p-r-md-16 {
      padding-right: 16px !important;
    }

    .p-r-md-17 {
      padding-right: 17px !important;
    }

    .p-r-md-18 {
      padding-right: 18px !important;
    }

    .p-r-md-19 {
      padding-right: 19px !important;
    }

    .p-r-md-20 {
      padding-right: 20px !important;
    }

    .p-r-md-21 {
      padding-right: 21px !important;
    }

    .p-r-md-22 {
      padding-right: 22px !important;
    }

    .p-r-md-23 {
      padding-right: 23px !important;
    }

    .p-r-md-24 {
      padding-right: 24px !important;
    }

    .p-r-md-25 {
      padding-right: 25px !important;
    }

    .p-r-md-26 {
      padding-right: 26px !important;
    }

    .p-r-md-27 {
      padding-right: 27px !important;
    }

    .p-r-md-28 {
      padding-right: 28px !important;
    }

    .p-r-md-29 {
      padding-right: 29px !important;
    }

    .p-r-md-30 {
      padding-right: 30px !important;
    }

    .p-r-md-31 {
      padding-right: 31px !important;
    }

    .p-r-md-32 {
      padding-right: 32px !important;
    }

    .p-r-md-33 {
      padding-right: 33px !important;
    }

    .p-r-md-34 {
      padding-right: 34px !important;
    }

    .p-r-md-35 {
      padding-right: 35px !important;
    }

    .p-r-md-36 {
      padding-right: 36px !important;
    }

    .p-r-md-37 {
      padding-right: 37px !important;
    }

    .p-r-md-38 {
      padding-right: 38px !important;
    }

    .p-r-md-39 {
      padding-right: 39px !important;
    }

    .p-r-md-40 {
      padding-right: 40px !important;
    }

    .p-r-md-41 {
      padding-right: 41px !important;
    }

    .p-r-md-42 {
      padding-right: 42px !important;
    }

    .p-r-md-43 {
      padding-right: 43px !important;
    }

    .p-r-md-44 {
      padding-right: 44px !important;
    }

    .p-r-md-45 {
      padding-right: 45px !important;
    }

    .p-r-md-46 {
      padding-right: 46px !important;
    }

    .p-r-md-47 {
      padding-right: 47px !important;
    }

    .p-r-md-48 {
      padding-right: 48px !important;
    }

    .p-r-md-49 {
      padding-right: 49px !important;
    }

    .p-r-md-50 {
      padding-right: 50px !important;
    }
  }

  @media screen and (min-width: 1200px) {
    .p-r-lg-0 {
      padding-right: 0px !important;
    }

    .p-r-lg-1 {
      padding-right: 1px !important;
    }

    .p-r-lg-2 {
      padding-right: 2px !important;
    }

    .p-r-lg-3 {
      padding-right: 3px !important;
    }

    .p-r-lg-4 {
      padding-right: 4px !important;
    }

    .p-r-lg-5 {
      padding-right: 5px !important;
    }

    .p-r-lg-6 {
      padding-right: 6px !important;
    }

    .p-r-lg-7 {
      padding-right: 7px !important;
    }

    .p-r-lg-8 {
      padding-right: 8px !important;
    }

    .p-r-lg-9 {
      padding-right: 9px !important;
    }

    .p-r-lg-10 {
      padding-right: 10px !important;
    }

    .p-r-lg-11 {
      padding-right: 11px !important;
    }

    .p-r-lg-12 {
      padding-right: 12px !important;
    }

    .p-r-lg-13 {
      padding-right: 13px !important;
    }

    .p-r-lg-14 {
      padding-right: 14px !important;
    }

    .p-r-lg-15 {
      padding-right: 15px !important;
    }

    .p-r-lg-16 {
      padding-right: 16px !important;
    }

    .p-r-lg-17 {
      padding-right: 17px !important;
    }

    .p-r-lg-18 {
      padding-right: 18px !important;
    }

    .p-r-lg-19 {
      padding-right: 19px !important;
    }

    .p-r-lg-20 {
      padding-right: 20px !important;
    }

    .p-r-lg-21 {
      padding-right: 21px !important;
    }

    .p-r-lg-22 {
      padding-right: 22px !important;
    }

    .p-r-lg-23 {
      padding-right: 23px !important;
    }

    .p-r-lg-24 {
      padding-right: 24px !important;
    }

    .p-r-lg-25 {
      padding-right: 25px !important;
    }

    .p-r-lg-26 {
      padding-right: 26px !important;
    }

    .p-r-lg-27 {
      padding-right: 27px !important;
    }

    .p-r-lg-28 {
      padding-right: 28px !important;
    }

    .p-r-lg-29 {
      padding-right: 29px !important;
    }

    .p-r-lg-30 {
      padding-right: 30px !important;
    }

    .p-r-lg-31 {
      padding-right: 31px !important;
    }

    .p-r-lg-32 {
      padding-right: 32px !important;
    }

    .p-r-lg-33 {
      padding-right: 33px !important;
    }

    .p-r-lg-34 {
      padding-right: 34px !important;
    }

    .p-r-lg-35 {
      padding-right: 35px !important;
    }

    .p-r-lg-36 {
      padding-right: 36px !important;
    }

    .p-r-lg-37 {
      padding-right: 37px !important;
    }

    .p-r-lg-38 {
      padding-right: 38px !important;
    }

    .p-r-lg-39 {
      padding-right: 39px !important;
    }

    .p-r-lg-40 {
      padding-right: 40px !important;
    }

    .p-r-lg-41 {
      padding-right: 41px !important;
    }

    .p-r-lg-42 {
      padding-right: 42px !important;
    }

    .p-r-lg-43 {
      padding-right: 43px !important;
    }

    .p-r-lg-44 {
      padding-right: 44px !important;
    }

    .p-r-lg-45 {
      padding-right: 45px !important;
    }

    .p-r-lg-46 {
      padding-right: 46px !important;
    }

    .p-r-lg-47 {
      padding-right: 47px !important;
    }

    .p-r-lg-48 {
      padding-right: 48px !important;
    }

    .p-r-lg-49 {
      padding-right: 49px !important;
    }

    .p-r-lg-50 {
      padding-right: 50px !important;
    }
  }

  .p-t-0 {
    padding-top: 0px !important;
  }

  .p-t-1 {
    padding-top: 1px !important;
  }

  .p-t-2 {
    padding-top: 2px !important;
  }

  .p-t-3 {
    padding-top: 3px !important;
  }

  .p-t-4 {
    padding-top: 4px !important;
  }

  .p-t-5 {
    padding-top: 5px !important;
  }

  .p-t-6 {
    padding-top: 6px !important;
  }

  .p-t-7 {
    padding-top: 7px !important;
  }

  .p-t-8 {
    padding-top: 8px !important;
  }

  .p-t-9 {
    padding-top: 9px !important;
  }

  .p-t-10 {
    padding-top: 10px !important;
  }

  .p-t-11 {
    padding-top: 11px !important;
  }

  .p-t-12 {
    padding-top: 12px !important;
  }

  .p-t-13 {
    padding-top: 13px !important;
  }

  .p-t-14 {
    padding-top: 14px !important;
  }

  .p-t-15 {
    padding-top: 15px !important;
  }

  .p-t-16 {
    padding-top: 16px !important;
  }

  .p-t-17 {
    padding-top: 17px !important;
  }

  .p-t-18 {
    padding-top: 18px !important;
  }

  .p-t-19 {
    padding-top: 19px !important;
  }

  .p-t-20 {
    padding-top: 20px !important;
  }

  .p-t-21 {
    padding-top: 21px !important;
  }

  .p-t-22 {
    padding-top: 22px !important;
  }

  .p-t-23 {
    padding-top: 23px !important;
  }

  .p-t-24 {
    padding-top: 24px !important;
  }

  .p-t-25 {
    padding-top: 25px !important;
  }

  .p-t-26 {
    padding-top: 26px !important;
  }

  .p-t-27 {
    padding-top: 27px !important;
  }

  .p-t-28 {
    padding-top: 28px !important;
  }

  .p-t-29 {
    padding-top: 29px !important;
  }

  .p-t-30 {
    padding-top: 30px !important;
  }

  .p-t-31 {
    padding-top: 31px !important;
  }

  .p-t-32 {
    padding-top: 32px !important;
  }

  .p-t-33 {
    padding-top: 33px !important;
  }

  .p-t-34 {
    padding-top: 34px !important;
  }

  .p-t-35 {
    padding-top: 35px !important;
  }

  .p-t-36 {
    padding-top: 36px !important;
  }

  .p-t-37 {
    padding-top: 37px !important;
  }

  .p-t-38 {
    padding-top: 38px !important;
  }

  .p-t-39 {
    padding-top: 39px !important;
  }

  .p-t-40 {
    padding-top: 40px !important;
  }

  .p-t-41 {
    padding-top: 41px !important;
  }

  .p-t-42 {
    padding-top: 42px !important;
  }

  .p-t-43 {
    padding-top: 43px !important;
  }

  .p-t-44 {
    padding-top: 44px !important;
  }

  .p-t-45 {
    padding-top: 45px !important;
  }

  .p-t-46 {
    padding-top: 46px !important;
  }

  .p-t-47 {
    padding-top: 47px !important;
  }

  .p-t-48 {
    padding-top: 48px !important;
  }

  .p-t-49 {
    padding-top: 49px !important;
  }

  .p-t-50 {
    padding-top: 50px !important;
  }

  @media screen and (min-width: 768px) {
    .p-t-sm-0 {
      padding-top: 0px !important;
    }

    .p-t-sm-1 {
      padding-top: 1px !important;
    }

    .p-t-sm-2 {
      padding-top: 2px !important;
    }

    .p-t-sm-3 {
      padding-top: 3px !important;
    }

    .p-t-sm-4 {
      padding-top: 4px !important;
    }

    .p-t-sm-5 {
      padding-top: 5px !important;
    }

    .p-t-sm-6 {
      padding-top: 6px !important;
    }

    .p-t-sm-7 {
      padding-top: 7px !important;
    }

    .p-t-sm-8 {
      padding-top: 8px !important;
    }

    .p-t-sm-9 {
      padding-top: 9px !important;
    }

    .p-t-sm-10 {
      padding-top: 10px !important;
    }

    .p-t-sm-11 {
      padding-top: 11px !important;
    }

    .p-t-sm-12 {
      padding-top: 12px !important;
    }

    .p-t-sm-13 {
      padding-top: 13px !important;
    }

    .p-t-sm-14 {
      padding-top: 14px !important;
    }

    .p-t-sm-15 {
      padding-top: 15px !important;
    }

    .p-t-sm-16 {
      padding-top: 16px !important;
    }

    .p-t-sm-17 {
      padding-top: 17px !important;
    }

    .p-t-sm-18 {
      padding-top: 18px !important;
    }

    .p-t-sm-19 {
      padding-top: 19px !important;
    }

    .p-t-sm-20 {
      padding-top: 20px !important;
    }

    .p-t-sm-21 {
      padding-top: 21px !important;
    }

    .p-t-sm-22 {
      padding-top: 22px !important;
    }

    .p-t-sm-23 {
      padding-top: 23px !important;
    }

    .p-t-sm-24 {
      padding-top: 24px !important;
    }

    .p-t-sm-25 {
      padding-top: 25px !important;
    }

    .p-t-sm-26 {
      padding-top: 26px !important;
    }

    .p-t-sm-27 {
      padding-top: 27px !important;
    }

    .p-t-sm-28 {
      padding-top: 28px !important;
    }

    .p-t-sm-29 {
      padding-top: 29px !important;
    }

    .p-t-sm-30 {
      padding-top: 30px !important;
    }

    .p-t-sm-31 {
      padding-top: 31px !important;
    }

    .p-t-sm-32 {
      padding-top: 32px !important;
    }

    .p-t-sm-33 {
      padding-top: 33px !important;
    }

    .p-t-sm-34 {
      padding-top: 34px !important;
    }

    .p-t-sm-35 {
      padding-top: 35px !important;
    }

    .p-t-sm-36 {
      padding-top: 36px !important;
    }

    .p-t-sm-37 {
      padding-top: 37px !important;
    }

    .p-t-sm-38 {
      padding-top: 38px !important;
    }

    .p-t-sm-39 {
      padding-top: 39px !important;
    }

    .p-t-sm-40 {
      padding-top: 40px !important;
    }

    .p-t-sm-41 {
      padding-top: 41px !important;
    }

    .p-t-sm-42 {
      padding-top: 42px !important;
    }

    .p-t-sm-43 {
      padding-top: 43px !important;
    }

    .p-t-sm-44 {
      padding-top: 44px !important;
    }

    .p-t-sm-45 {
      padding-top: 45px !important;
    }

    .p-t-sm-46 {
      padding-top: 46px !important;
    }

    .p-t-sm-47 {
      padding-top: 47px !important;
    }

    .p-t-sm-48 {
      padding-top: 48px !important;
    }

    .p-t-sm-49 {
      padding-top: 49px !important;
    }

    .p-t-sm-50 {
      padding-top: 50px !important;
    }
  }

  @media screen and (min-width: 992px) {
    .p-t-md-0 {
      padding-top: 0px !important;
    }

    .p-t-md-1 {
      padding-top: 1px !important;
    }

    .p-t-md-2 {
      padding-top: 2px !important;
    }

    .p-t-md-3 {
      padding-top: 3px !important;
    }

    .p-t-md-4 {
      padding-top: 4px !important;
    }

    .p-t-md-5 {
      padding-top: 5px !important;
    }

    .p-t-md-6 {
      padding-top: 6px !important;
    }

    .p-t-md-7 {
      padding-top: 7px !important;
    }

    .p-t-md-8 {
      padding-top: 8px !important;
    }

    .p-t-md-9 {
      padding-top: 9px !important;
    }

    .p-t-md-10 {
      padding-top: 10px !important;
    }

    .p-t-md-11 {
      padding-top: 11px !important;
    }

    .p-t-md-12 {
      padding-top: 12px !important;
    }

    .p-t-md-13 {
      padding-top: 13px !important;
    }

    .p-t-md-14 {
      padding-top: 14px !important;
    }

    .p-t-md-15 {
      padding-top: 15px !important;
    }

    .p-t-md-16 {
      padding-top: 16px !important;
    }

    .p-t-md-17 {
      padding-top: 17px !important;
    }

    .p-t-md-18 {
      padding-top: 18px !important;
    }

    .p-t-md-19 {
      padding-top: 19px !important;
    }

    .p-t-md-20 {
      padding-top: 20px !important;
    }

    .p-t-md-21 {
      padding-top: 21px !important;
    }

    .p-t-md-22 {
      padding-top: 22px !important;
    }

    .p-t-md-23 {
      padding-top: 23px !important;
    }

    .p-t-md-24 {
      padding-top: 24px !important;
    }

    .p-t-md-25 {
      padding-top: 25px !important;
    }

    .p-t-md-26 {
      padding-top: 26px !important;
    }

    .p-t-md-27 {
      padding-top: 27px !important;
    }

    .p-t-md-28 {
      padding-top: 28px !important;
    }

    .p-t-md-29 {
      padding-top: 29px !important;
    }

    .p-t-md-30 {
      padding-top: 30px !important;
    }

    .p-t-md-31 {
      padding-top: 31px !important;
    }

    .p-t-md-32 {
      padding-top: 32px !important;
    }

    .p-t-md-33 {
      padding-top: 33px !important;
    }

    .p-t-md-34 {
      padding-top: 34px !important;
    }

    .p-t-md-35 {
      padding-top: 35px !important;
    }

    .p-t-md-36 {
      padding-top: 36px !important;
    }

    .p-t-md-37 {
      padding-top: 37px !important;
    }

    .p-t-md-38 {
      padding-top: 38px !important;
    }

    .p-t-md-39 {
      padding-top: 39px !important;
    }

    .p-t-md-40 {
      padding-top: 40px !important;
    }

    .p-t-md-41 {
      padding-top: 41px !important;
    }

    .p-t-md-42 {
      padding-top: 42px !important;
    }

    .p-t-md-43 {
      padding-top: 43px !important;
    }

    .p-t-md-44 {
      padding-top: 44px !important;
    }

    .p-t-md-45 {
      padding-top: 45px !important;
    }

    .p-t-md-46 {
      padding-top: 46px !important;
    }

    .p-t-md-47 {
      padding-top: 47px !important;
    }

    .p-t-md-48 {
      padding-top: 48px !important;
    }

    .p-t-md-49 {
      padding-top: 49px !important;
    }

    .p-t-md-50 {
      padding-top: 50px !important;
    }
  }

  @media screen and (min-width: 1200px) {
    .p-t-lg-0 {
      padding-top: 0px !important;
    }

    .p-t-lg-1 {
      padding-top: 1px !important;
    }

    .p-t-lg-2 {
      padding-top: 2px !important;
    }

    .p-t-lg-3 {
      padding-top: 3px !important;
    }

    .p-t-lg-4 {
      padding-top: 4px !important;
    }

    .p-t-lg-5 {
      padding-top: 5px !important;
    }

    .p-t-lg-6 {
      padding-top: 6px !important;
    }

    .p-t-lg-7 {
      padding-top: 7px !important;
    }

    .p-t-lg-8 {
      padding-top: 8px !important;
    }

    .p-t-lg-9 {
      padding-top: 9px !important;
    }

    .p-t-lg-10 {
      padding-top: 10px !important;
    }

    .p-t-lg-11 {
      padding-top: 11px !important;
    }

    .p-t-lg-12 {
      padding-top: 12px !important;
    }

    .p-t-lg-13 {
      padding-top: 13px !important;
    }

    .p-t-lg-14 {
      padding-top: 14px !important;
    }

    .p-t-lg-15 {
      padding-top: 15px !important;
    }

    .p-t-lg-16 {
      padding-top: 16px !important;
    }

    .p-t-lg-17 {
      padding-top: 17px !important;
    }

    .p-t-lg-18 {
      padding-top: 18px !important;
    }

    .p-t-lg-19 {
      padding-top: 19px !important;
    }

    .p-t-lg-20 {
      padding-top: 20px !important;
    }

    .p-t-lg-21 {
      padding-top: 21px !important;
    }

    .p-t-lg-22 {
      padding-top: 22px !important;
    }

    .p-t-lg-23 {
      padding-top: 23px !important;
    }

    .p-t-lg-24 {
      padding-top: 24px !important;
    }

    .p-t-lg-25 {
      padding-top: 25px !important;
    }

    .p-t-lg-26 {
      padding-top: 26px !important;
    }

    .p-t-lg-27 {
      padding-top: 27px !important;
    }

    .p-t-lg-28 {
      padding-top: 28px !important;
    }

    .p-t-lg-29 {
      padding-top: 29px !important;
    }

    .p-t-lg-30 {
      padding-top: 30px !important;
    }

    .p-t-lg-31 {
      padding-top: 31px !important;
    }

    .p-t-lg-32 {
      padding-top: 32px !important;
    }

    .p-t-lg-33 {
      padding-top: 33px !important;
    }

    .p-t-lg-34 {
      padding-top: 34px !important;
    }

    .p-t-lg-35 {
      padding-top: 35px !important;
    }

    .p-t-lg-36 {
      padding-top: 36px !important;
    }

    .p-t-lg-37 {
      padding-top: 37px !important;
    }

    .p-t-lg-38 {
      padding-top: 38px !important;
    }

    .p-t-lg-39 {
      padding-top: 39px !important;
    }

    .p-t-lg-40 {
      padding-top: 40px !important;
    }

    .p-t-lg-41 {
      padding-top: 41px !important;
    }

    .p-t-lg-42 {
      padding-top: 42px !important;
    }

    .p-t-lg-43 {
      padding-top: 43px !important;
    }

    .p-t-lg-44 {
      padding-top: 44px !important;
    }

    .p-t-lg-45 {
      padding-top: 45px !important;
    }

    .p-t-lg-46 {
      padding-top: 46px !important;
    }

    .p-t-lg-47 {
      padding-top: 47px !important;
    }

    .p-t-lg-48 {
      padding-top: 48px !important;
    }

    .p-t-lg-49 {
      padding-top: 49px !important;
    }

    .p-t-lg-50 {
      padding-top: 50px !important;
    }
  }

  .p-b-0 {
    padding-bottom: 0px !important;
  }

  .p-b-1 {
    padding-bottom: 1px !important;
  }

  .p-b-2 {
    padding-bottom: 2px !important;
  }

  .p-b-3 {
    padding-bottom: 3px !important;
  }

  .p-b-4 {
    padding-bottom: 4px !important;
  }

  .p-b-5 {
    padding-bottom: 5px !important;
  }

  .p-b-6 {
    padding-bottom: 6px !important;
  }

  .p-b-7 {
    padding-bottom: 7px !important;
  }

  .p-b-8 {
    padding-bottom: 8px !important;
  }

  .p-b-9 {
    padding-bottom: 9px !important;
  }

  .p-b-10 {
    padding-bottom: 10px !important;
  }

  .p-b-11 {
    padding-bottom: 11px !important;
  }

  .p-b-12 {
    padding-bottom: 12px !important;
  }

  .p-b-13 {
    padding-bottom: 13px !important;
  }

  .p-b-14 {
    padding-bottom: 14px !important;
  }

  .p-b-15 {
    padding-bottom: 15px !important;
  }

  .p-b-16 {
    padding-bottom: 16px !important;
  }

  .p-b-17 {
    padding-bottom: 17px !important;
  }

  .p-b-18 {
    padding-bottom: 18px !important;
  }

  .p-b-19 {
    padding-bottom: 19px !important;
  }

  .p-b-20 {
    padding-bottom: 20px !important;
  }

  .p-b-21 {
    padding-bottom: 21px !important;
  }

  .p-b-22 {
    padding-bottom: 22px !important;
  }

  .p-b-23 {
    padding-bottom: 23px !important;
  }

  .p-b-24 {
    padding-bottom: 24px !important;
  }

  .p-b-25 {
    padding-bottom: 25px !important;
  }

  .p-b-26 {
    padding-bottom: 26px !important;
  }

  .p-b-27 {
    padding-bottom: 27px !important;
  }

  .p-b-28 {
    padding-bottom: 28px !important;
  }

  .p-b-29 {
    padding-bottom: 29px !important;
  }

  .p-b-30 {
    padding-bottom: 30px !important;
  }

  .p-b-31 {
    padding-bottom: 31px !important;
  }

  .p-b-32 {
    padding-bottom: 32px !important;
  }

  .p-b-33 {
    padding-bottom: 33px !important;
  }

  .p-b-34 {
    padding-bottom: 34px !important;
  }

  .p-b-35 {
    padding-bottom: 35px !important;
  }

  .p-b-36 {
    padding-bottom: 36px !important;
  }

  .p-b-37 {
    padding-bottom: 37px !important;
  }

  .p-b-38 {
    padding-bottom: 38px !important;
  }

  .p-b-39 {
    padding-bottom: 39px !important;
  }

  .p-b-40 {
    padding-bottom: 40px !important;
  }

  .p-b-41 {
    padding-bottom: 41px !important;
  }

  .p-b-42 {
    padding-bottom: 42px !important;
  }

  .p-b-43 {
    padding-bottom: 43px !important;
  }

  .p-b-44 {
    padding-bottom: 44px !important;
  }

  .p-b-45 {
    padding-bottom: 45px !important;
  }

  .p-b-46 {
    padding-bottom: 46px !important;
  }

  .p-b-47 {
    padding-bottom: 47px !important;
  }

  .p-b-48 {
    padding-bottom: 48px !important;
  }

  .p-b-49 {
    padding-bottom: 49px !important;
  }

  .p-b-50 {
    padding-bottom: 50px !important;
  }

  @media screen and (min-width: 768px) {
    .p-b-sm-0 {
      padding-bottom: 0px !important;
    }

    .p-b-sm-1 {
      padding-bottom: 1px !important;
    }

    .p-b-sm-2 {
      padding-bottom: 2px !important;
    }

    .p-b-sm-3 {
      padding-bottom: 3px !important;
    }

    .p-b-sm-4 {
      padding-bottom: 4px !important;
    }

    .p-b-sm-5 {
      padding-bottom: 5px !important;
    }

    .p-b-sm-6 {
      padding-bottom: 6px !important;
    }

    .p-b-sm-7 {
      padding-bottom: 7px !important;
    }

    .p-b-sm-8 {
      padding-bottom: 8px !important;
    }

    .p-b-sm-9 {
      padding-bottom: 9px !important;
    }

    .p-b-sm-10 {
      padding-bottom: 10px !important;
    }

    .p-b-sm-11 {
      padding-bottom: 11px !important;
    }

    .p-b-sm-12 {
      padding-bottom: 12px !important;
    }

    .p-b-sm-13 {
      padding-bottom: 13px !important;
    }

    .p-b-sm-14 {
      padding-bottom: 14px !important;
    }

    .p-b-sm-15 {
      padding-bottom: 15px !important;
    }

    .p-b-sm-16 {
      padding-bottom: 16px !important;
    }

    .p-b-sm-17 {
      padding-bottom: 17px !important;
    }

    .p-b-sm-18 {
      padding-bottom: 18px !important;
    }

    .p-b-sm-19 {
      padding-bottom: 19px !important;
    }

    .p-b-sm-20 {
      padding-bottom: 20px !important;
    }

    .p-b-sm-21 {
      padding-bottom: 21px !important;
    }

    .p-b-sm-22 {
      padding-bottom: 22px !important;
    }

    .p-b-sm-23 {
      padding-bottom: 23px !important;
    }

    .p-b-sm-24 {
      padding-bottom: 24px !important;
    }

    .p-b-sm-25 {
      padding-bottom: 25px !important;
    }

    .p-b-sm-26 {
      padding-bottom: 26px !important;
    }

    .p-b-sm-27 {
      padding-bottom: 27px !important;
    }

    .p-b-sm-28 {
      padding-bottom: 28px !important;
    }

    .p-b-sm-29 {
      padding-bottom: 29px !important;
    }

    .p-b-sm-30 {
      padding-bottom: 30px !important;
    }

    .p-b-sm-31 {
      padding-bottom: 31px !important;
    }

    .p-b-sm-32 {
      padding-bottom: 32px !important;
    }

    .p-b-sm-33 {
      padding-bottom: 33px !important;
    }

    .p-b-sm-34 {
      padding-bottom: 34px !important;
    }

    .p-b-sm-35 {
      padding-bottom: 35px !important;
    }

    .p-b-sm-36 {
      padding-bottom: 36px !important;
    }

    .p-b-sm-37 {
      padding-bottom: 37px !important;
    }

    .p-b-sm-38 {
      padding-bottom: 38px !important;
    }

    .p-b-sm-39 {
      padding-bottom: 39px !important;
    }

    .p-b-sm-40 {
      padding-bottom: 40px !important;
    }

    .p-b-sm-41 {
      padding-bottom: 41px !important;
    }

    .p-b-sm-42 {
      padding-bottom: 42px !important;
    }

    .p-b-sm-43 {
      padding-bottom: 43px !important;
    }

    .p-b-sm-44 {
      padding-bottom: 44px !important;
    }

    .p-b-sm-45 {
      padding-bottom: 45px !important;
    }

    .p-b-sm-46 {
      padding-bottom: 46px !important;
    }

    .p-b-sm-47 {
      padding-bottom: 47px !important;
    }

    .p-b-sm-48 {
      padding-bottom: 48px !important;
    }

    .p-b-sm-49 {
      padding-bottom: 49px !important;
    }

    .p-b-sm-50 {
      padding-bottom: 50px !important;
    }
  }

  @media screen and (min-width: 992px) {
    .p-b-md-0 {
      padding-bottom: 0px !important;
    }

    .p-b-md-1 {
      padding-bottom: 1px !important;
    }

    .p-b-md-2 {
      padding-bottom: 2px !important;
    }

    .p-b-md-3 {
      padding-bottom: 3px !important;
    }

    .p-b-md-4 {
      padding-bottom: 4px !important;
    }

    .p-b-md-5 {
      padding-bottom: 5px !important;
    }

    .p-b-md-6 {
      padding-bottom: 6px !important;
    }

    .p-b-md-7 {
      padding-bottom: 7px !important;
    }

    .p-b-md-8 {
      padding-bottom: 8px !important;
    }

    .p-b-md-9 {
      padding-bottom: 9px !important;
    }

    .p-b-md-10 {
      padding-bottom: 10px !important;
    }

    .p-b-md-11 {
      padding-bottom: 11px !important;
    }

    .p-b-md-12 {
      padding-bottom: 12px !important;
    }

    .p-b-md-13 {
      padding-bottom: 13px !important;
    }

    .p-b-md-14 {
      padding-bottom: 14px !important;
    }

    .p-b-md-15 {
      padding-bottom: 15px !important;
    }

    .p-b-md-16 {
      padding-bottom: 16px !important;
    }

    .p-b-md-17 {
      padding-bottom: 17px !important;
    }

    .p-b-md-18 {
      padding-bottom: 18px !important;
    }

    .p-b-md-19 {
      padding-bottom: 19px !important;
    }

    .p-b-md-20 {
      padding-bottom: 20px !important;
    }

    .p-b-md-21 {
      padding-bottom: 21px !important;
    }

    .p-b-md-22 {
      padding-bottom: 22px !important;
    }

    .p-b-md-23 {
      padding-bottom: 23px !important;
    }

    .p-b-md-24 {
      padding-bottom: 24px !important;
    }

    .p-b-md-25 {
      padding-bottom: 25px !important;
    }

    .p-b-md-26 {
      padding-bottom: 26px !important;
    }

    .p-b-md-27 {
      padding-bottom: 27px !important;
    }

    .p-b-md-28 {
      padding-bottom: 28px !important;
    }

    .p-b-md-29 {
      padding-bottom: 29px !important;
    }

    .p-b-md-30 {
      padding-bottom: 30px !important;
    }

    .p-b-md-31 {
      padding-bottom: 31px !important;
    }

    .p-b-md-32 {
      padding-bottom: 32px !important;
    }

    .p-b-md-33 {
      padding-bottom: 33px !important;
    }

    .p-b-md-34 {
      padding-bottom: 34px !important;
    }

    .p-b-md-35 {
      padding-bottom: 35px !important;
    }

    .p-b-md-36 {
      padding-bottom: 36px !important;
    }

    .p-b-md-37 {
      padding-bottom: 37px !important;
    }

    .p-b-md-38 {
      padding-bottom: 38px !important;
    }

    .p-b-md-39 {
      padding-bottom: 39px !important;
    }

    .p-b-md-40 {
      padding-bottom: 40px !important;
    }

    .p-b-md-41 {
      padding-bottom: 41px !important;
    }

    .p-b-md-42 {
      padding-bottom: 42px !important;
    }

    .p-b-md-43 {
      padding-bottom: 43px !important;
    }

    .p-b-md-44 {
      padding-bottom: 44px !important;
    }

    .p-b-md-45 {
      padding-bottom: 45px !important;
    }

    .p-b-md-46 {
      padding-bottom: 46px !important;
    }

    .p-b-md-47 {
      padding-bottom: 47px !important;
    }

    .p-b-md-48 {
      padding-bottom: 48px !important;
    }

    .p-b-md-49 {
      padding-bottom: 49px !important;
    }

    .p-b-md-50 {
      padding-bottom: 50px !important;
    }
  }

  @media screen and (min-width: 1200px) {
    .p-b-lg-0 {
      padding-bottom: 0px !important;
    }

    .p-b-lg-1 {
      padding-bottom: 1px !important;
    }

    .p-b-lg-2 {
      padding-bottom: 2px !important;
    }

    .p-b-lg-3 {
      padding-bottom: 3px !important;
    }

    .p-b-lg-4 {
      padding-bottom: 4px !important;
    }

    .p-b-lg-5 {
      padding-bottom: 5px !important;
    }

    .p-b-lg-6 {
      padding-bottom: 6px !important;
    }

    .p-b-lg-7 {
      padding-bottom: 7px !important;
    }

    .p-b-lg-8 {
      padding-bottom: 8px !important;
    }

    .p-b-lg-9 {
      padding-bottom: 9px !important;
    }

    .p-b-lg-10 {
      padding-bottom: 10px !important;
    }

    .p-b-lg-11 {
      padding-bottom: 11px !important;
    }

    .p-b-lg-12 {
      padding-bottom: 12px !important;
    }

    .p-b-lg-13 {
      padding-bottom: 13px !important;
    }

    .p-b-lg-14 {
      padding-bottom: 14px !important;
    }

    .p-b-lg-15 {
      padding-bottom: 15px !important;
    }

    .p-b-lg-16 {
      padding-bottom: 16px !important;
    }

    .p-b-lg-17 {
      padding-bottom: 17px !important;
    }

    .p-b-lg-18 {
      padding-bottom: 18px !important;
    }

    .p-b-lg-19 {
      padding-bottom: 19px !important;
    }

    .p-b-lg-20 {
      padding-bottom: 20px !important;
    }

    .p-b-lg-21 {
      padding-bottom: 21px !important;
    }

    .p-b-lg-22 {
      padding-bottom: 22px !important;
    }

    .p-b-lg-23 {
      padding-bottom: 23px !important;
    }

    .p-b-lg-24 {
      padding-bottom: 24px !important;
    }

    .p-b-lg-25 {
      padding-bottom: 25px !important;
    }

    .p-b-lg-26 {
      padding-bottom: 26px !important;
    }

    .p-b-lg-27 {
      padding-bottom: 27px !important;
    }

    .p-b-lg-28 {
      padding-bottom: 28px !important;
    }

    .p-b-lg-29 {
      padding-bottom: 29px !important;
    }

    .p-b-lg-30 {
      padding-bottom: 30px !important;
    }

    .p-b-lg-31 {
      padding-bottom: 31px !important;
    }

    .p-b-lg-32 {
      padding-bottom: 32px !important;
    }

    .p-b-lg-33 {
      padding-bottom: 33px !important;
    }

    .p-b-lg-34 {
      padding-bottom: 34px !important;
    }

    .p-b-lg-35 {
      padding-bottom: 35px !important;
    }

    .p-b-lg-36 {
      padding-bottom: 36px !important;
    }

    .p-b-lg-37 {
      padding-bottom: 37px !important;
    }

    .p-b-lg-38 {
      padding-bottom: 38px !important;
    }

    .p-b-lg-39 {
      padding-bottom: 39px !important;
    }

    .p-b-lg-40 {
      padding-bottom: 40px !important;
    }

    .p-b-lg-41 {
      padding-bottom: 41px !important;
    }

    .p-b-lg-42 {
      padding-bottom: 42px !important;
    }

    .p-b-lg-43 {
      padding-bottom: 43px !important;
    }

    .p-b-lg-44 {
      padding-bottom: 44px !important;
    }

    .p-b-lg-45 {
      padding-bottom: 45px !important;
    }

    .p-b-lg-46 {
      padding-bottom: 46px !important;
    }

    .p-b-lg-47 {
      padding-bottom: 47px !important;
    }

    .p-b-lg-48 {
      padding-bottom: 48px !important;
    }

    .p-b-lg-49 {
      padding-bottom: 49px !important;
    }

    .p-b-lg-50 {
      padding-bottom: 50px !important;
    }
  }

  .m-l-0 {
    margin-left: 0px !important;
  }

  .m-l-1 {
    margin-left: 1px !important;
  }

  .m-l-2 {
    margin-left: 2px !important;
  }

  .m-l-3 {
    margin-left: 3px !important;
  }

  .m-l-4 {
    margin-left: 4px !important;
  }

  .m-l-5 {
    margin-left: 5px !important;
  }

  .m-l-6 {
    margin-left: 6px !important;
  }

  .m-l-7 {
    margin-left: 7px !important;
  }

  .m-l-8 {
    margin-left: 8px !important;
  }

  .m-l-9 {
    margin-left: 9px !important;
  }

  .m-l-10 {
    margin-left: 10px !important;
  }

  .m-l-11 {
    margin-left: 11px !important;
  }

  .m-l-12 {
    margin-left: 12px !important;
  }

  .m-l-13 {
    margin-left: 13px !important;
  }

  .m-l-14 {
    margin-left: 14px !important;
  }

  .m-l-15 {
    margin-left: 15px !important;
  }

  .m-l-16 {
    margin-left: 16px !important;
  }

  .m-l-17 {
    margin-left: 17px !important;
  }

  .m-l-18 {
    margin-left: 18px !important;
  }

  .m-l-19 {
    margin-left: 19px !important;
  }

  .m-l-20 {
    margin-left: 20px !important;
  }

  .m-l-21 {
    margin-left: 21px !important;
  }

  .m-l-22 {
    margin-left: 22px !important;
  }

  .m-l-23 {
    margin-left: 23px !important;
  }

  .m-l-24 {
    margin-left: 24px !important;
  }

  .m-l-25 {
    margin-left: 25px !important;
  }

  .m-l-26 {
    margin-left: 26px !important;
  }

  .m-l-27 {
    margin-left: 27px !important;
  }

  .m-l-28 {
    margin-left: 28px !important;
  }

  .m-l-29 {
    margin-left: 29px !important;
  }

  .m-l-30 {
    margin-left: 30px !important;
  }

  .m-l-31 {
    margin-left: 31px !important;
  }

  .m-l-32 {
    margin-left: 32px !important;
  }

  .m-l-33 {
    margin-left: 33px !important;
  }

  .m-l-34 {
    margin-left: 34px !important;
  }

  .m-l-35 {
    margin-left: 35px !important;
  }

  .m-l-36 {
    margin-left: 36px !important;
  }

  .m-l-37 {
    margin-left: 37px !important;
  }

  .m-l-38 {
    margin-left: 38px !important;
  }

  .m-l-39 {
    margin-left: 39px !important;
  }

  .m-l-40 {
    margin-left: 40px !important;
  }

  .m-l-41 {
    margin-left: 41px !important;
  }

  .m-l-42 {
    margin-left: 42px !important;
  }

  .m-l-43 {
    margin-left: 43px !important;
  }

  .m-l-44 {
    margin-left: 44px !important;
  }

  .m-l-45 {
    margin-left: 45px !important;
  }

  .m-l-46 {
    margin-left: 46px !important;
  }

  .m-l-47 {
    margin-left: 47px !important;
  }

  .m-l-48 {
    margin-left: 48px !important;
  }

  .m-l-49 {
    margin-left: 49px !important;
  }

  .m-l-50 {
    margin-left: 50px !important;
  }

  @media screen and (min-width: 768px) {
    .m-l-sm-0 {
      margin-left: 0px !important;
    }

    .m-l-sm-1 {
      margin-left: 1px !important;
    }

    .m-l-sm-2 {
      margin-left: 2px !important;
    }

    .m-l-sm-3 {
      margin-left: 3px !important;
    }

    .m-l-sm-4 {
      margin-left: 4px !important;
    }

    .m-l-sm-5 {
      margin-left: 5px !important;
    }

    .m-l-sm-6 {
      margin-left: 6px !important;
    }

    .m-l-sm-7 {
      margin-left: 7px !important;
    }

    .m-l-sm-8 {
      margin-left: 8px !important;
    }

    .m-l-sm-9 {
      margin-left: 9px !important;
    }

    .m-l-sm-10 {
      margin-left: 10px !important;
    }

    .m-l-sm-11 {
      margin-left: 11px !important;
    }

    .m-l-sm-12 {
      margin-left: 12px !important;
    }

    .m-l-sm-13 {
      margin-left: 13px !important;
    }

    .m-l-sm-14 {
      margin-left: 14px !important;
    }

    .m-l-sm-15 {
      margin-left: 15px !important;
    }

    .m-l-sm-16 {
      margin-left: 16px !important;
    }

    .m-l-sm-17 {
      margin-left: 17px !important;
    }

    .m-l-sm-18 {
      margin-left: 18px !important;
    }

    .m-l-sm-19 {
      margin-left: 19px !important;
    }

    .m-l-sm-20 {
      margin-left: 20px !important;
    }

    .m-l-sm-21 {
      margin-left: 21px !important;
    }

    .m-l-sm-22 {
      margin-left: 22px !important;
    }

    .m-l-sm-23 {
      margin-left: 23px !important;
    }

    .m-l-sm-24 {
      margin-left: 24px !important;
    }

    .m-l-sm-25 {
      margin-left: 25px !important;
    }

    .m-l-sm-26 {
      margin-left: 26px !important;
    }

    .m-l-sm-27 {
      margin-left: 27px !important;
    }

    .m-l-sm-28 {
      margin-left: 28px !important;
    }

    .m-l-sm-29 {
      margin-left: 29px !important;
    }

    .m-l-sm-30 {
      margin-left: 30px !important;
    }

    .m-l-sm-31 {
      margin-left: 31px !important;
    }

    .m-l-sm-32 {
      margin-left: 32px !important;
    }

    .m-l-sm-33 {
      margin-left: 33px !important;
    }

    .m-l-sm-34 {
      margin-left: 34px !important;
    }

    .m-l-sm-35 {
      margin-left: 35px !important;
    }

    .m-l-sm-36 {
      margin-left: 36px !important;
    }

    .m-l-sm-37 {
      margin-left: 37px !important;
    }

    .m-l-sm-38 {
      margin-left: 38px !important;
    }

    .m-l-sm-39 {
      margin-left: 39px !important;
    }

    .m-l-sm-40 {
      margin-left: 40px !important;
    }

    .m-l-sm-41 {
      margin-left: 41px !important;
    }

    .m-l-sm-42 {
      margin-left: 42px !important;
    }

    .m-l-sm-43 {
      margin-left: 43px !important;
    }

    .m-l-sm-44 {
      margin-left: 44px !important;
    }

    .m-l-sm-45 {
      margin-left: 45px !important;
    }

    .m-l-sm-46 {
      margin-left: 46px !important;
    }

    .m-l-sm-47 {
      margin-left: 47px !important;
    }

    .m-l-sm-48 {
      margin-left: 48px !important;
    }

    .m-l-sm-49 {
      margin-left: 49px !important;
    }

    .m-l-sm-50 {
      margin-left: 50px !important;
    }
  }

  @media screen and (min-width: 992px) {
    .m-l-md-0 {
      margin-left: 0px !important;
    }

    .m-l-md-1 {
      margin-left: 1px !important;
    }

    .m-l-md-2 {
      margin-left: 2px !important;
    }

    .m-l-md-3 {
      margin-left: 3px !important;
    }

    .m-l-md-4 {
      margin-left: 4px !important;
    }

    .m-l-md-5 {
      margin-left: 5px !important;
    }

    .m-l-md-6 {
      margin-left: 6px !important;
    }

    .m-l-md-7 {
      margin-left: 7px !important;
    }

    .m-l-md-8 {
      margin-left: 8px !important;
    }

    .m-l-md-9 {
      margin-left: 9px !important;
    }

    .m-l-md-10 {
      margin-left: 10px !important;
    }

    .m-l-md-11 {
      margin-left: 11px !important;
    }

    .m-l-md-12 {
      margin-left: 12px !important;
    }

    .m-l-md-13 {
      margin-left: 13px !important;
    }

    .m-l-md-14 {
      margin-left: 14px !important;
    }

    .m-l-md-15 {
      margin-left: 15px !important;
    }

    .m-l-md-16 {
      margin-left: 16px !important;
    }

    .m-l-md-17 {
      margin-left: 17px !important;
    }

    .m-l-md-18 {
      margin-left: 18px !important;
    }

    .m-l-md-19 {
      margin-left: 19px !important;
    }

    .m-l-md-20 {
      margin-left: 20px !important;
    }

    .m-l-md-21 {
      margin-left: 21px !important;
    }

    .m-l-md-22 {
      margin-left: 22px !important;
    }

    .m-l-md-23 {
      margin-left: 23px !important;
    }

    .m-l-md-24 {
      margin-left: 24px !important;
    }

    .m-l-md-25 {
      margin-left: 25px !important;
    }

    .m-l-md-26 {
      margin-left: 26px !important;
    }

    .m-l-md-27 {
      margin-left: 27px !important;
    }

    .m-l-md-28 {
      margin-left: 28px !important;
    }

    .m-l-md-29 {
      margin-left: 29px !important;
    }

    .m-l-md-30 {
      margin-left: 30px !important;
    }

    .m-l-md-31 {
      margin-left: 31px !important;
    }

    .m-l-md-32 {
      margin-left: 32px !important;
    }

    .m-l-md-33 {
      margin-left: 33px !important;
    }

    .m-l-md-34 {
      margin-left: 34px !important;
    }

    .m-l-md-35 {
      margin-left: 35px !important;
    }

    .m-l-md-36 {
      margin-left: 36px !important;
    }

    .m-l-md-37 {
      margin-left: 37px !important;
    }

    .m-l-md-38 {
      margin-left: 38px !important;
    }

    .m-l-md-39 {
      margin-left: 39px !important;
    }

    .m-l-md-40 {
      margin-left: 40px !important;
    }

    .m-l-md-41 {
      margin-left: 41px !important;
    }

    .m-l-md-42 {
      margin-left: 42px !important;
    }

    .m-l-md-43 {
      margin-left: 43px !important;
    }

    .m-l-md-44 {
      margin-left: 44px !important;
    }

    .m-l-md-45 {
      margin-left: 45px !important;
    }

    .m-l-md-46 {
      margin-left: 46px !important;
    }

    .m-l-md-47 {
      margin-left: 47px !important;
    }

    .m-l-md-48 {
      margin-left: 48px !important;
    }

    .m-l-md-49 {
      margin-left: 49px !important;
    }

    .m-l-md-50 {
      margin-left: 50px !important;
    }
  }

  @media screen and (min-width: 1200px) {
    .m-l-lg-0 {
      margin-left: 0px !important;
    }

    .m-l-lg-1 {
      margin-left: 1px !important;
    }

    .m-l-lg-2 {
      margin-left: 2px !important;
    }

    .m-l-lg-3 {
      margin-left: 3px !important;
    }

    .m-l-lg-4 {
      margin-left: 4px !important;
    }

    .m-l-lg-5 {
      margin-left: 5px !important;
    }

    .m-l-lg-6 {
      margin-left: 6px !important;
    }

    .m-l-lg-7 {
      margin-left: 7px !important;
    }

    .m-l-lg-8 {
      margin-left: 8px !important;
    }

    .m-l-lg-9 {
      margin-left: 9px !important;
    }

    .m-l-lg-10 {
      margin-left: 10px !important;
    }

    .m-l-lg-11 {
      margin-left: 11px !important;
    }

    .m-l-lg-12 {
      margin-left: 12px !important;
    }

    .m-l-lg-13 {
      margin-left: 13px !important;
    }

    .m-l-lg-14 {
      margin-left: 14px !important;
    }

    .m-l-lg-15 {
      margin-left: 15px !important;
    }

    .m-l-lg-16 {
      margin-left: 16px !important;
    }

    .m-l-lg-17 {
      margin-left: 17px !important;
    }

    .m-l-lg-18 {
      margin-left: 18px !important;
    }

    .m-l-lg-19 {
      margin-left: 19px !important;
    }

    .m-l-lg-20 {
      margin-left: 20px !important;
    }

    .m-l-lg-21 {
      margin-left: 21px !important;
    }

    .m-l-lg-22 {
      margin-left: 22px !important;
    }

    .m-l-lg-23 {
      margin-left: 23px !important;
    }

    .m-l-lg-24 {
      margin-left: 24px !important;
    }

    .m-l-lg-25 {
      margin-left: 25px !important;
    }

    .m-l-lg-26 {
      margin-left: 26px !important;
    }

    .m-l-lg-27 {
      margin-left: 27px !important;
    }

    .m-l-lg-28 {
      margin-left: 28px !important;
    }

    .m-l-lg-29 {
      margin-left: 29px !important;
    }

    .m-l-lg-30 {
      margin-left: 30px !important;
    }

    .m-l-lg-31 {
      margin-left: 31px !important;
    }

    .m-l-lg-32 {
      margin-left: 32px !important;
    }

    .m-l-lg-33 {
      margin-left: 33px !important;
    }

    .m-l-lg-34 {
      margin-left: 34px !important;
    }

    .m-l-lg-35 {
      margin-left: 35px !important;
    }

    .m-l-lg-36 {
      margin-left: 36px !important;
    }

    .m-l-lg-37 {
      margin-left: 37px !important;
    }

    .m-l-lg-38 {
      margin-left: 38px !important;
    }

    .m-l-lg-39 {
      margin-left: 39px !important;
    }

    .m-l-lg-40 {
      margin-left: 40px !important;
    }

    .m-l-lg-41 {
      margin-left: 41px !important;
    }

    .m-l-lg-42 {
      margin-left: 42px !important;
    }

    .m-l-lg-43 {
      margin-left: 43px !important;
    }

    .m-l-lg-44 {
      margin-left: 44px !important;
    }

    .m-l-lg-45 {
      margin-left: 45px !important;
    }

    .m-l-lg-46 {
      margin-left: 46px !important;
    }

    .m-l-lg-47 {
      margin-left: 47px !important;
    }

    .m-l-lg-48 {
      margin-left: 48px !important;
    }

    .m-l-lg-49 {
      margin-left: 49px !important;
    }

    .m-l-lg-50 {
      margin-left: 50px !important;
    }
  }

  .m-r-0 {
    margin-right: 0px !important;
  }

  .m-r-1 {
    margin-right: 1px !important;
  }

  .m-r-2 {
    margin-right: 2px !important;
  }

  .m-r-3 {
    margin-right: 3px !important;
  }

  .m-r-4 {
    margin-right: 4px !important;
  }

  .m-r-5 {
    margin-right: 5px !important;
  }

  .m-r-6 {
    margin-right: 6px !important;
  }

  .m-r-7 {
    margin-right: 7px !important;
  }

  .m-r-8 {
    margin-right: 8px !important;
  }

  .m-r-9 {
    margin-right: 9px !important;
  }

  .m-r-10 {
    margin-right: 10px !important;
  }

  .m-r-11 {
    margin-right: 11px !important;
  }

  .m-r-12 {
    margin-right: 12px !important;
  }

  .m-r-13 {
    margin-right: 13px !important;
  }

  .m-r-14 {
    margin-right: 14px !important;
  }

  .m-r-15 {
    margin-right: 15px !important;
  }

  .m-r-16 {
    margin-right: 16px !important;
  }

  .m-r-17 {
    margin-right: 17px !important;
  }

  .m-r-18 {
    margin-right: 18px !important;
  }

  .m-r-19 {
    margin-right: 19px !important;
  }

  .m-r-20 {
    margin-right: 20px !important;
  }

  .m-r-21 {
    margin-right: 21px !important;
  }

  .m-r-22 {
    margin-right: 22px !important;
  }

  .m-r-23 {
    margin-right: 23px !important;
  }

  .m-r-24 {
    margin-right: 24px !important;
  }

  .m-r-25 {
    margin-right: 25px !important;
  }

  .m-r-26 {
    margin-right: 26px !important;
  }

  .m-r-27 {
    margin-right: 27px !important;
  }

  .m-r-28 {
    margin-right: 28px !important;
  }

  .m-r-29 {
    margin-right: 29px !important;
  }

  .m-r-30 {
    margin-right: 30px !important;
  }

  .m-r-31 {
    margin-right: 31px !important;
  }

  .m-r-32 {
    margin-right: 32px !important;
  }

  .m-r-33 {
    margin-right: 33px !important;
  }

  .m-r-34 {
    margin-right: 34px !important;
  }

  .m-r-35 {
    margin-right: 35px !important;
  }

  .m-r-36 {
    margin-right: 36px !important;
  }

  .m-r-37 {
    margin-right: 37px !important;
  }

  .m-r-38 {
    margin-right: 38px !important;
  }

  .m-r-39 {
    margin-right: 39px !important;
  }

  .m-r-40 {
    margin-right: 40px !important;
  }

  .m-r-41 {
    margin-right: 41px !important;
  }

  .m-r-42 {
    margin-right: 42px !important;
  }

  .m-r-43 {
    margin-right: 43px !important;
  }

  .m-r-44 {
    margin-right: 44px !important;
  }

  .m-r-45 {
    margin-right: 45px !important;
  }

  .m-r-46 {
    margin-right: 46px !important;
  }

  .m-r-47 {
    margin-right: 47px !important;
  }

  .m-r-48 {
    margin-right: 48px !important;
  }

  .m-r-49 {
    margin-right: 49px !important;
  }

  .m-r-50 {
    margin-right: 50px !important;
  }

  @media screen and (min-width: 768px) {
    .m-r-sm-0 {
      margin-right: 0px !important;
    }

    .m-r-sm-1 {
      margin-right: 1px !important;
    }

    .m-r-sm-2 {
      margin-right: 2px !important;
    }

    .m-r-sm-3 {
      margin-right: 3px !important;
    }

    .m-r-sm-4 {
      margin-right: 4px !important;
    }

    .m-r-sm-5 {
      margin-right: 5px !important;
    }

    .m-r-sm-6 {
      margin-right: 6px !important;
    }

    .m-r-sm-7 {
      margin-right: 7px !important;
    }

    .m-r-sm-8 {
      margin-right: 8px !important;
    }

    .m-r-sm-9 {
      margin-right: 9px !important;
    }

    .m-r-sm-10 {
      margin-right: 10px !important;
    }

    .m-r-sm-11 {
      margin-right: 11px !important;
    }

    .m-r-sm-12 {
      margin-right: 12px !important;
    }

    .m-r-sm-13 {
      margin-right: 13px !important;
    }

    .m-r-sm-14 {
      margin-right: 14px !important;
    }

    .m-r-sm-15 {
      margin-right: 15px !important;
    }

    .m-r-sm-16 {
      margin-right: 16px !important;
    }

    .m-r-sm-17 {
      margin-right: 17px !important;
    }

    .m-r-sm-18 {
      margin-right: 18px !important;
    }

    .m-r-sm-19 {
      margin-right: 19px !important;
    }

    .m-r-sm-20 {
      margin-right: 20px !important;
    }

    .m-r-sm-21 {
      margin-right: 21px !important;
    }

    .m-r-sm-22 {
      margin-right: 22px !important;
    }

    .m-r-sm-23 {
      margin-right: 23px !important;
    }

    .m-r-sm-24 {
      margin-right: 24px !important;
    }

    .m-r-sm-25 {
      margin-right: 25px !important;
    }

    .m-r-sm-26 {
      margin-right: 26px !important;
    }

    .m-r-sm-27 {
      margin-right: 27px !important;
    }

    .m-r-sm-28 {
      margin-right: 28px !important;
    }

    .m-r-sm-29 {
      margin-right: 29px !important;
    }

    .m-r-sm-30 {
      margin-right: 30px !important;
    }

    .m-r-sm-31 {
      margin-right: 31px !important;
    }

    .m-r-sm-32 {
      margin-right: 32px !important;
    }

    .m-r-sm-33 {
      margin-right: 33px !important;
    }

    .m-r-sm-34 {
      margin-right: 34px !important;
    }

    .m-r-sm-35 {
      margin-right: 35px !important;
    }

    .m-r-sm-36 {
      margin-right: 36px !important;
    }

    .m-r-sm-37 {
      margin-right: 37px !important;
    }

    .m-r-sm-38 {
      margin-right: 38px !important;
    }

    .m-r-sm-39 {
      margin-right: 39px !important;
    }

    .m-r-sm-40 {
      margin-right: 40px !important;
    }

    .m-r-sm-41 {
      margin-right: 41px !important;
    }

    .m-r-sm-42 {
      margin-right: 42px !important;
    }

    .m-r-sm-43 {
      margin-right: 43px !important;
    }

    .m-r-sm-44 {
      margin-right: 44px !important;
    }

    .m-r-sm-45 {
      margin-right: 45px !important;
    }

    .m-r-sm-46 {
      margin-right: 46px !important;
    }

    .m-r-sm-47 {
      margin-right: 47px !important;
    }

    .m-r-sm-48 {
      margin-right: 48px !important;
    }

    .m-r-sm-49 {
      margin-right: 49px !important;
    }

    .m-r-sm-50 {
      margin-right: 50px !important;
    }
  }

  @media screen and (min-width: 992px) {
    .m-r-md-0 {
      margin-right: 0px !important;
    }

    .m-r-md-1 {
      margin-right: 1px !important;
    }

    .m-r-md-2 {
      margin-right: 2px !important;
    }

    .m-r-md-3 {
      margin-right: 3px !important;
    }

    .m-r-md-4 {
      margin-right: 4px !important;
    }

    .m-r-md-5 {
      margin-right: 5px !important;
    }

    .m-r-md-6 {
      margin-right: 6px !important;
    }

    .m-r-md-7 {
      margin-right: 7px !important;
    }

    .m-r-md-8 {
      margin-right: 8px !important;
    }

    .m-r-md-9 {
      margin-right: 9px !important;
    }

    .m-r-md-10 {
      margin-right: 10px !important;
    }

    .m-r-md-11 {
      margin-right: 11px !important;
    }

    .m-r-md-12 {
      margin-right: 12px !important;
    }

    .m-r-md-13 {
      margin-right: 13px !important;
    }

    .m-r-md-14 {
      margin-right: 14px !important;
    }

    .m-r-md-15 {
      margin-right: 15px !important;
    }

    .m-r-md-16 {
      margin-right: 16px !important;
    }

    .m-r-md-17 {
      margin-right: 17px !important;
    }

    .m-r-md-18 {
      margin-right: 18px !important;
    }

    .m-r-md-19 {
      margin-right: 19px !important;
    }

    .m-r-md-20 {
      margin-right: 20px !important;
    }

    .m-r-md-21 {
      margin-right: 21px !important;
    }

    .m-r-md-22 {
      margin-right: 22px !important;
    }

    .m-r-md-23 {
      margin-right: 23px !important;
    }

    .m-r-md-24 {
      margin-right: 24px !important;
    }

    .m-r-md-25 {
      margin-right: 25px !important;
    }

    .m-r-md-26 {
      margin-right: 26px !important;
    }

    .m-r-md-27 {
      margin-right: 27px !important;
    }

    .m-r-md-28 {
      margin-right: 28px !important;
    }

    .m-r-md-29 {
      margin-right: 29px !important;
    }

    .m-r-md-30 {
      margin-right: 30px !important;
    }

    .m-r-md-31 {
      margin-right: 31px !important;
    }

    .m-r-md-32 {
      margin-right: 32px !important;
    }

    .m-r-md-33 {
      margin-right: 33px !important;
    }

    .m-r-md-34 {
      margin-right: 34px !important;
    }

    .m-r-md-35 {
      margin-right: 35px !important;
    }

    .m-r-md-36 {
      margin-right: 36px !important;
    }

    .m-r-md-37 {
      margin-right: 37px !important;
    }

    .m-r-md-38 {
      margin-right: 38px !important;
    }

    .m-r-md-39 {
      margin-right: 39px !important;
    }

    .m-r-md-40 {
      margin-right: 40px !important;
    }

    .m-r-md-41 {
      margin-right: 41px !important;
    }

    .m-r-md-42 {
      margin-right: 42px !important;
    }

    .m-r-md-43 {
      margin-right: 43px !important;
    }

    .m-r-md-44 {
      margin-right: 44px !important;
    }

    .m-r-md-45 {
      margin-right: 45px !important;
    }

    .m-r-md-46 {
      margin-right: 46px !important;
    }

    .m-r-md-47 {
      margin-right: 47px !important;
    }

    .m-r-md-48 {
      margin-right: 48px !important;
    }

    .m-r-md-49 {
      margin-right: 49px !important;
    }

    .m-r-md-50 {
      margin-right: 50px !important;
    }
  }

  @media screen and (min-width: 1200px) {
    .m-r-lg-0 {
      margin-right: 0px !important;
    }

    .m-r-lg-1 {
      margin-right: 1px !important;
    }

    .m-r-lg-2 {
      margin-right: 2px !important;
    }

    .m-r-lg-3 {
      margin-right: 3px !important;
    }

    .m-r-lg-4 {
      margin-right: 4px !important;
    }

    .m-r-lg-5 {
      margin-right: 5px !important;
    }

    .m-r-lg-6 {
      margin-right: 6px !important;
    }

    .m-r-lg-7 {
      margin-right: 7px !important;
    }

    .m-r-lg-8 {
      margin-right: 8px !important;
    }

    .m-r-lg-9 {
      margin-right: 9px !important;
    }

    .m-r-lg-10 {
      margin-right: 10px !important;
    }

    .m-r-lg-11 {
      margin-right: 11px !important;
    }

    .m-r-lg-12 {
      margin-right: 12px !important;
    }

    .m-r-lg-13 {
      margin-right: 13px !important;
    }

    .m-r-lg-14 {
      margin-right: 14px !important;
    }

    .m-r-lg-15 {
      margin-right: 15px !important;
    }

    .m-r-lg-16 {
      margin-right: 16px !important;
    }

    .m-r-lg-17 {
      margin-right: 17px !important;
    }

    .m-r-lg-18 {
      margin-right: 18px !important;
    }

    .m-r-lg-19 {
      margin-right: 19px !important;
    }

    .m-r-lg-20 {
      margin-right: 20px !important;
    }

    .m-r-lg-21 {
      margin-right: 21px !important;
    }

    .m-r-lg-22 {
      margin-right: 22px !important;
    }

    .m-r-lg-23 {
      margin-right: 23px !important;
    }

    .m-r-lg-24 {
      margin-right: 24px !important;
    }

    .m-r-lg-25 {
      margin-right: 25px !important;
    }

    .m-r-lg-26 {
      margin-right: 26px !important;
    }

    .m-r-lg-27 {
      margin-right: 27px !important;
    }

    .m-r-lg-28 {
      margin-right: 28px !important;
    }

    .m-r-lg-29 {
      margin-right: 29px !important;
    }

    .m-r-lg-30 {
      margin-right: 30px !important;
    }

    .m-r-lg-31 {
      margin-right: 31px !important;
    }

    .m-r-lg-32 {
      margin-right: 32px !important;
    }

    .m-r-lg-33 {
      margin-right: 33px !important;
    }

    .m-r-lg-34 {
      margin-right: 34px !important;
    }

    .m-r-lg-35 {
      margin-right: 35px !important;
    }

    .m-r-lg-36 {
      margin-right: 36px !important;
    }

    .m-r-lg-37 {
      margin-right: 37px !important;
    }

    .m-r-lg-38 {
      margin-right: 38px !important;
    }

    .m-r-lg-39 {
      margin-right: 39px !important;
    }

    .m-r-lg-40 {
      margin-right: 40px !important;
    }

    .m-r-lg-41 {
      margin-right: 41px !important;
    }

    .m-r-lg-42 {
      margin-right: 42px !important;
    }

    .m-r-lg-43 {
      margin-right: 43px !important;
    }

    .m-r-lg-44 {
      margin-right: 44px !important;
    }

    .m-r-lg-45 {
      margin-right: 45px !important;
    }

    .m-r-lg-46 {
      margin-right: 46px !important;
    }

    .m-r-lg-47 {
      margin-right: 47px !important;
    }

    .m-r-lg-48 {
      margin-right: 48px !important;
    }

    .m-r-lg-49 {
      margin-right: 49px !important;
    }

    .m-r-lg-50 {
      margin-right: 50px !important;
    }
  }

  .m-t-0 {
    margin-top: 0px !important;
  }

  .m-t-1 {
    margin-top: 1px !important;
  }

  .m-t-2 {
    margin-top: 2px !important;
  }

  .m-t-3 {
    margin-top: 3px !important;
  }

  .m-t-4 {
    margin-top: 4px !important;
  }

  .m-t-5 {
    margin-top: 5px !important;
  }

  .m-t-6 {
    margin-top: 6px !important;
  }

  .m-t-7 {
    margin-top: 7px !important;
  }

  .m-t-8 {
    margin-top: 8px !important;
  }

  .m-t-9 {
    margin-top: 9px !important;
  }

  .m-t-10 {
    margin-top: 10px !important;
  }

  .m-t-11 {
    margin-top: 11px !important;
  }

  .m-t-12 {
    margin-top: 12px !important;
  }

  .m-t-13 {
    margin-top: 13px !important;
  }

  .m-t-14 {
    margin-top: 14px !important;
  }

  .m-t-15 {
    margin-top: 15px !important;
  }

  .m-t-16 {
    margin-top: 16px !important;
  }

  .m-t-17 {
    margin-top: 17px !important;
  }

  .m-t-18 {
    margin-top: 18px !important;
  }

  .m-t-19 {
    margin-top: 19px !important;
  }

  .m-t-20 {
    margin-top: 20px !important;
  }

  .m-t-21 {
    margin-top: 21px !important;
  }

  .m-t-22 {
    margin-top: 22px !important;
  }

  .m-t-23 {
    margin-top: 23px !important;
  }

  .m-t-24 {
    margin-top: 24px !important;
  }

  .m-t-25 {
    margin-top: 25px !important;
  }

  .m-t-26 {
    margin-top: 26px !important;
  }

  .m-t-27 {
    margin-top: 27px !important;
  }

  .m-t-28 {
    margin-top: 28px !important;
  }

  .m-t-29 {
    margin-top: 29px !important;
  }

  .m-t-30 {
    margin-top: 30px !important;
  }

  .m-t-31 {
    margin-top: 31px !important;
  }

  .m-t-32 {
    margin-top: 32px !important;
  }

  .m-t-33 {
    margin-top: 33px !important;
  }

  .m-t-34 {
    margin-top: 34px !important;
  }

  .m-t-35 {
    margin-top: 35px !important;
  }

  .m-t-36 {
    margin-top: 36px !important;
  }

  .m-t-37 {
    margin-top: 37px !important;
  }

  .m-t-38 {
    margin-top: 38px !important;
  }

  .m-t-39 {
    margin-top: 39px !important;
  }

  .m-t-40 {
    margin-top: 40px !important;
  }

  .m-t-41 {
    margin-top: 41px !important;
  }

  .m-t-42 {
    margin-top: 42px !important;
  }

  .m-t-43 {
    margin-top: 43px !important;
  }

  .m-t-44 {
    margin-top: 44px !important;
  }

  .m-t-45 {
    margin-top: 45px !important;
  }

  .m-t-46 {
    margin-top: 46px !important;
  }

  .m-t-47 {
    margin-top: 47px !important;
  }

  .m-t-48 {
    margin-top: 48px !important;
  }

  .m-t-49 {
    margin-top: 49px !important;
  }

  .m-t-50 {
    margin-top: 50px !important;
  }

  @media screen and (min-width: 768px) {
    .m-t-sm-0 {
      margin-top: 0px !important;
    }

    .m-t-sm-1 {
      margin-top: 1px !important;
    }

    .m-t-sm-2 {
      margin-top: 2px !important;
    }

    .m-t-sm-3 {
      margin-top: 3px !important;
    }

    .m-t-sm-4 {
      margin-top: 4px !important;
    }

    .m-t-sm-5 {
      margin-top: 5px !important;
    }

    .m-t-sm-6 {
      margin-top: 6px !important;
    }

    .m-t-sm-7 {
      margin-top: 7px !important;
    }

    .m-t-sm-8 {
      margin-top: 8px !important;
    }

    .m-t-sm-9 {
      margin-top: 9px !important;
    }

    .m-t-sm-10 {
      margin-top: 10px !important;
    }

    .m-t-sm-11 {
      margin-top: 11px !important;
    }

    .m-t-sm-12 {
      margin-top: 12px !important;
    }

    .m-t-sm-13 {
      margin-top: 13px !important;
    }

    .m-t-sm-14 {
      margin-top: 14px !important;
    }

    .m-t-sm-15 {
      margin-top: 15px !important;
    }

    .m-t-sm-16 {
      margin-top: 16px !important;
    }

    .m-t-sm-17 {
      margin-top: 17px !important;
    }

    .m-t-sm-18 {
      margin-top: 18px !important;
    }

    .m-t-sm-19 {
      margin-top: 19px !important;
    }

    .m-t-sm-20 {
      margin-top: 20px !important;
    }

    .m-t-sm-21 {
      margin-top: 21px !important;
    }

    .m-t-sm-22 {
      margin-top: 22px !important;
    }

    .m-t-sm-23 {
      margin-top: 23px !important;
    }

    .m-t-sm-24 {
      margin-top: 24px !important;
    }

    .m-t-sm-25 {
      margin-top: 25px !important;
    }

    .m-t-sm-26 {
      margin-top: 26px !important;
    }

    .m-t-sm-27 {
      margin-top: 27px !important;
    }

    .m-t-sm-28 {
      margin-top: 28px !important;
    }

    .m-t-sm-29 {
      margin-top: 29px !important;
    }

    .m-t-sm-30 {
      margin-top: 30px !important;
    }

    .m-t-sm-31 {
      margin-top: 31px !important;
    }

    .m-t-sm-32 {
      margin-top: 32px !important;
    }

    .m-t-sm-33 {
      margin-top: 33px !important;
    }

    .m-t-sm-34 {
      margin-top: 34px !important;
    }

    .m-t-sm-35 {
      margin-top: 35px !important;
    }

    .m-t-sm-36 {
      margin-top: 36px !important;
    }

    .m-t-sm-37 {
      margin-top: 37px !important;
    }

    .m-t-sm-38 {
      margin-top: 38px !important;
    }

    .m-t-sm-39 {
      margin-top: 39px !important;
    }

    .m-t-sm-40 {
      margin-top: 40px !important;
    }

    .m-t-sm-41 {
      margin-top: 41px !important;
    }

    .m-t-sm-42 {
      margin-top: 42px !important;
    }

    .m-t-sm-43 {
      margin-top: 43px !important;
    }

    .m-t-sm-44 {
      margin-top: 44px !important;
    }

    .m-t-sm-45 {
      margin-top: 45px !important;
    }

    .m-t-sm-46 {
      margin-top: 46px !important;
    }

    .m-t-sm-47 {
      margin-top: 47px !important;
    }

    .m-t-sm-48 {
      margin-top: 48px !important;
    }

    .m-t-sm-49 {
      margin-top: 49px !important;
    }

    .m-t-sm-50 {
      margin-top: 50px !important;
    }
  }

  @media screen and (min-width: 992px) {
    .m-t-md-0 {
      margin-top: 0px !important;
    }

    .m-t-md-1 {
      margin-top: 1px !important;
    }

    .m-t-md-2 {
      margin-top: 2px !important;
    }

    .m-t-md-3 {
      margin-top: 3px !important;
    }

    .m-t-md-4 {
      margin-top: 4px !important;
    }

    .m-t-md-5 {
      margin-top: 5px !important;
    }

    .m-t-md-6 {
      margin-top: 6px !important;
    }

    .m-t-md-7 {
      margin-top: 7px !important;
    }

    .m-t-md-8 {
      margin-top: 8px !important;
    }

    .m-t-md-9 {
      margin-top: 9px !important;
    }

    .m-t-md-10 {
      margin-top: 10px !important;
    }

    .m-t-md-11 {
      margin-top: 11px !important;
    }

    .m-t-md-12 {
      margin-top: 12px !important;
    }

    .m-t-md-13 {
      margin-top: 13px !important;
    }

    .m-t-md-14 {
      margin-top: 14px !important;
    }

    .m-t-md-15 {
      margin-top: 15px !important;
    }

    .m-t-md-16 {
      margin-top: 16px !important;
    }

    .m-t-md-17 {
      margin-top: 17px !important;
    }

    .m-t-md-18 {
      margin-top: 18px !important;
    }

    .m-t-md-19 {
      margin-top: 19px !important;
    }

    .m-t-md-20 {
      margin-top: 20px !important;
    }

    .m-t-md-21 {
      margin-top: 21px !important;
    }

    .m-t-md-22 {
      margin-top: 22px !important;
    }

    .m-t-md-23 {
      margin-top: 23px !important;
    }

    .m-t-md-24 {
      margin-top: 24px !important;
    }

    .m-t-md-25 {
      margin-top: 25px !important;
    }

    .m-t-md-26 {
      margin-top: 26px !important;
    }

    .m-t-md-27 {
      margin-top: 27px !important;
    }

    .m-t-md-28 {
      margin-top: 28px !important;
    }

    .m-t-md-29 {
      margin-top: 29px !important;
    }

    .m-t-md-30 {
      margin-top: 30px !important;
    }

    .m-t-md-31 {
      margin-top: 31px !important;
    }

    .m-t-md-32 {
      margin-top: 32px !important;
    }

    .m-t-md-33 {
      margin-top: 33px !important;
    }

    .m-t-md-34 {
      margin-top: 34px !important;
    }

    .m-t-md-35 {
      margin-top: 35px !important;
    }

    .m-t-md-36 {
      margin-top: 36px !important;
    }

    .m-t-md-37 {
      margin-top: 37px !important;
    }

    .m-t-md-38 {
      margin-top: 38px !important;
    }

    .m-t-md-39 {
      margin-top: 39px !important;
    }

    .m-t-md-40 {
      margin-top: 40px !important;
    }

    .m-t-md-41 {
      margin-top: 41px !important;
    }

    .m-t-md-42 {
      margin-top: 42px !important;
    }

    .m-t-md-43 {
      margin-top: 43px !important;
    }

    .m-t-md-44 {
      margin-top: 44px !important;
    }

    .m-t-md-45 {
      margin-top: 45px !important;
    }

    .m-t-md-46 {
      margin-top: 46px !important;
    }

    .m-t-md-47 {
      margin-top: 47px !important;
    }

    .m-t-md-48 {
      margin-top: 48px !important;
    }

    .m-t-md-49 {
      margin-top: 49px !important;
    }

    .m-t-md-50 {
      margin-top: 50px !important;
    }
  }

  @media screen and (min-width: 1200px) {
    .m-t-lg-0 {
      margin-top: 0px !important;
    }

    .m-t-lg-1 {
      margin-top: 1px !important;
    }

    .m-t-lg-2 {
      margin-top: 2px !important;
    }

    .m-t-lg-3 {
      margin-top: 3px !important;
    }

    .m-t-lg-4 {
      margin-top: 4px !important;
    }

    .m-t-lg-5 {
      margin-top: 5px !important;
    }

    .m-t-lg-6 {
      margin-top: 6px !important;
    }

    .m-t-lg-7 {
      margin-top: 7px !important;
    }

    .m-t-lg-8 {
      margin-top: 8px !important;
    }

    .m-t-lg-9 {
      margin-top: 9px !important;
    }

    .m-t-lg-10 {
      margin-top: 10px !important;
    }

    .m-t-lg-11 {
      margin-top: 11px !important;
    }

    .m-t-lg-12 {
      margin-top: 12px !important;
    }

    .m-t-lg-13 {
      margin-top: 13px !important;
    }

    .m-t-lg-14 {
      margin-top: 14px !important;
    }

    .m-t-lg-15 {
      margin-top: 15px !important;
    }

    .m-t-lg-16 {
      margin-top: 16px !important;
    }

    .m-t-lg-17 {
      margin-top: 17px !important;
    }

    .m-t-lg-18 {
      margin-top: 18px !important;
    }

    .m-t-lg-19 {
      margin-top: 19px !important;
    }

    .m-t-lg-20 {
      margin-top: 20px !important;
    }

    .m-t-lg-21 {
      margin-top: 21px !important;
    }

    .m-t-lg-22 {
      margin-top: 22px !important;
    }

    .m-t-lg-23 {
      margin-top: 23px !important;
    }

    .m-t-lg-24 {
      margin-top: 24px !important;
    }

    .m-t-lg-25 {
      margin-top: 25px !important;
    }

    .m-t-lg-26 {
      margin-top: 26px !important;
    }

    .m-t-lg-27 {
      margin-top: 27px !important;
    }

    .m-t-lg-28 {
      margin-top: 28px !important;
    }

    .m-t-lg-29 {
      margin-top: 29px !important;
    }

    .m-t-lg-30 {
      margin-top: 30px !important;
    }

    .m-t-lg-31 {
      margin-top: 31px !important;
    }

    .m-t-lg-32 {
      margin-top: 32px !important;
    }

    .m-t-lg-33 {
      margin-top: 33px !important;
    }

    .m-t-lg-34 {
      margin-top: 34px !important;
    }

    .m-t-lg-35 {
      margin-top: 35px !important;
    }

    .m-t-lg-36 {
      margin-top: 36px !important;
    }

    .m-t-lg-37 {
      margin-top: 37px !important;
    }

    .m-t-lg-38 {
      margin-top: 38px !important;
    }

    .m-t-lg-39 {
      margin-top: 39px !important;
    }

    .m-t-lg-40 {
      margin-top: 40px !important;
    }

    .m-t-lg-41 {
      margin-top: 41px !important;
    }

    .m-t-lg-42 {
      margin-top: 42px !important;
    }

    .m-t-lg-43 {
      margin-top: 43px !important;
    }

    .m-t-lg-44 {
      margin-top: 44px !important;
    }

    .m-t-lg-45 {
      margin-top: 45px !important;
    }

    .m-t-lg-46 {
      margin-top: 46px !important;
    }

    .m-t-lg-47 {
      margin-top: 47px !important;
    }

    .m-t-lg-48 {
      margin-top: 48px !important;
    }

    .m-t-lg-49 {
      margin-top: 49px !important;
    }

    .m-t-lg-50 {
      margin-top: 50px !important;
    }
  }

  .m-b-0 {
    margin-bottom: 0px !important;
  }

  .m-b-1 {
    margin-bottom: 1px !important;
  }

  .m-b-2 {
    margin-bottom: 2px !important;
  }

  .m-b-3 {
    margin-bottom: 3px !important;
  }

  .m-b-4 {
    margin-bottom: 4px !important;
  }

  .m-b-5 {
    margin-bottom: 5px !important;
  }

  .m-b-6 {
    margin-bottom: 6px !important;
  }

  .m-b-7 {
    margin-bottom: 7px !important;
  }

  .m-b-8 {
    margin-bottom: 8px !important;
  }

  .m-b-9 {
    margin-bottom: 9px !important;
  }

  .m-b-10 {
    margin-bottom: 10px !important;
  }

  .m-b-11 {
    margin-bottom: 11px !important;
  }

  .m-b-12 {
    margin-bottom: 12px !important;
  }

  .m-b-13 {
    margin-bottom: 13px !important;
  }

  .m-b-14 {
    margin-bottom: 14px !important;
  }

  .m-b-15 {
    margin-bottom: 15px !important;
  }

  .m-b-16 {
    margin-bottom: 16px !important;
  }

  .m-b-17 {
    margin-bottom: 17px !important;
  }

  .m-b-18 {
    margin-bottom: 18px !important;
  }

  .m-b-19 {
    margin-bottom: 19px !important;
  }

  .m-b-20 {
    margin-bottom: 20px !important;
  }

  .m-b-21 {
    margin-bottom: 21px !important;
  }

  .m-b-22 {
    margin-bottom: 22px !important;
  }

  .m-b-23 {
    margin-bottom: 23px !important;
  }

  .m-b-24 {
    margin-bottom: 24px !important;
  }

  .m-b-25 {
    margin-bottom: 25px !important;
  }

  .m-b-26 {
    margin-bottom: 26px !important;
  }

  .m-b-27 {
    margin-bottom: 27px !important;
  }

  .m-b-28 {
    margin-bottom: 28px !important;
  }

  .m-b-29 {
    margin-bottom: 29px !important;
  }

  .m-b-30 {
    margin-bottom: 30px !important;
  }

  .m-b-31 {
    margin-bottom: 31px !important;
  }

  .m-b-32 {
    margin-bottom: 32px !important;
  }

  .m-b-33 {
    margin-bottom: 33px !important;
  }

  .m-b-34 {
    margin-bottom: 34px !important;
  }

  .m-b-35 {
    margin-bottom: 35px !important;
  }

  .m-b-36 {
    margin-bottom: 36px !important;
  }

  .m-b-37 {
    margin-bottom: 37px !important;
  }

  .m-b-38 {
    margin-bottom: 38px !important;
  }

  .m-b-39 {
    margin-bottom: 39px !important;
  }

  .m-b-40 {
    margin-bottom: 40px !important;
  }

  .m-b-41 {
    margin-bottom: 41px !important;
  }

  .m-b-42 {
    margin-bottom: 42px !important;
  }

  .m-b-43 {
    margin-bottom: 43px !important;
  }

  .m-b-44 {
    margin-bottom: 44px !important;
  }

  .m-b-45 {
    margin-bottom: 45px !important;
  }

  .m-b-46 {
    margin-bottom: 46px !important;
  }

  .m-b-47 {
    margin-bottom: 47px !important;
  }

  .m-b-48 {
    margin-bottom: 48px !important;
  }

  .m-b-49 {
    margin-bottom: 49px !important;
  }

  .m-b-50 {
    margin-bottom: 50px !important;
  }

  @media screen and (min-width: 768px) {
    .m-b-sm-0 {
      margin-bottom: 0px !important;
    }

    .m-b-sm-1 {
      margin-bottom: 1px !important;
    }

    .m-b-sm-2 {
      margin-bottom: 2px !important;
    }

    .m-b-sm-3 {
      margin-bottom: 3px !important;
    }

    .m-b-sm-4 {
      margin-bottom: 4px !important;
    }

    .m-b-sm-5 {
      margin-bottom: 5px !important;
    }

    .m-b-sm-6 {
      margin-bottom: 6px !important;
    }

    .m-b-sm-7 {
      margin-bottom: 7px !important;
    }

    .m-b-sm-8 {
      margin-bottom: 8px !important;
    }

    .m-b-sm-9 {
      margin-bottom: 9px !important;
    }

    .m-b-sm-10 {
      margin-bottom: 10px !important;
    }

    .m-b-sm-11 {
      margin-bottom: 11px !important;
    }

    .m-b-sm-12 {
      margin-bottom: 12px !important;
    }

    .m-b-sm-13 {
      margin-bottom: 13px !important;
    }

    .m-b-sm-14 {
      margin-bottom: 14px !important;
    }

    .m-b-sm-15 {
      margin-bottom: 15px !important;
    }

    .m-b-sm-16 {
      margin-bottom: 16px !important;
    }

    .m-b-sm-17 {
      margin-bottom: 17px !important;
    }

    .m-b-sm-18 {
      margin-bottom: 18px !important;
    }

    .m-b-sm-19 {
      margin-bottom: 19px !important;
    }

    .m-b-sm-20 {
      margin-bottom: 20px !important;
    }

    .m-b-sm-21 {
      margin-bottom: 21px !important;
    }

    .m-b-sm-22 {
      margin-bottom: 22px !important;
    }

    .m-b-sm-23 {
      margin-bottom: 23px !important;
    }

    .m-b-sm-24 {
      margin-bottom: 24px !important;
    }

    .m-b-sm-25 {
      margin-bottom: 25px !important;
    }

    .m-b-sm-26 {
      margin-bottom: 26px !important;
    }

    .m-b-sm-27 {
      margin-bottom: 27px !important;
    }

    .m-b-sm-28 {
      margin-bottom: 28px !important;
    }

    .m-b-sm-29 {
      margin-bottom: 29px !important;
    }

    .m-b-sm-30 {
      margin-bottom: 30px !important;
    }

    .m-b-sm-31 {
      margin-bottom: 31px !important;
    }

    .m-b-sm-32 {
      margin-bottom: 32px !important;
    }

    .m-b-sm-33 {
      margin-bottom: 33px !important;
    }

    .m-b-sm-34 {
      margin-bottom: 34px !important;
    }

    .m-b-sm-35 {
      margin-bottom: 35px !important;
    }

    .m-b-sm-36 {
      margin-bottom: 36px !important;
    }

    .m-b-sm-37 {
      margin-bottom: 37px !important;
    }

    .m-b-sm-38 {
      margin-bottom: 38px !important;
    }

    .m-b-sm-39 {
      margin-bottom: 39px !important;
    }

    .m-b-sm-40 {
      margin-bottom: 40px !important;
    }

    .m-b-sm-41 {
      margin-bottom: 41px !important;
    }

    .m-b-sm-42 {
      margin-bottom: 42px !important;
    }

    .m-b-sm-43 {
      margin-bottom: 43px !important;
    }

    .m-b-sm-44 {
      margin-bottom: 44px !important;
    }

    .m-b-sm-45 {
      margin-bottom: 45px !important;
    }

    .m-b-sm-46 {
      margin-bottom: 46px !important;
    }

    .m-b-sm-47 {
      margin-bottom: 47px !important;
    }

    .m-b-sm-48 {
      margin-bottom: 48px !important;
    }

    .m-b-sm-49 {
      margin-bottom: 49px !important;
    }

    .m-b-sm-50 {
      margin-bottom: 50px !important;
    }
  }

  @media screen and (min-width: 992px) {
    .m-b-md-0 {
      margin-bottom: 0px !important;
    }

    .m-b-md-1 {
      margin-bottom: 1px !important;
    }

    .m-b-md-2 {
      margin-bottom: 2px !important;
    }

    .m-b-md-3 {
      margin-bottom: 3px !important;
    }

    .m-b-md-4 {
      margin-bottom: 4px !important;
    }

    .m-b-md-5 {
      margin-bottom: 5px !important;
    }

    .m-b-md-6 {
      margin-bottom: 6px !important;
    }

    .m-b-md-7 {
      margin-bottom: 7px !important;
    }

    .m-b-md-8 {
      margin-bottom: 8px !important;
    }

    .m-b-md-9 {
      margin-bottom: 9px !important;
    }

    .m-b-md-10 {
      margin-bottom: 10px !important;
    }

    .m-b-md-11 {
      margin-bottom: 11px !important;
    }

    .m-b-md-12 {
      margin-bottom: 12px !important;
    }

    .m-b-md-13 {
      margin-bottom: 13px !important;
    }

    .m-b-md-14 {
      margin-bottom: 14px !important;
    }

    .m-b-md-15 {
      margin-bottom: 15px !important;
    }

    .m-b-md-16 {
      margin-bottom: 16px !important;
    }

    .m-b-md-17 {
      margin-bottom: 17px !important;
    }

    .m-b-md-18 {
      margin-bottom: 18px !important;
    }

    .m-b-md-19 {
      margin-bottom: 19px !important;
    }

    .m-b-md-20 {
      margin-bottom: 20px !important;
    }

    .m-b-md-21 {
      margin-bottom: 21px !important;
    }

    .m-b-md-22 {
      margin-bottom: 22px !important;
    }

    .m-b-md-23 {
      margin-bottom: 23px !important;
    }

    .m-b-md-24 {
      margin-bottom: 24px !important;
    }

    .m-b-md-25 {
      margin-bottom: 25px !important;
    }

    .m-b-md-26 {
      margin-bottom: 26px !important;
    }

    .m-b-md-27 {
      margin-bottom: 27px !important;
    }

    .m-b-md-28 {
      margin-bottom: 28px !important;
    }

    .m-b-md-29 {
      margin-bottom: 29px !important;
    }

    .m-b-md-30 {
      margin-bottom: 30px !important;
    }

    .m-b-md-31 {
      margin-bottom: 31px !important;
    }

    .m-b-md-32 {
      margin-bottom: 32px !important;
    }

    .m-b-md-33 {
      margin-bottom: 33px !important;
    }

    .m-b-md-34 {
      margin-bottom: 34px !important;
    }

    .m-b-md-35 {
      margin-bottom: 35px !important;
    }

    .m-b-md-36 {
      margin-bottom: 36px !important;
    }

    .m-b-md-37 {
      margin-bottom: 37px !important;
    }

    .m-b-md-38 {
      margin-bottom: 38px !important;
    }

    .m-b-md-39 {
      margin-bottom: 39px !important;
    }

    .m-b-md-40 {
      margin-bottom: 40px !important;
    }

    .m-b-md-41 {
      margin-bottom: 41px !important;
    }

    .m-b-md-42 {
      margin-bottom: 42px !important;
    }

    .m-b-md-43 {
      margin-bottom: 43px !important;
    }

    .m-b-md-44 {
      margin-bottom: 44px !important;
    }

    .m-b-md-45 {
      margin-bottom: 45px !important;
    }

    .m-b-md-46 {
      margin-bottom: 46px !important;
    }

    .m-b-md-47 {
      margin-bottom: 47px !important;
    }

    .m-b-md-48 {
      margin-bottom: 48px !important;
    }

    .m-b-md-49 {
      margin-bottom: 49px !important;
    }

    .m-b-md-50 {
      margin-bottom: 50px !important;
    }
  }

  @media screen and (min-width: 1200px) {
    .m-b-lg-0 {
      margin-bottom: 0px !important;
    }

    .m-b-lg-1 {
      margin-bottom: 1px !important;
    }

    .m-b-lg-2 {
      margin-bottom: 2px !important;
    }

    .m-b-lg-3 {
      margin-bottom: 3px !important;
    }

    .m-b-lg-4 {
      margin-bottom: 4px !important;
    }

    .m-b-lg-5 {
      margin-bottom: 5px !important;
    }

    .m-b-lg-6 {
      margin-bottom: 6px !important;
    }

    .m-b-lg-7 {
      margin-bottom: 7px !important;
    }

    .m-b-lg-8 {
      margin-bottom: 8px !important;
    }

    .m-b-lg-9 {
      margin-bottom: 9px !important;
    }

    .m-b-lg-10 {
      margin-bottom: 10px !important;
    }

    .m-b-lg-11 {
      margin-bottom: 11px !important;
    }

    .m-b-lg-12 {
      margin-bottom: 12px !important;
    }

    .m-b-lg-13 {
      margin-bottom: 13px !important;
    }

    .m-b-lg-14 {
      margin-bottom: 14px !important;
    }

    .m-b-lg-15 {
      margin-bottom: 15px !important;
    }

    .m-b-lg-16 {
      margin-bottom: 16px !important;
    }

    .m-b-lg-17 {
      margin-bottom: 17px !important;
    }

    .m-b-lg-18 {
      margin-bottom: 18px !important;
    }

    .m-b-lg-19 {
      margin-bottom: 19px !important;
    }

    .m-b-lg-20 {
      margin-bottom: 20px !important;
    }

    .m-b-lg-21 {
      margin-bottom: 21px !important;
    }

    .m-b-lg-22 {
      margin-bottom: 22px !important;
    }

    .m-b-lg-23 {
      margin-bottom: 23px !important;
    }

    .m-b-lg-24 {
      margin-bottom: 24px !important;
    }

    .m-b-lg-25 {
      margin-bottom: 25px !important;
    }

    .m-b-lg-26 {
      margin-bottom: 26px !important;
    }

    .m-b-lg-27 {
      margin-bottom: 27px !important;
    }

    .m-b-lg-28 {
      margin-bottom: 28px !important;
    }

    .m-b-lg-29 {
      margin-bottom: 29px !important;
    }

    .m-b-lg-30 {
      margin-bottom: 30px !important;
    }

    .m-b-lg-31 {
      margin-bottom: 31px !important;
    }

    .m-b-lg-32 {
      margin-bottom: 32px !important;
    }

    .m-b-lg-33 {
      margin-bottom: 33px !important;
    }

    .m-b-lg-34 {
      margin-bottom: 34px !important;
    }

    .m-b-lg-35 {
      margin-bottom: 35px !important;
    }

    .m-b-lg-36 {
      margin-bottom: 36px !important;
    }

    .m-b-lg-37 {
      margin-bottom: 37px !important;
    }

    .m-b-lg-38 {
      margin-bottom: 38px !important;
    }

    .m-b-lg-39 {
      margin-bottom: 39px !important;
    }

    .m-b-lg-40 {
      margin-bottom: 40px !important;
    }

    .m-b-lg-41 {
      margin-bottom: 41px !important;
    }

    .m-b-lg-42 {
      margin-bottom: 42px !important;
    }

    .m-b-lg-43 {
      margin-bottom: 43px !important;
    }

    .m-b-lg-44 {
      margin-bottom: 44px !important;
    }

    .m-b-lg-45 {
      margin-bottom: 45px !important;
    }

    .m-b-lg-46 {
      margin-bottom: 46px !important;
    }

    .m-b-lg-47 {
      margin-bottom: 47px !important;
    }

    .m-b-lg-48 {
      margin-bottom: 48px !important;
    }

    .m-b-lg-49 {
      margin-bottom: 49px !important;
    }

    .m-b-lg-50 {
      margin-bottom: 50px !important;
    }
  }

  .order-1 {
    order: 1;
  }

  .order-2 {
    order: 2;
  }

  .order-3 {
    order: 3;
  }

  .hidden {
    display: none;
  }

  @media (min-width: 768px) {

    .lead,
    .lead_medium {
      font-size: 24px;
      line-height: 30px;
      letter-spacing: -1px;
    }

    p {
      font-size: 16px;
      line-height: 18px;
      letter-spacing: -0.5px;
    }
  }

  @media (min-width: 910px) {
    .wrap .content__thin {
      max-width: 850px;
      padding: 0;
    }
  }

  @media (min-width: 992px) {
    .wrap .content__thin {
      padding: 0;
    }
  }

  @media (min-width: 1200px) {
    .wrap {
      .content {
        padding: 0 30px;
      }

      .content__thin {
        padding: 0 30px;
        padding: 0;
      }
    }
  }

  @media (min-width: 1320px) {
    .wrap {

      .content,
      .content__thin {
        padding: 0;
      }
    }
  }

  .card_small {
    position: relative;
    width: 100%;
    margin: 0 auto 40px;
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;

    &::after {
      position: absolute;
      bottom: 0;
      left: 0;
      display: block;
      content: '';
      width: 100%;
      height: 1.5px;
      background: rgba(0, 0, 0, 0.05);
    }
  }

  .card_small__img {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 65.85%;
    margin-bottom: 20px;
    overflow: hidden;
    border-radius: 5px;

    img {
      top: 0;
      left: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
      -o-object-position: center;
      object-position: center;
      border-radius: 5px;
      transition: transform 0.2s ease-in;
    }

    &:hover {
      cursor: pointer;

      img {
        transform: scale(1.025, 1.025);
      }
    }
  }

  .card_small__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    color: rgba(0, 0, 0, 0.5);
  }

  .card_small__body {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  .card_small__author,
  .card_small__date,
  .card_small__description {
    color: rgba(0, 0, 0, 0.5);
    transition: color 0.15s ease-in;
  }

  .card_small__author:visited,
  .card_small__date:visited,
  .card_small__description:visited {
    color: rgba(0, 0, 0, 0.5);
  }

  .card_small__author {
    transition: color 0.15s ease-in;
    text-decoration: none;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -0.5px;

    &:hover {
      cursor: pointer;
      color: #e11d74;
    }
  }

  .card_small__title {
    margin-bottom: 15px;

    a {
      color: black;
      transition: color 0.15s ease-in;
      text-decoration: none;

      &:visited,
      &:active {
        color: black;
      }

      &:hover {
        cursor: pointer;
        color: rgba(0, 0, 0, 0.7);
      }
    }
  }

  .card_small__description {
    margin-bottom: 25px;
  }

  .card_small__info {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: auto;
    color: rgba(0, 0, 0, 0.3);
  }

  .card_small__stats {
    display: flex;
    align-items: center;
    border-radius: 20px;
    padding: 2px 10px 2px 6.33px;
    border: 1px solid rgba(0, 0, 0, 0.04);
    margin-right: 10px;
    margin-bottom: 10px;
    transition: background-color 0.15s ease-in, color 0.15s ease-in;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.5px;

    p {
      font-size: 15px;
      line-height: 18px;
      letter-spacing: -0.5px;
    }

    svg {
      margin-right: 4.33px;
    }
  }

  .card_small__category {
    text-decoration: none;
    border-radius: 20px;
    padding: 2px 10px;
    background-color: rgba(0, 0, 0, 0.04);
    border: 1px solid rgba(0, 0, 0, 0);
    margin-right: 10px;
    margin-bottom: 10px;
    transition: background-color 0.15s ease-in, color 0.15s ease-in;
    color: rgba(0, 0, 0, 0.3);
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.5px;

    &:visited {
      color: rgba(0, 0, 0, 0.3);
    }

    &:hover {
      cursor: pointer;
      background-color: rgba(225, 29, 116, 0.1);
      color: #e11d74;
    }
  }

  .card_small__bookmark {
    height: 21px;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: 5px;

    svg {
      height: 100%;
      width: auto;
      fill: rgba(0, 0, 0, 0.3);
      fill-opacity: 1;

      path {
        transition: fill 0.15s ease-in;
      }
    }

    &:hover {
      cursor: pointer;

      svg path {
        fill: #e11d74;
      }
    }
  }

  @media (min-width: 768px) {
    .card_small {
      width: calc(50% - 15px);
      margin: 0 30px 40px 0;

      &:nth-of-type(2n) {
        margin: 0 0 40px 0;
      }
    }
  }

  @media (min-width: 992px) {
    .card_small {
      width: calc(33.33% - 20px);
      margin: 0 30px 40px 0;

      &:nth-of-type(2n) {
        margin: 0 30px 40px 0;
      }

      &:nth-of-type(3n) {
        margin: 0 0 40px 0;
      }
    }
  }

  .card_medium {
    display: flex;
    flex-wrap: wrap;
    max-width: 100%;
    margin: 0 auto 40px;
    overflow: hidden;
  }

  .card_medium__img {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 0;
    padding-top: 63%;
    border-radius: 5px;

    img {
      top: 0;
      left: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
      -o-object-position: center;
      object-position: center;
      border-radius: 5px;
      transition: transform 0.2s ease-in;
    }

    &:hover {
      cursor: pointer;

      img {
        transform: scale(1.025, 1.025);
      }
    }
  }

  .card_medium__body {
    position: relative;
    padding: 20px 0;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    padding: 20px 0;

    &::after {
      position: absolute;
      bottom: 0;
      right: 0;
      display: block;
      content: '';
      width: 100%;
      height: 1.5px;
      background: rgba(0, 0, 0, 0.05);
    }
  }

  @media screen and (min-width: 768px) {
    .card_medium__body {
      padding: 20px 0;
    }
  }

  @media screen and (min-width: 992px) {
    .card_medium__body {
      padding: 0 0 20px 40px;
    }
  }

  .card_medium__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
  }

  .card_medium__author,
  .card_medium__date,
  .card_medium__description {
    color: rgba(0, 0, 0, 0.5);
    transition: color 0.15s ease-in;
  }

  .card_medium__author:visited,
  .card_medium__date:visited,
  .card_medium__description:visited {
    color: rgba(0, 0, 0, 0.5);
  }

  .card_medium__author {
    transition: color 0.15s ease-in;
    text-decoration: none;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -0.5px;

    &:hover {
      cursor: pointer;
      color: #e11d74;
    }
  }

  .card_medium__title {
    margin-bottom: 15px;

    a {
      color: black;
      transition: color 0.15s ease-in;
      text-decoration: none;

      &:visited,
      &:active {
        color: black;
      }

      &:hover {
        cursor: pointer;
        color: rgba(0, 0, 0, 0.7);
      }
    }
  }

  .card_medium__description {
    margin-bottom: 26px;
  }

  .card_medium__info {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: auto;
    color: rgba(0, 0, 0, 0.3);
  }

  .card_medium__stats {
    display: flex;
    align-items: center;
    border-radius: 20px;
    padding: 2px 10px 2px 6.33px;
    border: 1px solid rgba(0, 0, 0, 0.04);
    margin-right: 10px;
    margin-bottom: 10px;
    transition: background-color 0.15s ease-in, color 0.15s ease-in;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.5px;

    p {
      font-size: 15px;
      line-height: 18px;
      letter-spacing: -0.5px;
    }

    svg {
      margin-right: 4.33px;
    }
  }

  .card_medium__category {
    text-decoration: none;
    border-radius: 20px;
    padding: 2px 10px;
    background-color: rgba(0, 0, 0, 0.04);
    border: 1px solid rgba(0, 0, 0, 0);
    margin-right: 10px;
    margin-bottom: 10px;
    transition: background-color 0.15s ease-in, color 0.15s ease-in;
    color: rgba(0, 0, 0, 0.3);
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.5px;

    &:visited {
      color: rgba(0, 0, 0, 0.3);
    }

    &:hover {
      cursor: pointer;
      background-color: rgba(225, 29, 116, 0.1);
      color: #e11d74;
    }
  }

  .card_medium__bookmark {
    height: 21px;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: 5px;

    svg {
      height: 100%;
      width: auto;
      fill: rgba(0, 0, 0, 0.3);
      fill-opacity: 1;

      path {
        transition: fill 0.15s ease-in;
      }
    }

    &:hover {
      cursor: pointer;

      svg path {
        fill: #e11d74;
      }
    }
  }

  @media (min-width: 992px) {
    .card_medium {
      flex-wrap: nowrap;
    }

    .card_medium__img {
      min-width: 50%;
      width: 50%;
      height: auto;
      padding-top: 0;
    }

    .card_medium__body::after {
      width: calc(100% - 30px);
    }
  }

  @media (min-width: 1200px) {
    .card_medium {
      flex-wrap: nowrap;
    }

    .card_medium__img {
      min-width: 40.31%;
      width: 40.31%;
      padding-top: 27.1%;
    }
  }

  @media (min-width: 1320px) {
    .card_medium__img {
      width: 520px;
      padding-top: 340px;
    }
  }

  .card_big {
    position: relative;
    width: 100%;
    margin: 0 auto 40px;
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;

    &::after {
      position: absolute;
      bottom: 0;
      left: 0;
      display: block;
      content: '';
      width: 100%;
      height: 1.5px;
      background: rgba(0, 0, 0, 0.05);
    }
  }

  .card_big__img {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 65.125%;
    margin-bottom: 20px;
    overflow: hidden;
    border-radius: 5px;

    img {
      top: 0;
      left: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
      -o-object-position: center;
      object-position: center;
      border-radius: 5px;
      transition: transform 0.2s ease-in;
    }

    &:hover {
      cursor: pointer;

      img {
        transform: scale(1.025, 1.025);
      }
    }
  }

  .card_big__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    color: rgba(0, 0, 0, 0.5);
  }

  .card_big__body {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  .card_big__author,
  .card_big__date,
  .card_big__description {
    color: rgba(0, 0, 0, 0.5);
    transition: color 0.15s ease-in;
  }

  .card_big__author:visited,
  .card_big__date:visited,
  .card_big__description:visited {
    color: rgba(0, 0, 0, 0.5);
  }

  .card_big__author {
    transition: color 0.15s ease-in;
    text-decoration: none;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -0.5px;

    &:hover {
      cursor: pointer;
      color: #e11d74;
    }
  }

  .card_big__title {
    margin-bottom: 15px;

    a {
      color: black;
      transition: color 0.15s ease-in;
      text-decoration: none;

      &:visited,
      &:active {
        color: black;
      }

      &:hover {
        cursor: pointer;
        color: rgba(0, 0, 0, 0.7);
      }
    }
  }

  .card_big__description {
    margin-bottom: 28px;
  }

  .card_big__info {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: auto;
    color: rgba(0, 0, 0, 0.3);
  }

  .card_big__stats {
    display: flex;
    align-items: center;
    border-radius: 20px;
    padding: 2px 10px 2px 6.33px;
    border: 1px solid rgba(0, 0, 0, 0.04);
    margin-right: 10px;
    margin-bottom: 10px;
    transition: background-color 0.15s ease-in, color 0.15s ease-in;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.5px;

    p {
      font-size: 15px;
      line-height: 18px;
      letter-spacing: -0.5px;
    }

    svg {
      margin-right: 4.33px;
    }
  }

  .card_big__category {
    text-decoration: none;
    border-radius: 20px;
    padding: 2px 10px;
    background-color: rgba(0, 0, 0, 0.04);
    border: 1px solid rgba(0, 0, 0, 0);
    margin-right: 10px;
    margin-bottom: 10px;
    transition: background-color 0.15s ease-in, color 0.15s ease-in;
    color: rgba(0, 0, 0, 0.3);
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.5px;

    &:visited {
      color: rgba(0, 0, 0, 0.3);
    }

    &:hover {
      cursor: pointer;
      background-color: rgba(225, 29, 116, 0.1);
      color: #e11d74;
    }
  }

  .card_big__bookmark {
    height: 21px;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: 5px;

    svg {
      height: 100%;
      width: auto;
      fill: rgba(0, 0, 0, 0.3);
      fill-opacity: 1;

      path {
        transition: fill 0.15s ease-in;
      }
    }

    &:hover {
      cursor: pointer;

      svg path {
        fill: #e11d74;
      }
    }
  }

  @media (min-width: 992px) {
    .card_big {
      width: calc(50% - 15px);
      margin: 0 30px 40px 0;

      &:nth-of-type(2n) {
        margin: 0 0 40px 0;
      }
    }
  }

  .card_hero {
    display: flex;
    flex-wrap: wrap;
    max-width: 100%;
    margin: 0 auto 30px;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.05);
    overflow: hidden;
  }

  .card_hero__img {
    width: 100%;
    height: auto;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
      transform: scale(1, 1);
      transition: transform 0.2s ease-in;

      &:hover {
        cursor: pointer;
        transform: scale(1.025, 1.025);
      }
    }
  }

  .card_hero__body {
    display: flex;
    flex-direction: column;
    padding: 29px 20px 20px;
  }

  @media screen and (min-width: 768px) {
    .card_hero__body {
      padding: 29px 30px 20px;
    }
  }

  .card_hero__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
  }

  .card_hero__author,
  .card_hero__date,
  .card_hero__description {
    color: rgba(0, 0, 0, 0.5);
    transition: color 0.15s ease-in;
  }

  .card_hero__author:visited,
  .card_hero__date:visited,
  .card_hero__description:visited {
    color: rgba(0, 0, 0, 0.5);
  }

  .card_hero__author {
    transition: color 0.15s ease-in;
    text-decoration: none;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -0.5px;

    &:hover {
      cursor: pointer;
      color: #e11d74;
    }
  }

  .card_hero__title {
    font-size: 26px;
    line-height: 30px;
    letter-spacing: -0.9px;
    margin-bottom: 15px;

    a {
      color: black;
      transition: color 0.15s ease-in;
      text-decoration: none;

      &:visited,
      &:active {
        color: black;
      }

      &:hover {
        cursor: pointer;
        color: rgba(0, 0, 0, 0.7);
      }
    }
  }

  @media screen and (min-width: 768px) {
    .card_hero__title {
      font-size: 32px;
      line-height: 36px;
      letter-spacing: -1.4px;
    }
  }

  @media screen and (min-width: 992px) {
    .card_hero__title {
      font-size: 48px;
      line-height: 54px;
      letter-spacing: -1.6px;
    }
  }

  .card_hero__description {
    margin-bottom: 26px;
  }

  .card_hero__info {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: auto;
    color: rgba(0, 0, 0, 0.3);
  }

  .card_hero__stats {
    display: flex;
    align-items: center;
    border-radius: 20px;
    padding: 2px 10px 2px 6.33px;
    border: 1px solid rgba(0, 0, 0, 0.04);
    margin-right: 10px;
    margin-bottom: 10px;
    transition: background-color 0.15s ease-in, color 0.15s ease-in;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.5px;

    p {
      font-size: 15px;
      line-height: 18px;
      letter-spacing: -0.5px;
    }

    svg {
      margin-right: 4.33px;
    }
  }

  .card_hero__category {
    text-decoration: none;
    border-radius: 20px;
    padding: 2px 10px;
    background-color: rgba(0, 0, 0, 0.04);
    border: 1px solid rgba(0, 0, 0, 0);
    margin-right: 10px;
    margin-bottom: 10px;
    transition: background-color 0.15s ease-in, color 0.15s ease-in;
    color: rgba(0, 0, 0, 0.3);
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.5px;

    &:visited {
      color: rgba(0, 0, 0, 0.3);
    }

    &:hover {
      cursor: pointer;
      background-color: rgba(225, 29, 116, 0.1);
      color: #e11d74;
    }
  }

  .card_hero__bookmark {
    height: 21px;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: 5px;

    svg {
      height: 100%;
      width: auto;
      fill: rgba(0, 0, 0, 0.3);
      fill-opacity: 1;

      path {
        transition: fill 0.15s ease-in;
      }
    }

    &:hover {
      cursor: pointer;

      svg path {
        fill: #e11d74;
      }
    }
  }

  @media (min-width: 768px) {
    .card_hero {
      flex-wrap: nowrap;
    }

    .card_hero__img {
      min-width: 50%;
      height: auto;
    }
  }

  @media (min-width: 1320px) {
    .card_hero__img {
      width: 50%;
      min-height: 550px;
      min-width: 630px;
    }
  }

  .footer {
    background: #000000;
    color: #ffffff;
    padding: 45px 30px 30px;
  }

  .footer__inner {
    max-width: 1290px;
    margin: auto;
  }

  .footer__logocolumn {
    display: flex;
    flex-grow: 1;
    width: 100%;
    justify-content: center;
    margin-bottom: 24px;
  }

  .footer__column {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 0;
    min-width: 0;
    width: 100%;
    margin-bottom: 36px;
  }

  .footer__main-heading {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.6px;
  }

  .footer__heading {
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.3px;
    margin-bottom: 20px;
  }

  .footer__text {
    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: -0.2px;
    color: rgba(255, 255, 255, 0.5);

    &:visited {
      color: rgba(255, 255, 255, 0.5);
    }
  }

  .footer__top {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    padding-bottom: 60px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);

    a {
      text-decoration: none;
      transition: color 0.15s ease-in;

      &:hover {
        color: rgba(255, 255, 255, 0.8);
      }
    }

    .footer__column .footer__text {
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .footer__bottom {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 30px;
    align-items: center;

    .footer__text {
      margin-right: auto;
      width: 100%;
      text-align: center;
      order: 2;
    }
  }

  .footer__icon {
    position: relative;
    display: block;
    width: 45px;
    height: 45px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 50%;
    margin-right: 10px;
    cursor: pointer;
    transition: background-color 0.15s ease-in;
    order: 1;
    margin-bottom: 24px;

    &:hover {
      background-color: rgba(255, 255, 255, 0.8);

      svg {
        fill: rgba(0, 0, 0, 0.8);
      }
    }

    &:last-child {
      margin-right: 0;
    }

    svg {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      fill: #ffffff;
      transition: fill 0.15s ease-in;
    }
  }

  @media (min-width: 375px) {
    .footer__bottom {
      justify-content: flex-start;

      .footer__text {
        margin-right: auto;
        width: auto;
        text-align: left;
        order: 1;
      }
    }

    .footer__icon {
      order: 2;
      margin-bottom: 0;
    }
  }

  @media (min-width: 768px) {
    .footer {
      padding: 60px 30px 30px;
    }

    .footer__logocolumn {
      justify-content: flex-start;
    }

    .footer__column {
      width: calc(50% - 15px);
      margin-right: 30px;
      align-items: flex-start;

      &:nth-child(2n+1) {
        margin-right: 0;
      }
    }

    .footer__main-heading {
      font-size: 24px;
      line-height: 30px;
      letter-spacing: -0.5px;
    }

    .footer__heading {
      font-size: 20px;
      line-height: 24px;
      letter-spacing: -0.6px;
      margin-bottom: 20px;
    }

    .footer__text {
      font-size: 18px;
      line-height: 20px;
      letter-spacing: -0.3px;
      color: rgba(255, 255, 255, 0.5);
    }

    .footer__icon {
      width: 50px;
      height: 50px;
    }
  }

  @media (min-width: 992px) {
    .footer {
      padding: 60px 30px 30px;
    }

    .footer__column {
      width: calc(25% - 22.5px);
      margin-bottom: 0;

      &:nth-child(2n+1) {
        margin-right: 30px;
      }

      &:nth-child(4n+1) {
        margin-right: 0;
      }
    }
  }

  @media (min-width: 1200px) {
    .footer {
      padding: 60px 30px 30px;
    }
  }

  @media (min-width: 1270px) {
    .footer__logocolumn {
      width: auto;
      margin-bottom: 0;
    }

    .footer__column {
      width: auto;
      min-width: 190px;
    }
  }

  .menu_main {
    top: 0;
    position: relative;
    margin-bottom: 30px;
    background-color: #fff;
    z-index: 100;
    transition: opacity 0.3s ease-in, top 0.3s ease-in;
    opacity: 1;

    &.scrolled {
      opacity: 0.75;
      -webkit-backdrop-filter: blur(5px);
      backdrop-filter: blur(5px);
    }
  }

  .menu_main__container {
    position: relative;
    display: flex;
    padding: 0 32px;
    justify-content: space-between;
    align-items: center;
    height: auto;
    z-index: 150;
    background-color: #fff;

    &::after {
      position: absolute;
      bottom: 0;
      left: 0;
      display: block;
      content: '';
      width: 100%;
      height: 1.5px;
      background: rgba(0, 0, 0, 0.05);
    }
  }

  .menu_main__open-button {
    margin-right: 15px;
    display: flex;
    align-items: center;
    color: black;

    p {
      display: none;
    }

    span {
      margin-right: 10px;
      min-width: 24px;

      svg {
        margin: auto;
        display: block;

        path {
          transition: fill 0.15s ease-in;
        }

        &:hover {
          cursor: pointer;

          path {
            fill: #e11d74;
          }
        }
      }
    }
  }

  .menu_main__logo {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    flex: 1;
    text-decoration: none;
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: -0.5px;
    color: black;
    z-index: 10;

    &:visited,
    &:active {
      color: black;
    }
  }

  .menu_main__user-block {
    position: relative;
    display: flex;
    justify-content: flex-end;
    margin: 10px 0 15px;
    overflow: hidden;
    z-index: 11;
    background-color: #fff;
  }

  .menu_main__user-block-item,
  .menu_main__user-block-item-search {
    display: none;
    flex-direction: column;
    justify-content: center;
    height: 50px;
    margin-right: 15px;
  }

  .menu_main__user-block-item:last-child {
    margin-right: 0;
    padding-right: 0;
  }

  .menu_main__user-block-item-search {
    &:last-child {
      margin-right: 0;
      padding-right: 0;
    }

    position: relative;
    display: flex;
    border-radius: 30px;
    border: 1.5px solid rgba(0, 0, 0, 0);
    padding-right: 34px;
    width: 0;
    max-width: 100%;
    transition: width 0.3s ease-in-out,
    border-color 0.3s ease-in-out;

    /* clears the ‘X’ from Internet Explorer */
    /* clears the ‘X’ from Chrome */

    input {
      &[type=search] {

        &::-ms-clear,
        &::-ms-reveal {
          display: none;
          width: 0;
          height: 0;
        }
      }

      &[type="search"] {

        &::-webkit-search-decoration,
        &::-webkit-search-cancel-button,
        &::-webkit-search-results-button,
        &::-webkit-search-results-decoration {
          display: none;
        }
      }

      color: rgba(0, 0, 0, 0.3);
      border: none;
      width: 0;
      padding: 0;
      opacity: 0;

      &::-moz-placeholder,
      &:-ms-input-placeholder,
      &::placeholder {
        color: rgba(0, 0, 0, 0.3);
      }
    }

    svg {
      position: absolute;
      top: 50%;
      left: 17px;
      transform: translateY(-50%);
    }

    &.open {
      border: 1.5px solid rgba(0, 0, 0, 0.05);
      overflow: hidden;
      width: 360px;
      max-width: 100%;
      background-color: #fff;
      color: black;

      svg {

        path,
        &:hover path {
          fill: rgba(0, 0, 0, 0.3);
        }
      }

      input {
        color: black;
        width: 100%;
        padding: 0 0 0 47px;
        opacity: 1;
      }
    }
  }

  .menu_main__user-block-item svg path,
  .menu_main__user-block-item-search svg path {
    transition: fill 0.15s ease-in;
  }

  .menu_main__user-block-item svg:hover,
  .menu_main__user-block-item-search svg:hover {
    cursor: pointer;
  }

  .menu_main__user-block-item svg:hover path,
  .menu_main__user-block-item-search svg:hover path {
    fill: #e11d74;
  }

  .menu_main__dropdown {
    width: 100%;
    background-color: #fff;
    padding: 0 30px;
    z-index: 100;
    box-shadow: 0px 30px 100px rgba(0, 0, 0, 0.08);
  }

  .menu_main__dropdown-inner {
    display: flex;
    flex-wrap: wrap;
    max-width: 1290px;
    margin: 15px auto 10px;
    flex: 1;
    transition: flex 0.5s ease-in-out;
    height: auto;
  }

  .menu_main__main-section {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    margin-right: 0;

    .lead,
    .lead_medium {
      font-size: 17px;
      line-height: 20px;
      letter-spacing: -0.3px;
    }

    .row {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-bottom: 0;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .col {
      position: relative;

      &:nth-child(3n + 0) {
        margin-right: 0;
      }
    }
  }

  .menu_main__articles-section {
    flex-grow: 1;
    margin-top: 25px;

    .lead,
    .lead_medium {
      font-size: 18px;
      line-height: 20px;
      letter-spacing: -0.4px;
    }
  }

  .menu_main__articles-link {
    text-decoration: none;
    display: inline-block;
    color: rgba(0, 0, 0, 0.5);
    width: 100%;
    text-align: center;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.5px;
    transition: color 0.15s ease-out;

    &:visited {
      color: rgba(0, 0, 0, 0.5);
    }

    &:hover {
      cursor: pointer;
      color: #e11d74;
    }
  }

  @media screen and (min-width: 768px) {
    .menu_main__articles-link {
      font-size: 16px;
      line-height: 20px;
      letter-spacing: -0.5px;
    }
  }

  .menu_main__list-title {
    display: block;
    margin-bottom: 20px;
    position: relative;
    color: #000000;
    transition: color 0.15s ease-in;
    text-decoration: none;

    &.open::before {
      display: inline-block;
      content: '';
      width: 7px;
      height: 7px;
      border-style: solid;
      border-width: 2px 2px 0 0;
      display: inline-block;
      position: relative;
      transform: rotate(-135deg);
      vertical-align: middle;
      margin-right: 10px;
      top: -1px;
      left: 5px;
    }

    &:not(.disable) {

      &:visited,
      &:active {
        color: #000000;
        cursor: pointer;
        text-decoration: none;
      }

      &:hover {
        color: #e11d74;
        cursor: pointer;
      }
    }

    &:after {
      position: absolute;
      bottom: 0;
      left: 0;
      display: block;
      content: '';
      width: 100%;
      height: 1.5px;
      background: rgba(0, 0, 0, 0.05);
      border-radius: 2px;
      left: 0;
      bottom: -10px;
    }
  }

  .menu_main {
    ul.menu_main__list {
      padding: 0;
      list-style: none;
      margin-bottom: 0px;
      height: 0;
      overflow: hidden;

      &.open {
        height: auto;
        margin-bottom: 15px;

        li {
          transform: translateX(0);
          opacity: 1;
        }
      }

      li {
        font-size: 16px;
        line-height: 20px;
        letter-spacing: -0.5px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.8);
        margin-bottom: 5px;
        transition: color 0.15s ease-in;
        transform: translateX(-100px);
        transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
        opacity: 0;

        &:nth-child(1n + 1) {
          transition-delay: 0.025s;
        }

        &:nth-child(1n + 2) {
          transition-delay: 0.05s;
        }

        &:nth-child(1n + 3) {
          transition-delay: 0.075s;
        }

        &:nth-child(1n + 4) {
          transition-delay: 0.1s;
        }

        &:nth-child(1n + 5) {
          transition-delay: 0.125s;
        }

        &:nth-child(1n + 6) {
          transition-delay: 0.15s;
        }

        &:nth-child(1n + 7) {
          transition-delay: 0.175s;
        }

        &:nth-child(1n + 8) {
          transition-delay: 0.2s;
        }

        &:nth-child(1n + 9) {
          transition-delay: 0.225s;
        }

        &:nth-child(1n + 10) {
          transition-delay: 0.25s;
        }

        &:nth-child(1n + 11) {
          transition-delay: 0.275s;
        }

        &:nth-child(1n + 12) {
          transition-delay: 0.3s;
        }

        &:nth-child(1n + 13) {
          transition-delay: 0.325s;
        }

        &:nth-child(1n + 14) {
          transition-delay: 0.35s;
        }

        &:nth-child(1n + 15) {
          transition-delay: 0.375s;
        }

        a {
          position: relative;
          color: inherit;
          text-decoration: none;
          transition: color 0.15s ease-in, margin-left 0.15s ease-in;
          margin-left: 0;
        }

        &:hover a {
          cursor: pointer;
          color: #e11d74;
          margin-left: 3px;
        }
      }
    }

    .collapsible {
      position: absolute;
      overflow-x: hidden;
      overflow-y: scroll;
      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  @media screen and (min-width: 768px) {
    .menu_main ul.menu_main__list li {
      font-size: 16px;
      line-height: 20px;
      letter-spacing: -0.5px;
    }
  }

  .menu_main__article {
    display: flex;
    position: relative;
    margin-bottom: 20px;
    padding: 10px 0 15px;

    &:first-of-type {
      margin-top: 20px;
    }

    &:after {
      position: absolute;
      bottom: 0;
      left: 0;
      display: block;
      content: '';
      width: 100%;
      height: 1.5px;
      background: rgba(0, 0, 0, 0.05);
      border-radius: 2px;
      left: 0;
      bottom: -10px;
    }

    img {
      display: none;
      width: 80px;
      height: 60px;
      margin-right: 20px;
      border-radius: 5px;
    }
  }

  .menu_main__article-header {
    color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: space-between;
  }

  .menu_main__article-body {
    display: flex;
    justify-content: space-between;
    margin-top: 12px;

    a {
      color: inherit;
      text-decoration: none;
    }

    svg {
      display: none;

      &:hover {
        cursor: pointer;

        path {
          fill: #e11d74;
        }
      }
    }
  }

  .menu_main__article-title {
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.5px;
    font-weight: 500;
    transition: color 0.15s ease-out;

    &:hover {
      cursor: pointer;
      color: #e11d74;
    }
  }

  .menu_main__article-content {
    width: 100%;
  }

  @media (min-width: 576px) {

    .menu_main__user-block-item,
    .menu_main__user-block-item-search {
      display: flex;
      margin-right: 27px;
    }

    .menu_main__mobile-item {
      display: none;
    }
  }

  @media (min-width: 680px) {
    .menu_main__dropdown-inner {
      margin: 20px auto 30px;
    }

    .menu_main__main-section {

      .lead,
      .lead_medium {
        font-size: 18px;
        line-height: 20px;
        letter-spacing: -0.4px;
      }
    }

    .menu_main__articles-section {

      .lead,
      .lead_medium {
        font-size: 18px;
        line-height: 20px;
        letter-spacing: -0.4px;
      }
    }
  }

  @media (min-width: 768px) {
    .menu_main__container {
      height: 80px;
    }

    .menu_main__dropdown-inner {
      flex-direction: row;
      margin: 30px auto 40px;
    }

    .menu_main__open-button {
      margin: 0;
      min-width: 71px;
      display: flex;
      justify-content: space-between;

      p {
        display: block;
      }
    }

    .menu_main__logo {
      margin: 0;
      text-align: center;
    }

    .menu_main__user-block {
      margin: 0;
    }

    .menu_main__main-section {
      width: calc(50% - 15px);
      margin-right: 30px;
    }

    .menu_main__articles-section {
      width: calc(50% - 15px);
      margin-top: 0;
    }

    .menu_main__article {
      padding: 0;
    }

    .menu_main__article-title {
      font-size: 18px;
      line-height: 25px;
      letter-spacing: -0.3px;
    }

    .menu_main__list-title {
      font-size: 20px;
      line-height: 24px;
    }

    .menu_main .collapsible {
      max-height: auto;
    }
  }

  @media (min-width: 992px) {
    .menu_main__dropdown-inner {
      flex-wrap: nowrap;
    }

    .menu_main__main-section {
      flex-direction: row;
      width: calc(80% - 30px);
      margin-right: 30px;

      .lead,
      .lead_medium {
        font-size: 20px;
        line-height: 24px;
        letter-spacing: -0.5px;
      }
    }

    .menu_main__articles-section {

      .lead,
      .lead_medium {
        font-size: 20px;
        line-height: 24px;
        letter-spacing: -0.5px;
      }
    }

    .menu_main__list-title {
      cursor: auto;
      margin-bottom: 48.5px;
      font-size: 24px;
      line-height: 30px;

      &:not(.disable):hover {
        color: inherit;
        cursor: auto;
      }

      &:after {
        bottom: -25px;
      }
    }

    .menu_main {
      .row {
        flex-direction: row;
        margin-bottom: 20px;
      }

      .col {
        width: calc(33.33% - 20px);
        margin-right: 30px;
        margin-bottom: 20px;

        &:nth-child(n+3) {
          margin-bottom: 0;
        }
      }

      ul.menu_main__list {
        height: auto;
        opacity: 1;
        margin-bottom: 20px;
        margin-bottom: 0;

        li {
          transition: unset;
          margin-bottom: 10px;
          transform: translateX(0);
          opacity: 1;
        }
      }
    }
  }

  @media (min-width: 1100px) {
    .menu_main__main-section {
      .row {
        margin-bottom: 40px;
      }

      .col {
        margin-bottom: 40px;

        &:nth-child(n+3) {
          margin-bottom: 0;
        }
      }
    }

    .menu_main__list-title {
      margin-bottom: 48.5px;
      font-size: 24px;
      line-height: 30px;

      &:after {
        bottom: -25px;
      }
    }

    .menu_main__article {
      margin-bottom: 45px;

      &:after {
        bottom: -22px;
      }

      &:last-of-type {
        margin-bottom: 40px;

        &:after {
          bottom: -23px;
        }
      }
    }

    .menu_main__article-content {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }

    .menu_main__article-header {
      display: flex;
      justify-content: space-between;
    }

    .menu_main__article-body {
      display: flex;
      justify-content: space-between;
      margin-top: 12px;

      svg {
        margin-left: 5px;
        min-width: 14px;
        display: block;
      }
    }

    .menu_main__article-title {
      font-size: 18px;
      font-weight: 500;
      line-height: 25px;
      letter-spacing: -0.3px;
    }
  }

  @media (min-width: 1200px) {
    .menu_main__dropdown {
      padding: 0 30px;
    }

    .menu_main__dropdown-inner {
      margin: 40px auto 50px;
    }

    .menu_main__main-section {
      width: calc(65.1% - 50px);
      margin-right: 50px;

      .col {
        width: calc(33.33% - 33.33px);
        margin-right: 50px;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .menu_main__article img {
      display: block;
    }
  }

  .user_window {
    position: fixed;
    top: 75px;
    right: 30px;
    width: 278px;
    max-width: calc(100% - 60px);
    height: 245px;
    z-index: 1001;
    background-color: #fff;
    border: 1.5px solid rgba(0, 0, 0, 0.05);
    box-shadow: 0px 30px 100px rgba(0, 0, 0, 0.08);
    opacity: 0;
    padding: 20px 30px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: -1;

    &.open {
      opacity: 1;
      z-index: 101;
    }
  }

  @media screen and (min-width: 768px) {
    .user_window {
      padding: 30px 40px 23px;
    }
  }

  .user_window__avatar {
    border-radius: 50%;
    width: 70px;
    height: 70px;
    -o-object-fit: cover;
    object-fit: cover;
    margin-bottom: 10px;
  }

  .user_window__name {
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -0.6px;
    margin-bottom: 5px;
  }

  @media screen and (min-width: 768px) {
    .user_window__name {
      font-size: 21px;
      line-height: 30px;
      letter-spacing: -0.8px;
    }
  }

  .user_window__email {
    color: rgba(0, 0, 0, 0.3);
    margin-bottom: 23.5px;
  }

  .user_window__login {
    text-decoration: none;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.4px;
    color: rgba(0, 0, 0, 0.3);

    &:visited {
      color: rgba(0, 0, 0, 0.3);
    }

    &:hover,
    &:active {
      cursor: pointer;
      color: #e11d74;
    }
  }

  @media screen and (min-width: 768px) {
    .user_window__login {
      font-size: 16px;
      line-height: 20px;
      letter-spacing: -0.5px;
    }
  }

  .user_window__delimiter {
    background: rgba(0, 0, 0, 0.05);
    height: 1.5px;
    min-height: 1.5px;
    width: 100%;
    border-radius: 2px;
    margin-bottom: 17px;
  }

  /* Slider */

  .slider-container {
    position: relative;
    width: 100%;
    margin: 40px 0;
  }

  .slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
  }

  .slick-list {
    position: relative;
    display: block;
    margin-left: -30px;
    padding: 0;

    &:focus {
      outline: none;
    }

    &.dragging {
      cursor: pointer;
      cursor: hand;
    }
  }

  .slick-slider {

    .slick-track,
    .slick-list {
      transform: translate3d(0, 0, 0);
    }
  }

  .slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: flex !important;
    margin-left: auto;
    margin-right: auto;

    &:before {
      content: "";
      display: table;
    }

    &:after {
      content: "";
      display: table;
      clear: both;
    }
  }

  .slick-loading .slick-track {
    visibility: hidden;
  }

  .slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    margin-left: 30px;
    height: inherit !important;
    display: none;

    >div {
      height: 100%;

      >div {
        height: 100%;
      }
    }
  }

  [dir="rtl"] .slick-slide {
    float: right;
  }

  .slick-slide {
    img {
      display: block;
    }

    &.slick-loading img {
      display: none;
    }

    &.dragging img {
      pointer-events: none;
    }
  }

  .slick-initialized .slick-slide {
    display: block;
  }

  .slick-loading .slick-slide {
    visibility: hidden;
  }

  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }

  .slick-arrow.slick-hidden {
    display: none;
  }

  @media screen and (min-width: 1350px) {
    .slider-container {
      min-width: 1290px;
    }
  }

  .card_slide {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
    height: 100%;

    * {
      outline-color: rgba(0, 0, 0, 0);
    }

    &::after {
      position: absolute;
      bottom: 0;
      left: 0;
      display: block;
      content: '';
      width: 100%;
      height: 1.5px;
      background: rgba(0, 0, 0, 0.05);
    }
  }

  .card_slide__img {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 65.125%;
    margin-bottom: 20px;
    border-radius: 5px;
    overflow: hidden;
    border-radius: 5px;

    img {
      top: 0;
      left: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
      -o-object-position: center;
      object-position: center;
      border-radius: 5px;
      transition: transform 0.2s ease-in;
    }

    &:hover {
      cursor: pointer;

      img {
        transform: scale(1.025, 1.025);
      }
    }
  }

  .card_slide__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    color: rgba(0, 0, 0, 0.5);
  }

  .card_slide__body {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  .card_slide__author,
  .card_slide__date,
  .card_slide__description {
    color: rgba(0, 0, 0, 0.5);
    transition: color 0.15s ease-in;
  }

  .card_slide__author:visited,
  .card_slide__date:visited,
  .card_slide__description:visited {
    color: rgba(0, 0, 0, 0.5);
  }

  .card_slide__author {
    transition: color 0.15s ease-in;
    text-decoration: none;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -0.5px;

    &:hover {
      cursor: pointer;
      color: #e11d74;
    }
  }

  .card_slide__title {
    margin-bottom: 15px;

    a {
      color: black;
      transition: color 0.15s ease-in;
      text-decoration: none;

      &:visited,
      &:active {
        color: black;
      }

      &:hover {
        cursor: pointer;
        color: rgba(0, 0, 0, 0.7);
      }
    }
  }

  .card_slide__description {
    margin-bottom: 28px;
  }

  .card_slide__info {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: auto;
    color: rgba(0, 0, 0, 0.3);
  }

  .card_slide__stats {
    display: flex;
    align-items: center;
    border-radius: 20px;
    padding: 2px 10px 2px 6.33px;
    border: 1px solid rgba(0, 0, 0, 0.04);
    margin-right: 10px;
    margin-bottom: 10px;
    transition: background-color 0.15s ease-in, color 0.15s ease-in;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.5px;

    p {
      font-size: 15px;
      line-height: 18px;
      letter-spacing: -0.5px;
    }

    svg {
      margin-right: 4.33px;
    }
  }

  .card_slide__category {
    text-decoration: none;
    border-radius: 20px;
    padding: 2px 10px;
    background-color: rgba(0, 0, 0, 0.04);
    border: 1px solid rgba(0, 0, 0, 0);
    margin-right: 10px;
    margin-bottom: 10px;
    transition: background-color 0.15s ease-in, color 0.15s ease-in;
    color: rgba(0, 0, 0, 0.3);
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.5px;

    &:visited {
      color: rgba(0, 0, 0, 0.3);
    }

    &:hover {
      cursor: pointer;
      background-color: rgba(225, 29, 116, 0.1);
      color: #e11d74;
    }
  }

  .card_slide__bookmark {
    height: 21px;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: 5px;

    svg {
      height: 100%;
      width: auto;
      fill: rgba(0, 0, 0, 0.3);
      fill-opacity: 1;

      path {
        transition: fill 0.15s ease-in;
      }
    }

    &:hover {
      cursor: pointer;

      svg path {
        fill: #e11d74;
      }
    }
  }

  .subscribe_block {
    position: relative;
    display: flex;
    flex-wrap: wrap;
  }

  .subscribe_block__form {
    border-radius: 5px;
    background-image: linear-gradient(to right, rgba(211, 206, 197, 0.4), rgba(211, 206, 197, 0.4)), linear-gradient(to right, #fff, #fff);
    width: calc(100% - 60px);
    height: calc(100% - 60px);
    margin: 30px auto;
    padding: 65px 30px 50px;
    z-index: 2;
  }

  .subscribe_block__form-title {
    text-align: center;
    margin-bottom: 30px;
  }

  .subscribe_block__form {
    label {
      margin-right: -4px;
      width: 100%;
      margin-bottom: 10px;

      input {
        width: 100%;
      }
    }

    p {
      text-align: center;
      color: rgba(0, 0, 0, 0.3);
    }

    input[type=submit] {
      margin: 0 auto 20px;
      display: block;
      width: 100%;
    }
  }

  .subscribe_block__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;

    img {
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
    }
  }

  @media (min-width: 600px) {
    .subscribe_block__form {
      label {
        width: calc(100% - 115px);
        margin-bottom: 20px;
      }

      input[type=submit] {
        margin: 0 0 0 15px;
        display: inline-block;
        width: auto;
      }
    }
  }

  @media (min-width: 992px) {
    .subscribe_block {
      flex-wrap: nowrap;
      height: 425px;
    }

    .subscribe_block__form {
      border-radius: 0;
      position: relative;
      top: 0;
      left: 0;
      transform: none;
      width: 70%;
      height: auto;
      margin: 0;
      padding: 0 40px 0 100px;
      background-image: linear-gradient(to right, rgba(211, 206, 197, 0.4), rgba(211, 206, 197, 0.4));
    }

    .subscribe_block__form-title {
      text-align: left;
      margin-top: 97px;
      margin-bottom: 30px;
    }

    .subscribe_block__form {
      label {
        display: inline-block;
        min-width: auto;

        input {
          min-width: 400px;
        }
      }

      p {
        text-align: left;
      }
    }

    .subscribe_block__image {
      position: relative;
      width: 30%;
    }
  }

  @media (min-width: 1200px) {
    .subscribe_block__form {
      width: 60%;

      label {
        width: 462px;

        input {
          width: 462px;
        }
      }
    }

    .subscribe_block__image {
      width: 40%;
    }
  }

  @media (min-width: 1320px) {
    .subscribe_block__form {
      padding: 0 65px 0 0;
      width: calc(50% + 15px);

      form {
        max-width: 595px;
        margin-left: auto;
      }
    }

    .subscribe_block__image {
      width: calc(50% - 15px);
    }
  }

  .subscribe-input,
  .subscribe-input_icon-left input,
  .subscribe-input_icon-right input,
  .subscribe-input_button {
    font-size: 17px;
    line-height: 18px;
    letter-spacing: -0.3px;
    padding: 15px 18px;
    border-radius: 5px;
    background-color: #fff;
    color: black;
    border: none;
    margin-right: -4px;
  }

  @media screen and (min-width: 768px) {

    .subscribe-input,
    .subscribe-input_icon-left input,
    .subscribe-input_icon-right input,
    .subscribe-input_button {
      font-size: 18px;
      line-height: 20px;
      letter-spacing: -0.3px;
    }
  }

  .subscribe-input::-moz-placeholder,
  .subscribe-input_icon-left input::-moz-placeholder,
  .subscribe-input_icon-right input::-moz-placeholder,
  .subscribe-input_button::-moz-placeholder,
  .subscribe-input:-ms-input-placeholder,
  .subscribe-input_icon-left input:-ms-input-placeholder,
  .subscribe-input_icon-right input:-ms-input-placeholder,
  .subscribe-input_button:-ms-input-placeholder,
  .subscribe-input::placeholder,
  .subscribe-input_icon-left input::placeholder,
  .subscribe-input_icon-right input::placeholder,
  .subscribe-input_button::placeholder {
    color: rgba(0, 0, 0, 0.3);
  }

  .subscribe-input_icon-left {
    position: relative;
    display: inline-block;

    input {
      padding: 15px 18px 15px 44px;

      +svg {
        position: absolute;
        left: 18px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  .subscribe-input_icon-right {
    position: relative;
    display: inline-block;

    input {
      padding: 15px 44px 15px 18px;
    }
  }

  .subscribe-input_button {
    display: inline-block;
    background-color: #5d8cff;
    transition: background-color 0.15s ease-in;
    color: #fff;
    cursor: pointer;
    font-weight: 500;

    &:hover {
      background-color: rgba(93, 140, 255, 0.8);
    }

    &:active {
      background-color: #547ee6;
    }
  }

  .avatar,
  .avatar_small,
  .avatar_big {
    border-radius: 50%;
    width: 70px;
    min-width: 70px;
    height: 70px;
    min-height: 70px;
  }

  .avatar_small {
    width: 40px;
    min-width: 40px;
    height: 40px;
    min-height: 40px;
  }

  .avatar_big {
    width: 80px;
    min-width: 80px;
    height: 80px;
    min-height: 80px;
  }

  .breadcrumbs {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .breadcrumbs__item {
    color: rgba(0, 0, 0, 0.3);
    text-decoration: none;
    align-items: center;
    margin: 5px 0;

    &.active,
    &:active {
      color: black;
    }

    &:visited {
      color: rgba(0, 0, 0, 0.3);
    }

    &:hover {
      color: rgba(0, 0, 0, 0.8);
    }
  }

  .breadcrumbs svg {
    margin: 0 13.5px;
  }

  @media (min-width: 480px) {
    .breadcrumbs__item {
      margin: 0;
    }
  }

  .comment__avatar img,
  .comment__avatar-placeholder,
  .comment__avatar-placeholder_accent_1,
  .comment__avatar-placeholder_accent_2,
  .comment__avatar-placeholder_accent_3,
  .comment__avatar-placeholder_accent_4 {
    border-radius: 50%;
    width: 100%;
    height: 100%;
    position: absolute;
  }

  .comment__avatar img>*,
  .comment__avatar-placeholder>*,
  .comment__avatar-placeholder_accent_1>*,
  .comment__avatar-placeholder_accent_2>*,
  .comment__avatar-placeholder_accent_3>*,
  .comment__avatar-placeholder_accent_4>* {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .comments {
    margin-bottom: 60px;
  }

  .comments__title {
    margin-bottom: 36px;
  }

  .comments__footer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    color: rgba(0, 0, 0, 0.5);
  }

  .comments__footer-text {
    margin-right: 20px;
    order: 2;
  }

  .comments__icon {
    order: 3;
    position: relative;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: rgba(93, 140, 255, 0.1);
    margin-right: 10px;
    transition: background-color 0.15s ease-in;

    svg {
      width: 13.33px;
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      path {
        fill: #5d8cff;
        transition: fill 0.15s ease-in;
      }
    }

    &:hover {
      background-color: rgba(93, 140, 255, 0.05);
      opacity: 0.5;

      svg path {
        fill: #5d8cff;
      }
    }
  }

  .comment,
  .comment_new {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .comment__avatar {
    position: relative;
    width: 40px;
    min-width: 40px;
    height: 40px;
    min-height: 40px;
    margin-right: 25px;
    margin-bottom: 15px;

    img {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      -o-object-fit: cover;
      object-fit: cover;
      -o-object-position: center;
      object-position: center;
    }
  }

  .comment__avatar-placeholder_accent_1 {
    background-color: rgba(93, 140, 255, 0.1);
    color: #5d8cff;
  }

  .comment__avatar-placeholder_accent_2 {
    background-color: rgba(145, 209, 139, 0.1);
    color: #91d18b;
  }

  .comment__avatar-placeholder_accent_3 {
    background-color: rgba(225, 29, 116, 0.1);
    color: #e11d74;
  }

  .comment__avatar-placeholder_accent_4 {
    background-color: rgba(243, 137, 40, 0.1);
    color: #f38928;
  }

  .comment__body {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-bottom: 43.5px;

    &::after {
      position: absolute;
      display: block;
      content: '';
      width: 100%;
      height: 1.5px;
      background: rgba(0, 0, 0, 0.05);
      border-radius: 2px;
      left: 0;
      bottom: -23.5px;
    }
  }

  .comment__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  .comment__content {
    color: rgba(0, 0, 0, 0.5);
  }

  .comment__author {
    color: rgba(0, 0, 0, 0.8);
  }

  .comment__date {
    color: rgba(0, 0, 0, 0.5);
  }

  .comment__add-button {
    width: 100%;
    margin-left: 0;
    margin-bottom: 15px;
    order: 1;
  }

  .comment_new {
    margin-top: 10px;
    margin-bottom: 15px;
  }

  .comment_new-content {
    flex-grow: 1;
    border: 1.5px solid rgba(0, 0, 0, 0.05);
    padding: 12px 16px;
    border-radius: 8px;
    color: rgba(0, 0, 0, 0.3);
  }

  @media (min-width: 480px) {

    .comment,
    .comment_new {
      flex-direction: row;
    }

    .comment__avatar {
      margin-bottom: 0;
    }
  }

  @media (min-width: 600px) {
    .comments__footer {
      justify-content: flex-end;
    }

    .comments__footer-text {
      order: 1;
    }

    .comment__add-button {
      order: 3;
      margin-left: 15px;
      margin-bottom: 0;
      width: auto;
    }
  }

  @media (min-width: 768px) {
    .comments {
      margin-bottom: 80px;
    }
  }

  @media (min-width: 992px) {
    .comments {
      margin-bottom: 108px;
    }
  }

  .button,
  .button_mini,
  .button_mini_icon-left,
  .button_mini_icon-right,
  .button_small,
  .button_big,
  .button_fixed,
  .button_fixed_mini,
  .button_fixed_small,
  .button_fixed_big {
    text-decoration: none;
    text-align: center;
    position: relative;
    border-radius: 8px;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0);
    display: inline-block;
    height: 50px;
    outline: 0;
    background-color: #5d8cff;
    color: #fff;
    font-size: 18px;
    line-height: 28px;
    font-weight: 500;
    letter-spacing: -0.3px;
    padding: 11px 18px;
    transition: background-color 0.15s ease-in, color 0.15s ease-in;
    white-space: nowrap;
  }

  .button_follow {
    text-decoration: none;
    text-align: center;
    position: relative;
    border-radius: 8px;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0);
    display: inline-block;
    height: 50px;
    outline: 0;
    background-color: #5d8cff;
    color: #fff;
    font-size: 18px;
    line-height: 28px;
    font-weight: 500;
    letter-spacing: -0.3px;
    padding: 11px 18px;
    transition: background-color 0.15s ease-in, color 0.15s ease-in;
    white-space: nowrap;

    &.followed {
      text-decoration: none;
      text-align: center;
      position: relative;
      border-radius: 8px;
      border-width: 1px;
      border-style: solid;
      border-color: rgba(0, 0, 0, 0);
      display: inline-block;
      height: 50px;
      outline: 0;
      background-color: #5d8cff;
      color: #fff;
      font-size: 18px;
      line-height: 28px;
      font-weight: 500;
      letter-spacing: -0.3px;
      padding: 11px 18px;
      transition: background-color 0.15s ease-in, color 0.15s ease-in;
      white-space: nowrap;
    }
  }

  .button:hover,
  .button_mini:hover,
  .button_mini_icon-left:hover,
  .button_mini_icon-right:hover,
  .button_small:hover,
  .button_big:hover,
  .button_fixed:hover,
  .button_fixed_mini:hover,
  .button_fixed_small:hover,
  .button_fixed_big:hover,
  .button_follow:hover {
    color: #fff;
    background-color: rgba(93, 140, 255, 0.8);
    cursor: pointer;
  }

  .button:active,
  .button_mini:active,
  .button_mini_icon-left:active,
  .button_mini_icon-right:active,
  .button_small:active,
  .button_big:active,
  .button_fixed:active,
  .button_fixed_mini:active,
  .button_fixed_small:active,
  .button_fixed_big:active,
  .button_follow:active {
    color: #fff;
    background-color: #547ee6;
  }

  .button:visited,
  .button_mini:visited,
  .button_mini_icon-left:visited,
  .button_mini_icon-right:visited,
  .button_small:visited,
  .button_big:visited,
  .button_fixed:visited,
  .button_fixed_mini:visited,
  .button_fixed_small:visited,
  .button_fixed_big:visited,
  .button_follow:visited {
    color: #fff;
  }

  .button svg path,
  .button_mini svg path,
  .button_mini_icon-left svg path,
  .button_mini_icon-right svg path,
  .button_small svg path,
  .button_big svg path,
  .button_fixed svg path,
  .button_fixed_mini svg path,
  .button_fixed_small svg path,
  .button_fixed_big svg path {
    transition: fill 0.15s ease-in;
  }

  .button_follow {

    svg path,
    &.followed svg path {
      transition: fill 0.15s ease-in;
    }
  }

  .button_mini,
  .button_mini_icon-left,
  .button_mini_icon-right {
    height: 30px;
    font-size: 13px;
    line-height: 15px;
    letter-spacing: -0.2px;
    padding: 7.5px 9px;
  }

  .button_mini_icon-left {
    padding-left: 25px;

    svg {
      position: absolute;
      width: 13.33px;
      height: auto;
      left: 8.33px;
      top: 50%;
      transform: translate(0, -50%);
    }
  }

  .button_mini_icon-right {
    padding-right: 25px;

    svg {
      position: absolute;
      width: 13.33px;
      height: auto;
      right: 8.33px;
      top: 50%;
      transform: translate(0, -50%);
    }
  }

  .button_small {
    height: 40px;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -0.5px;
    padding: 0 14px;
  }

  .button_big {
    height: 60px;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.6px;
    padding: 0 22px;
  }

  .button_fixed {
    width: 200px;
  }

  .button_fixed_mini {
    width: 200px;
    height: 30px;
    font-size: 13px;
    line-height: 15px;
    letter-spacing: -0.2px;
    padding: 7.5px 9px;
  }

  .button_fixed_small {
    width: 200px;
    height: 40px;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -0.5px;
    padding: 11px 14px;
  }

  .button_fixed_big {
    width: 200px;
    height: 60px;
    height: 60px;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.6px;
    padding: 18px 22px;
  }

  .button_outline {
    background-color: #fff;
    color: #000;
    border-color: rgba(0, 0, 0, 0.1);
    pointer-events: none;
  }

  .button_disabled {
    background-color: rgba(0, 0, 0, 0.05);
    color: rgba(0, 0, 0, 0.3);
    border-color: rgba(0, 0, 0, 0.1);
    pointer-events: none;
  }

  .button_twitter {
    background-color: rgba(106, 171, 232, 0.15);
    color: #6aabe8;

    svg path {
      fill: #6aabe8;
    }

    &:hover,
    &:visited {
      background-color: rgba(106, 171, 232, 0.05);
      color: rgba(106, 171, 232, 0.75);
    }

    &:hover svg path,
    &:visited svg path {
      fill: rgba(106, 171, 232, 0.75);
    }

    &:active {
      background-color: rgba(106, 171, 232, 0.3);
      color: #6aabe8;

      svg path {
        fill: #6aabe8;
      }
    }
  }

  .button_facebook {
    background-color: rgba(66, 87, 153, 0.1);
    color: #425799;

    svg path {
      fill: #425799;
    }

    &:hover,
    &:visited {
      background-color: rgba(66, 87, 153, 0.05);
      color: rgba(66, 87, 153, 0.75);
    }

    &:hover svg path,
    &:visited svg path {
      fill: rgba(66, 87, 153, 0.75);
    }

    &:active {
      background-color: rgba(66, 87, 153, 0.3);
      color: #425799;

      svg path {
        fill: #425799;
      }
    }
  }

  .button_gray {
    background-color: rgba(0, 0, 0, 0.05);
    color: rgba(0, 0, 0, 0.3);

    svg path {
      fill: rgba(0, 0, 0, 0.3);
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
      color: rgba(0, 0, 0, 0.3);

      svg path {
        fill: rgba(0, 0, 0, 0.3);
      }
    }

    &:active {
      background-color: rgba(0, 0, 0, 0.1);
      color: rgba(0, 0, 0, 0.5);

      svg path {
        fill: rgba(0, 0, 0, 0.5);
      }
    }
  }

  .button_follow {
    height: 30px;
    font-size: 13px;
    line-height: 15px;
    letter-spacing: -0.2px;
    padding: 0 11px 0 9px;
    color: #e11d74;
    background-color: rgba(225, 29, 116, 0.1);

    &.followed {
      height: 30px;
      font-size: 13px;
      line-height: 15px;
      letter-spacing: -0.2px;
      padding: 0 11px 0 9px;
      color: #e11d74;
      background-color: rgba(225, 29, 116, 0.1);
    }

    &:hover {
      background-color: rgba(225, 29, 116, 0.3);
      color: #e11d74;
      cursor: pointer;
    }

    &:active {
      background-color: #e11d74;
      color: #fff;
    }
  }

  .followed.button_follow {
    background-color: #e11d74;
    color: #fff;
    background-image: url("/images/follow.svg");
    background-repeat: no-repeat;
    padding: 0 25px 0 9px;
    background-position: calc(100% - 8px);
  }

  .button_small_br {
    border-radius: 5px;
  }

  .rounded-button,
  .rounded-button_icon-left,
  .rounded-button_icon-right,
  .rounded-button_controls {
    position: relative;
    display: flex;
    text-decoration: none;
    align-items: center;
    border: 1.5px solid rgba(0, 0, 0, 0.05);
    border-radius: 60px;
    background-color: #fff;
    padding: 0 33px;
    height: 60px;
    outline: none;
    color: black;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: -0.5px;
    transition: color 0.15s ease-in;
  }

  @media screen and (min-width: 768px) {

    .rounded-button,
    .rounded-button_icon-left,
    .rounded-button_icon-right,
    .rounded-button_controls {
      font-size: 20px;
      line-height: 24px;
      letter-spacing: -0.6px;
    }
  }

  .rounded-button svg path,
  .rounded-button_icon-left svg path,
  .rounded-button_icon-right svg path,
  .rounded-button_controls svg path {
    transition: fill 0.15s ease-in;
  }

  .rounded-button:visited,
  .rounded-button_icon-left:visited,
  .rounded-button_icon-right:visited,
  .rounded-button_controls:visited {
    color: black;
  }

  .rounded-button:hover,
  .rounded-button_icon-left:hover,
  .rounded-button_icon-right:hover,
  .rounded-button_controls:hover {
    cursor: pointer;
    color: #e11d74;
  }

  .rounded-button:hover svg path,
  .rounded-button_icon-left:hover svg path,
  .rounded-button_icon-right:hover svg path,
  .rounded-button_controls:hover svg path {
    fill: #e11d74;
  }

  .rounded-button_icon-left {
    padding-left: 63px;

    svg {
      position: absolute;
      width: 12px;
      height: auto;
      left: 35px;
      top: 50%;
      transform: translate(0, -50%);
    }
  }

  .rounded-button_icon-right {
    padding-right: 63px;

    svg {
      position: absolute;
      width: 12px;
      height: auto;
      right: 35px;
      top: 50%;
      transform: translate(0, -50%);
    }
  }

  .rounded-button_controls {
    width: 110px;
    display: flex;
    padding: 0;
    color: rgba(0, 0, 0, 0.5);
    border: none;

    .before {
      pointer-events: none;
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      content: '';
      width: 100%;
      height: 100%;
      border-radius: 60px;
      border: 1.5px solid #f2f2f2;
      transition: width 0.35s ease-in-out;
    }

    .before_prev {
      -webkit-animation: controls_hover 0.35s ease-in-out forwards;
      animation: controls_hover 0.35s ease-in-out forwards;
    }

    .before_next {
      left: auto;
      right: 0;
      -webkit-animation: controls_hover 0.35s ease-in-out forwards;
      animation: controls_hover 0.35s ease-in-out forwards;
    }

    .before_redraw_prev {
      transition: width 0.35s ease-in-out;
      -webkit-animation: controls_unhover 0.35s ease-in-out forwards;
      animation: controls_unhover 0.35s ease-in-out forwards;
    }

    .before_redraw_next {
      left: auto;
      right: 0;
      transition: width 0.35s ease-in-out;
      -webkit-animation: controls_unhover 0.35s ease-in-out forwards;
      animation: controls_unhover 0.35s ease-in-out forwards;
    }

    svg path {
      fill: rgba(0, 0, 0, 0.5);
    }

    &:hover {
      color: rgba(0, 0, 0, 0.5);

      svg path {
        fill: rgba(0, 0, 0, 0.5);
      }
    }

    .rounded-button_controls__prev,
    .rounded-button_controls__next {
      display: inline-block;
      width: 50%;
      height: 100%;
      cursor: pointer;
      transition: color 0.15s ease-in-out;
    }

    .rounded-button_controls__prev svg,
    .rounded-button_controls__next svg {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }

    .rounded-button_controls__prev svg path,
    .rounded-button_controls__next svg path {
      transition: fill-opacity 0.15s ease-in;
    }

    .rounded-button_controls__prev:hover::after,
    .rounded-button_controls__next:hover::after {
      width: 55%;
      border: 1.5px solid #f2f2f2;
      opacity: 1;
    }

    .rounded-button_controls__prev:hover svg path,
    .rounded-button_controls__next:hover svg path {
      fill: black;
    }

    .noborder {

      &.rounded-button_controls__prev::after,
      &.rounded-button_controls__next::after {
        opacity: 0;
      }
    }
  }

  .rounded-button_controls__prev svg {
    left: 24px;
  }

  .rounded-button_controls__next svg {
    right: 24px;
  }

  .noborder_controls__prev,
  .noborder_controls__next {
    position: relative;
    display: inline-block;
    width: 50%;
    height: 100%;
    border-radius: 50%;
    border-color: rgba(0, 0, 0, 0);
    transition: border-color 0.15s ease-in;
  }

  .noborder_controls__prev svg,
  .noborder_controls__next svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .noborder_controls__prev svg path,
  .noborder_controls__next svg path {
    transition: fill 0.15s ease-in;
    fill: rgba(0, 0, 0, 0.5);
  }

  .noborder_controls__prev:hover,
  .noborder_controls__next:hover {
    border: 1px solid rgba(0, 0, 0, 0.05);
    cursor: pointer;
  }

  .noborder_controls__prev:hover svg path,
  .noborder_controls__next:hover svg path {
    fill: black;
  }

  .noborder_controls {
    width: 120px;
    display: flex;
    height: 60px;
  }

  @-webkit-keyframes controls_hover {
    from {
      width: 100%;
    }

    to {
      width: 55%;
    }
  }

  @keyframes controls_hover {
    from {
      width: 100%;
    }

    to {
      width: 55%;
    }
  }

  @-webkit-keyframes controls_unhover {
    from {
      width: 55%;
    }

    to {
      width: 100%;
    }
  }

  @keyframes controls_unhover {
    from {
      width: 55%;
    }

    to {
      width: 100%;
    }
  }

  .tag,
  .tag_accent_1,
  .tag_accent_2,
  .tag_accent_3,
  .tag_accent_4,
  .tag_info,
  .tag_icon-left,
  .tag_icon-right {
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.4px;
    cursor: pointer;
    text-decoration: none;
    padding: 2px 10px;
    background-color: rgba(0, 0, 0, 0.04);
    color: rgba(0, 0, 0, 0.3);
    border: 1px solid rgba(0, 0, 0, 0);
    border-radius: 20px;
    display: inline-block;
    transition: color 0.15s ease-in, background-color 0.15s ease-in;
  }

  @media screen and (min-width: 768px) {

    .tag,
    .tag_accent_1,
    .tag_accent_2,
    .tag_accent_3,
    .tag_accent_4,
    .tag_info,
    .tag_icon-left,
    .tag_icon-right {
      font-size: 15px;
      line-height: 18px;
      letter-spacing: -0.5px;
    }
  }

  .tag:visited,
  .tag_accent_1:visited,
  .tag_accent_2:visited,
  .tag_accent_3:visited,
  .tag_accent_4:visited,
  .tag_info:visited,
  .tag_icon-left:visited,
  .tag_icon-right:visited {
    color: rgba(0, 0, 0, 0.3);
  }

  .tag:hover,
  .tag_accent_1:hover,
  .tag_accent_2:hover,
  .tag_accent_3:hover,
  .tag_accent_4:hover,
  .tag_info:hover,
  .tag_icon-left:hover,
  .tag_icon-right:hover {
    cursor: pointer;
    background-color: rgba(225, 29, 116, 0.1);
    color: #e11d74;
  }

  .tag_accent_1 {
    background-color: rgba(93, 140, 255, 0.1);
    color: #547ee6;

    &:visited {
      color: #547ee6;
    }

    &:hover {
      background-color: rgba(93, 140, 255, 0.3);
      color: #547ee6;
    }
  }

  .tag_accent_2 {
    background-color: rgba(145, 209, 139, 0.1);
    color: #82bc7d;

    &:visited {
      color: #82bc7d;
    }

    &:hover {
      background-color: rgba(145, 209, 139, 0.3);
      color: #82bc7d;
    }
  }

  .tag_accent_3 {
    background-color: rgba(225, 29, 116, 0.1);
    color: #ca1a68;

    &:visited {
      color: #ca1a68;
    }

    &:hover {
      background-color: rgba(225, 29, 116, 0.3);
      color: #ca1a68;
    }
  }

  .tag_accent_4 {
    background-color: rgba(243, 137, 40, 0.1);
    color: #db7b24;

    &:visited {
      color: #db7b24;
    }

    &:hover {
      background-color: rgba(243, 137, 40, 0.3);
      color: #db7b24;
    }
  }

  .tag_info {
    background-color: #fff;
    border: 1px rgba(0, 0, 0, 0.05) solid;

    &:hover {
      cursor: default;
      color: rgba(0, 0, 0, 0.3);
      background-color: #fff;
    }
  }

  .tag_icon-left {
    position: relative;
    padding-left: 24px;

    svg {
      position: absolute;
      width: 13.33px;
      height: auto;
      top: 50%;
      right: 55.3px;
      transform: translate(0, -50%);
    }
  }

  .tag_icon-right {
    position: relative;
    padding-right: 24px;

    svg {
      position: absolute;
      width: 13.33px;
      height: auto;
      top: 50%;
      right: 55.3px;
      transform: translate(0, -50%);
    }
  }

  .author-block {
    display: flex;
    position: relative;
    padding: 0 0 16px 0;
    margin: 0 0 15px 0;
    width: 100%;

    &:after {
      position: absolute;
      bottom: 0;
      left: 0;
      display: block;
      content: '';
      width: 100%;
      height: 1.5px;
      background: rgba(0, 0, 0, 0.05);
      border-radius: 2px;
    }
  }

  @media screen and (min-width: 768px) {
    .author-block {
      padding: 0 0 16px 0;
    }
  }

  @media screen and (min-width: 992px) {
    .author-block {
      padding: 0 0 18.5px 0;
    }
  }

  @media screen and (min-width: 768px) {
    .author-block {
      margin: 0 0 18px 0;
    }
  }

  @media screen and (min-width: 992px) {
    .author-block {
      margin: 0 0 20px 0;
    }
  }

  .author-block__avatar {
    margin-right: 20px;
    border-radius: 50%;

    img {
      transition: filter 0.15s ease-in;
    }

    &:hover img {
      filter: brightness(90%) grayscale(10%);
    }
  }

  .author-block__name {
    display: block;
    text-decoration: none;
    color: black;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -0.6px;
    margin-bottom: 10px;
    transition: color 0.15s ease-in;

    &:hover {
      cursor: pointer;
      color: #e11d74;
    }
  }

  @media screen and (min-width: 768px) {
    .author-block__name {
      font-size: 21px;
      line-height: 30px;
      letter-spacing: -0.8px;
    }
  }

  .author-block__followers {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    color: rgba(0, 0, 0, 0.3);

    >* {
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .author-block__container {
    margin: 0 0 40px 0;
  }

  @media screen and (min-width: 768px) {
    .author-block__container {
      margin: 0 0 60px 0;
    }
  }

  @media screen and (min-width: 992px) {
    .author-block__container {
      margin: 0 0 100px 0;
    }
  }

  @media (min-width: 768px) {
    .author-block {
      width: calc(50% - 15px);
      margin-right: 30px;

      &:nth-child(2n) {
        margin-right: 0;
      }
    }
  }

  @media (min-width: 1200px) {
    .author-block {
      width: calc(33.33% - 20px);
      margin-right: 30px;

      &:nth-child(2n) {
        margin-right: 30px;
      }

      &:nth-child(3n) {
        margin-right: 0;
      }
    }
  }

  .author-heading {
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    align-items: flex-end;
    flex-wrap: wrap;
    margin: 0 0 25px 0;
    padding: 0 0 24px 0;

    &::after {
      position: absolute;
      bottom: 0;
      left: 0;
      display: block;
      content: '';
      width: 100%;
      height: 1.5px;
      background: rgba(0, 0, 0, 0.05);
      border-radius: 2px;
    }
  }

  @media screen and (min-width: 768px) {
    .author-heading {
      margin: 0 0 35px 0;
    }
  }

  @media screen and (min-width: 992px) {
    .author-heading {
      margin: 0 0 40px 0;
    }
  }

  @media screen and (min-width: 768px) {
    .author-heading {
      padding: 0 0 34px 0;
    }
  }

  .author-heading__avatar {
    margin-right: auto;
    margin-left: auto;

    img {
      transition: filter 0.15s ease-in;
    }

    &:hover img {
      filter: brightness(90%) grayscale(10%);
    }
  }

  .author-heading__info {
    margin-left: auto;
    margin-right: auto;
  }

  .author-heading__name {
    margin: 10px 0 10px 0;
    text-align: center;
  }

  .author-heading__followers {
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.3);
    margin-right: 20px;

    >*:first-child {
      margin-right: 10px;
    }
  }

  .author-heading__button {
    margin: 20px auto 0 auto;
  }

  @media (min-width: 375px) {
    .author-heading__avatar {
      margin-right: 20px;
      margin-left: auto;
    }

    .author-heading__info {
      margin-left: 0;
      margin-right: auto;
    }

    .author-heading__name {
      margin: 0 0 6px 0;
      text-align: left;
    }
  }

  @media (min-width: 680px) {
    .author-heading__avatar {
      margin-right: 20px;
      margin-left: 0;
    }

    .author-heading__info {
      margin: 0;
    }

    .author-heading__button {
      margin: 0 0 0 auto;
    }
  }

  .filter {
    position: relative;
  }

  .filter__trigger {
    svg {
      transform-origin: center;
      transform: rotateX(0deg) translateY(-50%);
    }

    &.open svg {
      transform-origin: center;
      transform: rotateX(180deg) translateY(50%);
    }
  }

  .filter__dropdown {
    position: absolute;
    top: calc(100% + 10px);
    z-index: 99;
    min-width: 100%;
    border-radius: 20px;
    border: 1px solid rgba(0, 0, 0, 0.05);
    background-color: #fff;

    ul {
      list-style: none;
      padding: 0 33px;

      li {
        margin: 20px 0 20px 0;
        font-size: 16px;
        line-height: 28px;
        letter-spacing: normal;
        transition: color 0.15s ease-in;

        &:hover {
          cursor: pointer;
          color: #e11d74;
        }
      }
    }
  }

  @media screen and (min-width: 768px) {
    .filter__dropdown ul li {
      font-size: 18px;
      line-height: 20px;
      letter-spacing: normal;
    }
  }

  // PAGES
  // Main
  .main-page .menu_main {
    margin: 0 0 40px 0;
  }

  @media screen and (min-width: 768px) {
    .main-page .menu_main {
      margin: 0 0 45px 0;
    }
  }

  @media screen and (min-width: 992px) {
    .main-page .menu_main {
      margin: 0 0 50px 0;
    }
  }

  .main-page .show-more-block {
    margin: 30px 0 40px;
  }

  @media screen and (min-width: 768px) {
    .main-page .show-more-block {
      margin: 40px 0 60px;
    }
  }

  @media screen and (min-width: 992px) {
    .main-page .show-more-block {
      margin: 50px 0 100px;
    }
  }

  .main-page .headline {
    margin: 35px 0 30px;
  }

  @media screen and (min-width: 768px) {
    .main-page .headline {
      margin: 40px 0 35px;
    }
  }

  @media screen and (min-width: 992px) {
    .main-page .headline {
      margin: 60px 0 40px;
    }
  }

  // Post
  .single-post__author,
  .single-post__description {
    text-align: center;
    color: rgba(0, 0, 0, 0.5);
  }

  .single-post__header {
    margin-top: 20px;
  }

  .single-post__icon-block {
    position: relative;
  }

  .single-post__icon-block-inner {
    position: -webkit-sticky;
    position: sticky;
    top: 130px;
    left: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 0;
  }

  .single-post__icon-block-icon {
    position: relative;
    border: 1.5px rgba(0, 0, 0, 0.05) solid;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    margin-bottom: 0;
    margin-right: 15px;
  }

  .single-post__icon-block-icon:last-child {
    margin-right: 0;
  }

  .single-post__icon-block-icon svg,
  .single-post__icon-block-icon svg.fix-height {
    color: rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 20px;
    height: auto;
  }

  .single-post__icon-block-icon svg path,
  .single-post__icon-block-icon svg.fix-height path {
    transition: fill 0.15s ease-in;
    fill: rgba(0, 0, 0, 0.3);
  }

  .single-post__icon-block-icon svg.fix-height {
    width: auto;
    height: 20px;
  }

  .single-post__icon-block-icon:hover svg path {
    fill: #e11d74;
  }

  .single-post .article-content {
    margin-bottom: 50px;
  }

  .single-post__author {
    margin-bottom: 13px;
  }

  .single-post__description {
    max-width: 630px;
    margin: 0 auto 50px;
  }

  .single-post__title {
    text-align: center;
    margin-bottom: 15px;
  }

  .single-post__footer {
    position: relative;
    margin: 0 0 40px 0;
    padding: 30px 0 0 0;
  }

  @media screen and (min-width: 768px) {
    .single-post__footer {
      margin: 0 0 60px 0;
    }
  }

  @media screen and (min-width: 992px) {
    .single-post__footer {
      margin: 0 0 100px 0;
    }
  }

  @media screen and (min-width: 768px) {
    .single-post__footer {
      padding: 40px 0 0 0;
    }
  }

  @media screen and (min-width: 992px) {
    .single-post__footer {
      padding: 50px 0 0 0;
    }
  }

  .single-post__footer:before {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    content: '';
    width: 100%;
    height: 1.5px;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 2px;
  }

  @media (min-width: 768px) {

    .single-post__header-left,
    .single-post__header-center,
    .single-post__header-right {
      padding: 0 80px;
    }
  }

  @media (min-width: 992px) {

    .single-post__header-left,
    .single-post__header-center,
    .single-post__header-right {
      padding: 0;
    }
  }

  @media (min-width: 1100px) {
    .single-post__icon-block {
      position: absolute;
      top: 20px;
      left: 0;
      height: 100%;
    }

    .single-post__icon-block-inner {
      position: -webkit-sticky;
      position: sticky;
      top: 60px;
      left: 0;
      display: flex;
      flex-direction: column;
      padding: 0 0 0 30px;
      margin-bottom: 70px;
    }

    .single-post__icon-block-icon {
      margin-bottom: 15px;
      margin-right: 0;
    }
  }

  @media (min-width: 1200px) {
    .single-post__header {
      flex-direction: row;
    }

    .single-post__header-left {
      min-width: calc((100% - 850px)/2);
      order: 1;
      flex-direction: column;
    }

    .single-post__header-center {
      order: 2;
    }

    .single-post__header-right {
      min-width: calc((100% - 850px)/2);
      order: 3;
    }
  }

  @media (min-width: 1330px) {
    .single-post__icon-block-inner {
      padding: 0;
    }
  }
}