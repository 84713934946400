/**
* Generated theme by Material Theme Generator
* https://materialtheme.arcsine.dev
* Fork at: https://materialtheme.arcsine.dev/?c=YHBhbGV0dGU$YHByaW1hcnk$YF48IzFhMWM1MSIsIj9lcjwjYmFiYmNiIiwiO2VyPCMwZjExMzd$LCIlPmBePCMwMDk5NWIiLCI~ZXI8I2IzZTBjZSIsIjtlcjwjMDA3YzQwfiwid2Fybj5gXjwjZmYwMDAwIiwiP2VyPCNmZmIzYjMiLCI7ZXI8I2ZmMDAwMH4sIj9UZXh0PCMwMDAwMDAiLCI~PTwjZmFmYWZhIiwiO1RleHQ8I2ZmZmZmZiIsIjs9PCMyYzJjMmN$LCJmb250cz5bYEA8KC00IiwiZmFtaWx5PEludGVyfixgQDwoLTN$LGBAPCgtMn4sYEA8KC0xfixgQDxoZWFkbGluZX4sYEA8dGl0bGV$LGBAPHN1YiktMn4sYEA8c3ViKS0xfixgQDxib2R5LTJ$LGBAPGJvZHktMX4sYEA8YnV0dG9ufixgQDxjYXB0aW9ufixgQDxpbnB1dCIsInNpemU$bnVsbH1dLCJpY29uczxGaWxsZWQiLCI~bmVzcz5mYWxzZSwidmVyc2lvbj4xM30=
*/

@use '@angular/material' as mat;
@use './assets/scss/theme';

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.

// Fonts
@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500&display=swap');

@include mat.core();

html {
  @include mat.all-component-themes(theme.$light-theme);
  @include mat.system-level-colors(theme.$light-theme);
  @include mat.system-level-typography(theme.$light-theme);

  // TODO - uncomment once ready to provide full dark mode
  // @media (prefers-color-scheme: dark) {
  //   @include mat.all-component-colors(theme.$dark-theme);
  //   @include mat.system-level-colors(theme.$dark-theme);
  // }
}

// Bring in other global styles
@import './assets/scss/layout';

// Specific component overrides, pieces that are not in line with the general theming

// Handle buttons appropriately, with respect to line-height
.mat-raised-button,
.mat-stroked-button,
.mat-flat-button {
  padding: 0 1.15em;
  margin: 0 .65em;
  min-width: 3em;
  line-height: 36.4px
}

.mat-standard-chip {
  padding: .5em .85em;
  min-height: 2.5em;
}

.material-icons {
  font-size: 24px;
  font-family: 'Material Icons Outlined', 'Material Icons';

  .mat-badge-content {
    font-family: 'Roboto';
  }
}
