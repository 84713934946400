@use 'sass:math';
@import './variables';

html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

.mat-mdc-dialog-actions, .actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between !important;
}

.flex-spacer {
  flex: 1;
}

form {
  display: flex;
  flex-direction: column;
}

.info {
  font-size: 1.1rem;
  margin: math.div($gutter, 2) 0;

  .info-header {
    margin-bottom: math.div($gutter, 2);
  }

  p {
    margin-bottom: math.div($gutter, 4);

    &:last-child {
      margin-bottom: 0;
    }
  }
}